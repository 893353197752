import DisplayLevel from '../../../BeecomingTypes/DisplayLevel';
import DataInterface from './DataInterface';

interface ActualityFromFetch {
  id: string;
  title: string;
  date: Date;
  // eslint-disable-next-line camelcase
  img_url: string;
  level: DisplayLevel;
}

interface FieldsInterface {
  title: string;
  published: Date;
}

interface ActualityInterface extends DataInterface {
  title: string;
  level: DisplayLevel;
  // eslint-disable-next-line camelcase
  img_url: string;
  published: Date;
}

function ActualityFormatter(data: ActualityFromFetch): ActualityInterface {
  return {
    id: data.id,
    title: data.title,
    img_url: data.img_url,
    level: data.level ?? -1,
    published: data.date,
  };
}

export default ActualityInterface;
export { ActualityFormatter };
