/* eslint-disable implicit-arrow-linebreak */
import { DayItem } from '../../Types/BeecomingTypes/BeecomingTypes';

const AllDays: DayItem[] = [
  { id: 0, name: 'Lundi' },
  { id: 1, name: 'Mardi' },
  { id: 2, name: 'Mercredi' },
  { id: 3, name: 'Jeudi' },
  { id: 4, name: 'Vendredi' },
  { id: 5, name: 'Samedi' },
  { id: 6, name: 'Dimanche' },
];

const getDaysFromNames = (names: string[]): number[] =>
  names.map((name) => AllDays.findIndex((x) => x.name === name));
const getDaysFromIds = (ids: number[]): string[] => ids.map((day) => AllDays[day].name);

export default AllDays;
export { getDaysFromIds, getDaysFromNames };
