import React, { FunctionComponent } from 'react';
import './AdminDataDisplayer.scss';

interface Props {
  label: string;
  value: string;
}

const DisplayProperty: FunctionComponent<Props> = ({ label, value }) => (
  <div className="ElementProperty">
    <p className="adminPropertyName">{label}</p>
    <p className="adminPropertyValue">{value}</p>
  </div>
);

export default DisplayProperty;
