import AdminScreenPathsList from '../Datas/BeecomingDatas/AdminScreenPathsList';
import AdminScreen from '../Types/BeecomingTypes/AdminScreen';

/**
 * @type {AdminScreen}
 */

/**
 * Get screen path from screen name
 * @param screen Screen Name
 * @returns Screen Path
 */

export default function GetPathFromAdminScreen(screen: string | null): string {
  const res: string = AdminScreenPathsList[screen as AdminScreen];
  if (res !== undefined) {
    return res;
  }
  return AdminScreenPathsList.parking;
}
