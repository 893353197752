import { Divider, List } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import NavigationList from '../../../../Datas/ProjectDatas/NavigationList';
import '../../Admin.scss';
import NavigationItemComponent from './NavigationItemComponent';

interface Props {}

const AdminNavigation: FunctionComponent<Props> = () => (
  <List component="nav">
    {NavigationList.map((navigationItem) => (
      <div key={navigationItem.label}>
        <Divider />
        <NavigationItemComponent
          label={navigationItem.label}
          imgSrc={navigationItem.imgSrc}
          node={navigationItem.node}
        />
      </div>
    ))}
    <Divider />
  </List>
);

export default AdminNavigation;
