/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import frLocale from 'date-fns/locale/fr';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from '@material-ui/core';
import assert from 'assert';
import DisplayProperty from '../BeecomingCompenents/DisplayProperty';
import CollectExceptionInterface from '../../../Types/Interface/TableInterfaces/DataInterface/CollectExceptionInterface';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import {
  BasicGarbageCollector,
  FormatterBasicWaste,
} from '../../../Types/Interface/TableInterfaces/DataInterface/WasteManagementInterface';
import CollectChip from '../BeecomingCompenents/CollectChip';
import UseBeecomingStyles from '../../../Themes/UseBeecomingStyles';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import AdminScreenPathsList from '../../../Datas/BeecomingDatas/AdminScreenPathsList';
// eslint-disable-next-line max-len
// import { NotificationTopic } from '../../../Types/Interface/TableInterfaces/DataInterface/NotificationInterface';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';

interface Props {}

/**
 * @param onError method: show a snackbar with the chosen message
 * @returns Edit Exception page component
 */

const AdminEditException: AdminPageComponent = ({ onError }) => {
  const history = useHistory();
  const [pageTitle, setPageTitle] = useState<string>('Nouvelle Exception');
  const [id, setId] = useState<string | null>();
  // true if thre is several exception selected
  const [plural, setPlural] = useState(false);

  // properties
  const [PickupTourCode, setPickupTourCode] = useState<string>('');
  const [collectProperties, setCollectProperties] = useState<BasicGarbageCollector[]>([]);
  const [exceptionDate, setExceptionDate] = useState<Date | null>(new Date());
  const [hasSubstitution, setHasSubstitution] = useState<boolean>(false);
  const [replacement, setReplacement] = useState<Date | null>(new Date());

  // notification
  const [hasNotification, setHasNotification] = useState<boolean>(true);
  // const [notificationTopics, setNotificationTopics] = useState<NotificationTopic | null>();
  const [notificationTitle, setNotificationTitle] = useState<string>('');
  const [notificationBody, setNotificationBody] = useState<string>('');
  const [notificationDate, setNotificationDate] = useState<Date | null>(new Date());

  // dialogs
  const [isDialogNotification, setIsDialogNotification] = useState<boolean>(false);

  // errors
  const [isNotificationTitleError, setIsNotificationTitleError] = useState<boolean>(false);
  const [isNotificationBodyError, setIsNotificationBodyError] = useState<boolean>(false);

  // Edit Date by setting hours, minutes and seconds to zero
  const getHandleDateChange =
    (setter: React.Dispatch<React.SetStateAction<Date | null>>): ((_date: Date | null) => void) =>
    (date: Date | null) => {
      date?.setHours(12);
      date?.setMinutes(0);
      date?.setSeconds(0);
      date?.setMilliseconds(0);
      setter(date);
    };

  // handle checkbox used to specify if their is a substitution collect
  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasSubstitution(event.target.checked);
  };

  // #region Handle Validation
  // check if each field is valid
  const isValid = (): boolean => {
    let valid: boolean = true;
    if (hasNotification) {
      if (notificationTitle === '') {
        valid = false;
        setIsNotificationTitleError(true);
      }
      if (notificationBody === '') {
        valid = false;
        setIsNotificationBodyError(true);
      }
    }
    return valid;
  };

  /**
   * Method used to send notifications
   * @returns either the notifications were saved succesfully
   */
  const handleLaunchNotification = (): Promise<boolean> => {
    const requestNotification = {
      notification: {
        title: notificationTitle,
        body: notificationBody,
        // topics: notificationTopics,
      },
      sendingTime: notificationDate,
    };
    return ApiFetch(
      '/Notification/enregister-une-notification',
      'POST',
      history,
      requestNotification,
    ).then((NotificationResponse) => {
      if (NotificationResponse.ok) {
        return true;
      }
      setIsDialogNotification(true);
      return false;
    });
  };

  /**
   * Send User back to a menu
   */
  const handleSucces = () => {
    if (plural) {
      history.push(AdminScreenPathsList['waste-collect']);
    } else {
      history.push(`${AdminScreenPathsList['waste-collect-edition']}?id=${PickupTourCode}`);
    }
  };

  /**
   * Method used when the user clicked 'Valider'
   */
  const handleValidate = () => {
    if (isValid()) {
      // try to add collects
      const newDate = hasSubstitution ? replacement : null;
      console.log(id);
      const requestData =
        id !== null
          ? {
              Id: id,
              PickupTourCode,
              date: exceptionDate,
              newDate,
            }
          : {
              Id: undefined,
              PickupTourCode,
              date: exceptionDate,
              newDate,
            };
      if (id !== null) {
        ApiFetch('/waste-exception/', 'PUT', history, requestData)
          .then((response) => {
            if (response.ok) {
              if (hasNotification) {
                // if the exceptions are send then try to send notifications
                return handleLaunchNotification();
              }
              return true;
            }
            // Gives error if notifications are not send.
            if (plural) {
              onError("Les exceptions n'ont pas été enregistrées.");
            } else {
              onError("L'exception n'a pas été enregistrée.");
            }
            return false;
          })
          .then((succes: boolean) => {
            if (succes) {
              handleSucces();
            }
          });
      } else {
        ApiFetch('/waste-exception/add-one/', 'POST', history, requestData)
          .then((response) => {
            if (response.ok) {
              if (hasNotification) {
                // if the exceptions are send then try to send notifications
                return handleLaunchNotification();
              }
              return true;
            }
            // Gives error if notifications are not send.
            if (plural) {
              onError("Les exceptions n'ont pas été enregistrées.");
            } else {
              onError("L'exception n'a pas été enregistrée.");
            }
            return false;
          })
          .then((succes: boolean) => {
            if (succes) {
              handleSucces();
            }
          });
      }
    }
  };
  // #endregion

  /**
   * Method used when the user delete exception
   */
  const handleDelete = () => {
    assert(!plural, 'Impossible to delete in Add mode');
    console.log(id);
    ApiFetch('/waste-exception/', 'DELETE', history, id).then((response) => {
      if (response.ok) {
        history.push(`${AdminScreenPathsList['waste-collect-edition']}?id=${PickupTourCode}`);
      } else {
        onError("La notification n'a pas été supprimée.");
      }
    });
  };

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const editId = params.get('id');
    if (editId !== null) {
      // the user is editing an existing exception
      setPageTitle("Edition d'exception");
      setId(editId);
      ApiFetch(`/waste-exception/${editId}`, 'GET', history)
        .then((response) => response.json())
        .then((resp: CollectExceptionInterface) => {
          console.log(resp);
          setPlural(false);
          setPickupTourCode(resp.pickupTourCode);
          setExceptionDate(resp.date);
          if (resp.newDate !== null) {
            setHasSubstitution(true);
            setReplacement(resp.newDate);
          } else {
            setHasSubstitution(false);
          }
        });
    } else {
      // the user is creating an exception for each collect selected
      setId(null);
      const newPickupTourCode = params.get('collecteId') ?? '';
      setPickupTourCode(newPickupTourCode);
      setPlural(newPickupTourCode.length >= 2);
    }
  }, []);

  /**
   * This used Effect is used to fill exception properties
   * like collecteid, typeid, zoneid, zonelabelid
   * @type{ BasicGarbageCollector }
   */
  useEffect(() => {
    let isSubscribe = true;
    /* const l: number = PickupTourCode?.length;
    if (l > 0) { */
    let link = '/waste-exception/';
    link += `${id}`;
    ApiFetch(link, 'GET', history)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return null;
      })
      .then((resp) => {
        if (isSubscribe && resp != null) {
          const { records, nhits } = resp;
          const newCollectProperties: BasicGarbageCollector[] = [];
          for (let i = 0; i < nhits; i += 1) {
            newCollectProperties.push(FormatterBasicWaste(records[i]));
          }
          setCollectProperties(newCollectProperties);
          /* const topics: NotificationTopic = {
              category: 'Gestion des déchets',
              subCategories: ['yellowDumpster', 'greenDumpster'],
              zones: newCollectProperties.map((collect) => collect.collecteid.slice(3)),
            };
            setNotificationTopics(topics); */
        }
      });
    // }
    return () => {
      isSubscribe = false;
    };
  }, [PickupTourCode]);

  const collectLabel = plural ? 'Identifiant des collectes :' : 'Identifiant de la collecte :';

  const collectZoneLabel = plural ? 'zones des collectes' : 'zone de la collecte';

  const getCollectIdText = (): string => {
    const res = PickupTourCode;
    /* let res = '';
     if (PickupTourCode?.length > 0) {
      res += PickupTourCode;
    }
     for (let i = 1; i < PickupTourCode?.length; i += 1) {
      res += `, ${PickupTourCode[i]}`;
    } */
    return res;
  };

  // #region Notfications
  const handleHasNotification = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasNotification(event.target.checked);
  };

  const handleNotificationDateChange = (date: Date | null) => {
    date?.setMilliseconds(0);
    date?.setSeconds(0);
    setNotificationDate(date);
  };
  // #endregion

  // #region Dialogs
  const handleCloseNotificationError = () => {
    setIsDialogNotification(false);
    history.push(AdminScreenPathsList['waste-collect']);
  };

  /**
   * Send user back to Edit Notification Screen with wanted params
   */
  const handleRetryNotification = () => {
    setIsDialogNotification(false);
    let path = AdminScreenPathsList['notification-edition'];
    path += `?titre=${notificationTitle}`;
    path += `&corps=${notificationBody}`;
    path += `&date=${notificationDate}`;
    /* path += `&topicCategorie=${notificationTopics?.category}`;
    notificationTopics?.subCategories.forEach((subC) => {
      path += `&topicSousCategories=${subC}`;
    });
    notificationTopics?.zones.forEach((zone) => {
      path += `&topicZones=${zone}`;
    }); */
    history.push(path);
  };
  // #endregion

  // #region Styles
  const sssClasses = UseBeecomingStyles();
  // #endregion

  return (
    <div>
      <h1>{pageTitle}</h1>
      <DisplayProperty label={collectLabel} value={getCollectIdText()} />
      <Dialog open={isDialogNotification} onClose={handleCloseNotificationError}>
        <DialogTitle>Les notifications n&apos;ont pas été programmé</DialogTitle>
        <DialogContent>
          Les exceptions ont été sauvegardées mais une erreur à empêcher la programmation des
          notifications. Pour être redirigé vers l&apos;écran des notifications appuyez sur
          &quot;Réessayer&quot;. Fermez cette boîte de dialogue pour revenir sur la liste des
          collectes.
        </DialogContent>
        <DialogActions>
          <Button className={sssClasses.BeecomingButton} onClick={handleCloseNotificationError}>
            Fermer
          </Button>
          <Button className={sssClasses.BeecomingButton} onClick={handleRetryNotification}>
            Réessayer
          </Button>
        </DialogActions>
      </Dialog>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
        <div className="newElementContainer">
          <div className="newElementColumn">
            {/*  <div className="newElementProperty">
              <p className="adminPropertyName">{collectZoneLabel}</p>
              {collectProperties.map((collect) => (
                <CollectChip collect={collect} key={collect.collecteid} />
              ))}
            </div> */}
            <div className="newElementProperty">
              <KeyboardDatePicker
                required
                label="Jour de l'exception"
                value={exceptionDate}
                format="dd/MM/yyyy"
                okLabel="Ok"
                cancelLabel="Annuler"
                invalidDateMessage="Date invalide"
                fullWidth
                onChange={getHandleDateChange(setExceptionDate)}
              />
            </div>
            <div className="newElementProperty">
              <FormControlLabel
                control={<Switch checked={hasSubstitution} onChange={handleCheckBox} />}
                label="Collecte de subsititution :"
              />

              <KeyboardDatePicker
                disabled={!hasSubstitution}
                value={replacement}
                format="dd/MM/yyyy"
                okLabel="Ok"
                cancelLabel="Annuler"
                invalidDateMessage="Date invalide"
                fullWidth
                onChange={getHandleDateChange(setReplacement)}
              />
            </div>
            <div className="newElementProperty">
              <Button
                style={{ margin: 5 }}
                variant="outlined"
                onClick={() => {
                  history.push(
                    `${AdminScreenPathsList['waste-collect-edition']}?id=${PickupTourCode}`,
                  );
                }}
              >
                Annuler
              </Button>
              {id !== null ? (
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: 7 }}
                  onClick={handleDelete}
                >
                  Supprimer
                </Button>
              ) : null}
              <Button
                variant="contained"
                color="primary"
                onClick={handleValidate}
                style={{ margin: 5 }}
              >
                Valider
              </Button>
            </div>
          </div>
          <div className="newElementColumn">
            <FormControlLabel
              control={<Switch checked={hasNotification} onChange={handleHasNotification} />}
              label="Créer une notification"
            />
            <div className="newElementProperty">
              <TextField
                label="Titre de la notification"
                value={notificationTitle}
                disabled={!hasNotification}
                error={isNotificationTitleError}
                required
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIsNotificationTitleError(false);
                  setNotificationTitle(event.target.value);
                }}
              />
            </div>
            <div className="newElementProperty">
              <TextField
                label="Texte de la notification"
                value={notificationBody}
                disabled={!hasNotification}
                error={isNotificationBodyError}
                required
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                rows={3}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIsNotificationBodyError(false);
                  setNotificationBody(event.target.value);
                }}
              />
            </div>
            <div className="newElementProperty" style={{ display: 'flex', flexDirection: 'row' }}>
              <KeyboardDatePicker
                label="jour de la notification"
                value={notificationDate}
                disabled={!hasNotification}
                required
                format="dd/MM/yyyy"
                okLabel="Ok"
                cancelLabel="Annuler"
                invalidDateMessage="Date invalide"
                style={{ marginRight: '10pt' }}
                onChange={handleNotificationDateChange}
              />

              <KeyboardTimePicker
                label="heure de la notification"
                value={notificationDate}
                disabled={!hasNotification}
                required
                ampm={false}
                style={{ marginLeft: '10pt' }}
                onChange={handleNotificationDateChange}
                okLabel="OK"
                cancelLabel="Annuler"
                invalidDateMessage="Horaire invalide"
              />
            </div>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
};

const AdminEditExceptionProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Gestion des déchets"
    screenName="waste-collect-exception"
    menuPath="waste-collect"
    adminPage={AdminEditException}
  />
);

export default AdminEditExceptionProtected;
