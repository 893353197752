import { TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import ListHeaderProps from '../../../../../Types/Interface/Props/ListHeaderProps';
import GetHandleFilter from '../../../../../Methods/TableQuery/HandleFilter/GetHandleFilter';
import SearchBox from '../../SearchBox';
import ListHeaderOrder from './ListHeaderOrder';
import { HeadCell } from '../../../../../Types/BeecomingTypes/BeecomingTypes';
import ParkingInterface from '../../../../../Types/Interface/TableInterfaces/DataInterface/ParkingInterface';
import ParkingAdditionalArgs from '../../../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/ParkingAdditionalArgs';

const ListHeaderParking: FunctionComponent<
  ListHeaderProps<ParkingInterface, ParkingAdditionalArgs>
> = ({
  additionalArgs,
  onChangeAdditionalArgs,
  orderType,
  onChangeOrderType,
  orderBy,
  onChangeOrderBy,
}) => {
  const [timer, setTimer] = useState(setTimeout(() => {}, 0));

  const headCells: HeadCell<ParkingInterface>[] = [
    { id: 0, keyValue: 'title', name: 'Nom', align: 'left' },
    { id: 2, keyValue: 'coordinate', name: 'Coordonnées', align: 'center' },
    { id: 3, keyValue: 'maxSpaces', name: 'Nombre de places', align: 'center' },
    { id: 4, keyValue: 'edit', name: 'Edition', align: 'center' },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <SearchBox
            label="Trouver un parking"
            handleSearch={GetHandleFilter<ParkingAdditionalArgs>(
              'title',
              additionalArgs,
              onChangeAdditionalArgs,
              timer,
              setTimer,
              500,
            )}
          />
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
      <ListHeaderOrder
        headCells={headCells}
        orderType={orderType}
        onChangeOrderType={onChangeOrderType}
        orderBy={orderBy}
        onChangeOrderBy={onChangeOrderBy}
      />
    </TableHead>
  );
};

export default ListHeaderParking;
