import GetAdditionalQueryInterface from '../../../Types/Interface/MethodInterface/GetAdditionalQueryInteface';
import AgendaAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/AgendaAdditionalArgs';
import { AgendaInterface } from '../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import StringContains from '../../StringContains';

const GetAgendaAdditionalQuery: GetAdditionalQueryInterface<AgendaAdditionalArgs> = (
  _additionalQuery: AgendaAdditionalArgs,
) => null;

// some additional args are not well handle by the clloApi, so their treated by the front
const parseAgendaList = (
  dataBlock: AgendaInterface[],
  args: AgendaAdditionalArgs,
): AgendaInterface[] => dataBlock.filter((row) => StringContains(row.label, args.title));

export default GetAgendaAdditionalQuery;
export { parseAgendaList };
