import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import OtherScreenPathsList from '../../../Datas/BeecomingDatas/OtherScreenPathsList';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import useCcloStyles from '../../../Themes/UseBeecomingStyles';

interface Props {}

const LogoutButton: FunctionComponent<Props> = () => {
  const [isAlertShown, setIsAlertShown] = useState(false);
  const history = useHistory();
  const ccloClasses = useCcloStyles();

  const handleLogout = (_event: React.MouseEvent<HTMLButtonElement>) => {
    ApiFetch('/compte/deconnexion', 'PUT', history).then(() => {
      localStorage.removeItem('user');
      history.push(OtherScreenPathsList['admin-connection']);
    });
  };

  const handleAlert = () => {
    setIsAlertShown(true);
  };

  const handleCloseAlert = () => {
    setIsAlertShown(false);
  };

  return (
    <>
      <Button className={ccloClasses.BeecomingButton} color="secondary" onClick={handleAlert}>
        Déconnexion
      </Button>
      <Dialog open={isAlertShown} onClose={handleCloseAlert}>
        <DialogContent>
          <DialogContentText>Voulez-vous vraiment vous déconnecter?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={ccloClasses.BeecomingButton} onClick={handleCloseAlert}>
            Annuler
          </Button>
          <Button className={ccloClasses.BeecomingButton} onClick={handleLogout}>
            Se déconnecter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LogoutButton;
