/* eslint-disable implicit-arrow-linebreak */
import BeecomingDatas from '../../Datas/BeecomingDatas/BeecomingDatas';

const urlRequest = process.env.REACT_APP_URL_REQUEST;

const refreshToken = (): Promise<any> =>
  fetch(`${urlRequest}/compte/est-admin`, {
    method: 'GET',
    credentials: 'include',
  }).then((refreshResponse) => (refreshResponse.ok ? refreshResponse.json() : null));

const refreshContinuously = () => {
  refreshToken().then(() => {
    startRefreshContinuously();
  });
};

const startRefreshContinuously = () => {
  setTimeout(refreshContinuously, BeecomingDatas.tokenRefreshTime);
};

export default refreshToken;
export { startRefreshContinuously };
