/* eslint-disable no-nested-ternary */
/* eslint-disable implicit-arrow-linebreak */
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import GarbageCollectAdditionalArgs from '../../../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/GarbageCollectAdditionalArgs';
import { WasteManagementInterface } from '../../../../../Types/Interface/TableInterfaces/DataInterface/WasteManagementInterface';
import ListHeaderProps from '../../../../../Types/Interface/Props/ListHeaderProps';
import GetHandleFilter from '../../../../../Methods/TableQuery/HandleFilter/GetHandleFilter';
import SearchBox from '../../SearchBox';
import ListHeaderOrder from './ListHeaderOrder';
import AllDays, {
  getDaysFromIds,
  getDaysFromNames,
} from '../../../../../Datas/BeecomingDatas/AllDays';
import { DayItem, HeadCell, WeekItem } from '../../../../../Types/BeecomingTypes/BeecomingTypes';
import AllWeeks from '../../../../../Datas/BeecomingDatas/AllWeeks';
import CcloMenu from '../../CcloMenu';

const ListHeaderGarbageCollect: FunctionComponent<
  ListHeaderProps<WasteManagementInterface, GarbageCollectAdditionalArgs>
> = ({
  additionalArgs,
  onChangeAdditionalArgs,
  orderType,
  onChangeOrderType,
  orderBy,
  onChangeOrderBy,
}) => {
  const [timer, setTimer] = useState(setTimeout(() => {}, 0));
  const [days, setDays] = useState<number[]>(getDaysFromNames(additionalArgs.days));

  const handleChangeType = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newGarbageType = event.target.value as number;
    const newArgs: GarbageCollectAdditionalArgs = additionalArgs;
    newArgs.type = newGarbageType;
    onChangeAdditionalArgs(newArgs);
  };

  const editDays = (newDays: number[]): Promise<boolean> => {
    const newArgs: GarbageCollectAdditionalArgs = additionalArgs;
    newArgs.days = getDaysFromIds(newDays);
    setDays(newDays);
    return onChangeAdditionalArgs(newArgs);
  };

  const editWeeks = (newWeeks: number[]): Promise<boolean> => {
    const newArgs: GarbageCollectAdditionalArgs = additionalArgs;
    newArgs.weeks = newWeeks;
    return onChangeAdditionalArgs(newArgs);
  };

  const headCells: HeadCell<WasteManagementInterface>[] = [
    { id: 0, keyValue: 'name', name: 'Parcours', align: 'left' },
    { id: 1, keyValue: 'type', name: 'Type', align: 'left' },
    { id: 2, keyValue: 'day', name: 'Jour de passage', align: 'left' },
    { id: 4, keyValue: 'recurrence', name: '', align: 'right' },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <SearchBox
            label="Trouver un parcours"
            handleSearch={GetHandleFilter<GarbageCollectAdditionalArgs>(
              'name',
              additionalArgs,
              onChangeAdditionalArgs,
              timer,
              setTimer,
              500,
            )}
          />
        </TableCell>
        <TableCell>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select defaultValue={-1} value={additionalArgs.type} onChange={handleChangeType}>
              <MenuItem value={-1}>Toutes les collectes</MenuItem>
              <MenuItem value={0}>Ordures ménagères</MenuItem>
              <MenuItem value={1}>Emballages ménagers</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        <TableCell style={{ width: '200pt' }}>
          <FormControl fullWidth style={{ maxWidth: '150pt' }}>
            <InputLabel>Jour</InputLabel>
            <CcloMenu<DayItem> keyText="name" menu={AllDays} values={days} onEditValue={editDays} />
          </FormControl>
          <FormControl fullWidth style={{ maxWidth: '150pt' }}>
            <InputLabel>Semaine</InputLabel>
            <CcloMenu<WeekItem>
              keyText="description"
              menu={AllWeeks}
              values={additionalArgs.weeks}
              onEditValue={editWeeks}
            />
          </FormControl>
        </TableCell>
        <TableCell style={{ width: '20pt' }} />
        <TableCell />
      </TableRow>
      <ListHeaderOrder
        headCells={headCells}
        orderType={orderType}
        onChangeOrderType={onChangeOrderType}
        orderBy={orderBy}
        onChangeOrderBy={onChangeOrderBy}
      />
    </TableHead>
  );
};

export default ListHeaderGarbageCollect;
