import GetAdditionalQueryInterface from '../../../Types/Interface/MethodInterface/GetAdditionalQueryInteface';
import ParkingAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/ParkingAdditionalArgs';
import ParkingInterface from '../../../Types/Interface/TableInterfaces/DataInterface/ParkingInterface';
import StringContains from '../../StringContains';

const GetParkingAdditionalQuery: GetAdditionalQueryInterface<ParkingAdditionalArgs> = (
  _additionalQuery: ParkingAdditionalArgs,
) => null;

// some additional args are not well handle by the clloApi, so their treated by the front
const parseParkingList = (
  dataBlock: ParkingInterface[],
  args: ParkingAdditionalArgs,
): ParkingInterface[] => dataBlock.filter((row) => StringContains(row.title, args.title));

export default GetParkingAdditionalQuery;
export { parseParkingList };
