/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import AllDays from '../../../../Datas/BeecomingDatas/AllDays';
import { DayItem, Order } from '../../../../Types/BeecomingTypes/BeecomingTypes';
import GetComparatorFromKeyInterface from '../../../../Types/Interface/MethodInterface/GetComparatorFromKeyInterface';
import { WasteManagementInterface } from '../../../../Types/Interface/TableInterfaces/DataInterface/WasteManagementInterface';

const GetWasteComparatorFromKey: GetComparatorFromKeyInterface<WasteManagementInterface> = (
  key: keyof WasteManagementInterface,
  typeOrder: Order,
) => {
  const sign: number = typeOrder === 'asc' ? 1 : -1;
  switch (key) {
    case 'name':
      return (a: WasteManagementInterface, b: WasteManagementInterface) =>
        sign * a.name.localeCompare(b.name);

    case 'type':
      return (a: WasteManagementInterface, b: WasteManagementInterface) =>
        sign * a.type.localeCompare(b.type);
    case 'day':
      return (a: WasteManagementInterface, b: WasteManagementInterface) => {
        const dayA: DayItem = AllDays.find((value) => value.id === a.day) ?? AllDays[0];
        const dayB: DayItem = AllDays.find((value) => value.id === b.day) ?? AllDays[0];
        return sign * (dayA.id - dayB.id);
      };
    default:
      return (a: WasteManagementInterface, b: WasteManagementInterface) =>
        sign * a[key].toLocaleString().localeCompare(b[key].toLocaleString());
  }
};

export default GetWasteComparatorFromKey;
