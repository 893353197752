/* eslint-disable react/jsx-wrap-multilines */
import { Button, Checkbox, CircularProgress, FormControlLabel } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState, Dispatch, SetStateAction, FunctionComponent, useEffect } from 'react';

import { CSVReader } from 'react-papaparse';
import { useHistory } from 'react-router-dom';
import {
  handleOnDropAddress,
  handleOnDropException,
  handleOnDropPickup,
  handleOnError,
  handleOnRemoveFile,
  handleOnUpload,
  handleResetDataAddress,
  handleResetDataException,
  handleResetDataPickup,
} from '../../../Methods/WasteHandler/importCSV';

const ModalImportCSV: FunctionComponent<{ setModalIsOpen: Dispatch<SetStateAction<boolean>> }> = ({
  setModalIsOpen,
}) => {
  const history = useHistory();
  // const buttonRef = React.createRef();
  const [step, setStep] = useState<number>(0);
  const [reset, setReset] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [exceptionCheck, setExceptionCheck] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contentModal, setContentModal] = useState<any>([
    {
      title: 'Attention',
      content: 'Vous allez purger la base de données intégralement avec de nouvelles données...',
    },
    {
      title: 'Étape 1 : Importer le fichier CSV "tournées"',
      error: 'Fichier non conforme...',
    },
    {
      title: 'Étape 2 : Importer le fichier CSV "adresses"',
      error: 'Fichier non conforme...',
    },
    {
      title: 'Étape 3 : Importer le fichier CSV "exceptions"',
      error: 'Fichier non conforme...',
    },
    {
      title: 'Validation',
      content: 'Vos fichiers sont tous valides',
      error: 'import impossible...',
    },
    { title: 'Import réussi!' },
  ]);

  useEffect(() => {
    console.log(exceptionCheck);
  }, [exceptionCheck]);

  const validate = () => {
    setStep((oldStep) => oldStep + 1);
  };

  const cancel = () => {
    setStep(0);
    setModalIsOpen(false);
    handleResetDataPickup();
    handleResetDataAddress();
  };
  return (
    <div className={step >= 1 ? 'modalimportCSV' : 'modalimportCSV__danger'}>
      <h3>{contentModal[step].title}</h3>
      <p>{contentModal[step].content}</p>
      <div className="dragCSV">
        {step >= 1 && step <= 3 && (
          <CSVReader
            onDrop={async (data: any) => {
              setIsLoading(true);
              switch (step) {
                case 1:
                  setError(false);
                  setReset(false);
                  if (handleOnDropPickup(data)) {
                    setStep((state) => state + 1);
                    setIsLoading(false);
                  } else {
                    setError(true);
                    handleResetDataPickup();
                    setIsLoading(false);
                  }
                  setReset(true);
                  break;
                case 2:
                  setError(false);
                  setReset(false);
                  if (handleOnDropAddress(data)) {
                    setStep((state) => state + 1);
                    setIsLoading(false);
                  } else {
                    setError(true);
                    handleResetDataAddress();
                    setIsLoading(false);
                  }
                  setReset(true);
                  break;
                case 3:
                  setError(false);
                  setReset(false);
                  if (!exceptionCheck) {
                    if (handleOnDropException(data)) {
                      setStep((state) => state + 1);
                      setIsLoading(false);
                    } else {
                      setError(true);
                      handleResetDataException();
                      setIsLoading(false);
                    }
                  } else {
                    setStep((state) => state + 1);
                    setIsLoading(false);
                  }
                  break;
                default:
                  setReset(true);
                  setIsLoading(false);
                  break;
              }
            }}
            onError={handleOnError}
            addRemoveButton
            onRemoveFile={handleOnRemoveFile}
            progressBarColor="#147076"
            isReset={reset}
          >
            <span>
              Ajouter votre fichier CSV directement dans le cadre ou cliquer pour sélectionner
            </span>
          </CSVReader>
        )}
      </div>
      {isLoading && <CircularProgress />}
      {error && (
        <Alert severity="error">
          <strong>{contentModal[step].error}</strong>
        </Alert>
      )}
      {step === 3 && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={exceptionCheck}
                onChange={() => setExceptionCheck((state) => !state)}
              />
            }
            label="Je n'ai pas d'exception à ajouter."
          />
        </div>
      )}
      <div className="btnContainerModalImportCSV">
        {(step < 1 || step >= 3) && (
          <Button
            style={{ margin: '5px' }}
            variant="contained"
            color="primary"
            onClick={async () => {
              if (step === 4) {
                setReset(false);
                setIsLoading(true);
                // eslint-disable-next-line no-case-declarations
                const upload = await handleOnUpload();
                if (upload) {
                  setStep((state) => state + 1);
                  setIsLoading(false);
                } else {
                  setError(true);
                  setIsLoading(false);
                }
                setReset(true);
              } else if (step === 5) {
                setModalIsOpen(false);
                setIsLoading(false);
              } else {
                validate();
                setIsLoading(false);
              }
            }}
          >
            {step !== 5 ? 'Valider' : 'Fermer'}
          </Button>
        )}
        {step < 5 && (
          <Button style={{ margin: '5px' }} variant="contained" color="secondary" onClick={cancel}>
            Annuler
          </Button>
        )}
      </div>
    </div>
  );
};

export default ModalImportCSV;
