import DisplayLevel from '../../../BeecomingTypes/DisplayLevel';
import DataInterface from './DataInterface';

interface BusFromFetch {
  color: string;
  busStops: any;
  name: string;
}

interface FieldsInterface {
  title: string;
  color: string;
}

interface BusInterface extends DataInterface {
  title: string;
  busStops: [];
  color: string;
}

function BusFormatter(data: BusFromFetch): BusInterface {
  return {
    id: data.name,
    title: data.name,
    busStops: data.busStops,
    color: data.color,
  };
}

export default BusInterface;
export { BusFormatter };
