import React, { FunctionComponent } from 'react';
import ArretBusAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/ArretBusAdditionalArgs';
import { ArretBusInterface } from '../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import ArretBusSharedData from '../../../Types/Interface/TableInterfaces/SharedDataInterface/ArretBusSharedData';
import GetArretBusComparatorFromKey from '../../../Methods/TableQuery/HandleFilter/HandleSort/GetArretBusComparatorFromKey';
import AdminListContainer from '../BeecomingCompenents/AdminContainers/AdminListContainer';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import ListHeaderArretBus from '../BeecomingCompenents/ListElements/ListHeaders/ListHeaderArretBus';
import ListItemArretBus from '../BeecomingCompenents/ListElements/ListItems/ListItemArretBus';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import GetArretBusAdditionalQuery, {
  parseArretBusList,
} from '../../../Methods/TableQuery/GetAdditionalQueries/GetArretBusAdditionalQuery';
import { ArretBusFormatter } from '../../../Types/Interface/TableInterfaces/DataInterface/ArretBusInterface';

interface Props {}

const getBusList = (additionalParameters: ArretBusAdditionalArgs, history: any) => {
  const query = GetArretBusAdditionalQuery(additionalParameters);
  return ApiFetch('/bus-stop', 'GET', history);
  // Requete qui récupère tous les arrêts existants
};

const ListArretBus: AdminPageComponent = ({ onError }) => (
  <AdminListContainer<ArretBusInterface, ArretBusAdditionalArgs, ArretBusSharedData>
    addNew="Ajouter un arrêt"
    defaultSharedData={{ highlight: '' }}
    defaultAdditionalArgs={{ title: '', body: '', start: null, end: null }}
    defaultSort="name"
    headComponent={ListHeaderArretBus}
    listComponent={ListItemArretBus}
    getList={{
      request: getBusList,
      formatter: ArretBusFormatter,
      filter: parseArretBusList,
      errorMessage: "La liste des arrêts de bus n'a pas pu être chargée correctement",
    }}
    getComparatorFromKey={GetArretBusComparatorFromKey}
    onError={onError}
  />
);

const ListArretBusProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Arrêts de bus"
    menuPath="arret-bus"
    screenName="arret-bus"
    adminPage={ListArretBus}
  />
);

export default ListArretBusProtected;
