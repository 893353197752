import React, { ChangeEventHandler } from 'react';
import AdditionalArgsInterface from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/AdditionalArgsInterface';

function GetHandleFilter<AdditionalArgs extends AdditionalArgsInterface>(
  arg: keyof AdditionalArgs,
  args: AdditionalArgs,
  onChange: (_value: AdditionalArgs) => Promise<boolean>,
  timer: any,
  setTimer: React.Dispatch<React.SetStateAction<any>>,
  time: number = 500,
): ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timer);
    const newArg = event.target.value as unknown as AdditionalArgs[typeof arg];
    setTimer(
      setTimeout(() => {
        const newArgs: AdditionalArgs = args;
        newArgs[arg] = newArg;
        onChange(newArgs);
      }, time),
    );
  };
}

export default GetHandleFilter;
