/* eslint-disable no-unused-vars */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AdminScreenPathsList from '../../../../Datas/BeecomingDatas/AdminScreenPathsList';
import OtherScreenPathsList from '../../../../Datas/BeecomingDatas/OtherScreenPathsList';
import UserData from '../../../../Datas/UserData';
import refreshToken, {
  startRefreshContinuously,
} from '../../../../Methods/RefreshToken/RefreshToken';
import AdminScreen from '../../../../Types/BeecomingTypes/AdminScreen';
import User from '../../../../Types/BeecomingTypes/User';
import AdminPageComponent from '../../../../Types/Interface/ComponentInterface/AdminPageComponent';
import AdminTitle from '../../../../Types/ProjectTypes/AdminTitle';
import AdminContainer from './AdminContainer';

interface Props {
  title: AdminTitle;
  screenName: AdminScreen;
  menuPath: AdminScreen;
  adminPage: AdminPageComponent;
}

const AdminProtection: FunctionComponent<Props> = ({ title, screenName, menuPath, adminPage }) => {
  const history = useHistory();
  const [isLogedIn, setIsLogedIn] = useState(false);
  const [login, setLogin] = useState<string>('non connecté');
  const [token, setToken] = useState<string>('token absent');
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const urlRequest = process.env.REACT_APP_URL_REQUEST;

    setLogin('en connexion');
    let isSubscribed = true;

    const newLogin: string = UserData.getUserProperty('email') ?? login;
    const newToken: string = UserData.getUserProperty('token') ?? token;

    const userStorage = localStorage.getItem('user');

    let checkUser: User | null = null;

    if (userStorage) {
      checkUser = JSON.parse(userStorage);
    } else {
      history.push(OtherScreenPathsList['admin-connection']);
    }

    fetch(`${urlRequest}/compte/est-admin`, {
      method: 'GET',
      credentials: 'include',
    }).then((response) => {
      if (checkUser) {
        UserData.setUser(checkUser);
        setIsLogedIn(true);
        setLogin(checkUser.email!);
        setToken(checkUser.token!);
      }

      localStorage.setItem('last-screen', screenName);
      isSubscribed = true;
      if (isSubscribed) {
        if (!response.ok) {
          refreshToken().then((resp) => {
            if (resp == null) {
              setIsLogedIn(false);
              history.push(OtherScreenPathsList['admin-connection']);
            } else {
              if (checkUser) {
                UserData.setUser(checkUser);
                setIsLogedIn(true);
                setLogin(checkUser.email!);
                setToken(checkUser.token!);
              }

              localStorage.setItem('last-screen', screenName);
              startRefreshContinuously();

              history.push('last-screen', screenName);
            }
          });
        }
      }
    });
  }, []);
  return (
    <>
      {isLogedIn ? (
        <AdminContainer
          title={title}
          path={AdminScreenPathsList[menuPath]}
          adminLogin={login}
          adminPage={adminPage}
        />
      ) : null}
    </>
  );
};

export default AdminProtection;
