import DataInterface from './DataInterface';

interface ArretBusFromFetch {
  name: string;
  coordinate: [number, number];
  // eslint-disable-next-line camelcase
  code_stop: string;
}

interface FieldsInterface {
  coordinate: [number, number];
  name: string;
  // eslint-disable-next-line camelcase
  code_stop: string;
}

interface ArretBusInterface extends DataInterface {
  name: string;
  coordinate: [number, number];
  // eslint-disable-next-line camelcase
  code_stop: string;
  edit: string;
}

function ArretBusFormatter(data: ArretBusFromFetch): ArretBusInterface {
  return {
    id: data.code_stop,
    name: data.name,
    coordinate: data.coordinate,
    code_stop: data.code_stop,
    edit: 'Editer',
  };
}

export default ArretBusInterface;
export { ArretBusFormatter };
