/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable operator-linebreak */
import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert/Alert';
import 'date-fns';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LogoDragNDrop from '../../../assets/icons/move_btn.svg';
import AdminScreenPathsList from '../../../Datas/BeecomingDatas/AdminScreenPathsList';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import '../Admin.scss';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';

interface Props {}

const AdminEditStopOfBus: AdminPageComponent = () => {
  const urlRequest = process.env.REACT_APP_URL_REQUEST;

  const history = useHistory();
  const location = useLocation<any>();
  const [nameOfLine, setnameOfLine] = useState<string>(location?.state?.nameOfLine);
  const [alert, setAlert] = useState<string>('null');
  const [lineBusCurrent, setLineCurrent] = useState<any>([]);
  // const [listStopBus, setListStopBus] = useState<any>([]);
  const [tabCheckStop, setTabCheckStop] = useState<any>([]);
  const [tabOrderStop, setTabOrderStop] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reset, setReset] = useState<boolean>(false);
  const [dragId, setDragId] = useState();

  const getCurrentLine = async () => {
    // Récupération des infos de la ligne de bus actuelle
    let lineCurrent: any[] = [];
    // eslint-disable-next-line implicit-arrow-linebreak
    await ApiFetch(`${urlRequest}/bus-line/Gastines%20vers%20Tuilerie`, 'GET', history)
      .then((response) => response.json())
      .then((data) => {
        setnameOfLine(data.name);
        const tabData = [];
        tabData.push(data);
        setLineCurrent(tabData);
        lineCurrent = tabData;
      });
    return lineCurrent;
  };

  const getBusStops = async () => {
    // Récupération de la liste de tous les arrêts existants
    let allBusStops: any[] = [];
    // eslint-disable-next-line implicit-arrow-linebreak
    await ApiFetch('/bus-stop', 'GET', history)
      .then((response) => response.json())
      .then((data) => {
        allBusStops = data;
      });
    return allBusStops;
  };

  useEffect(() => {
    const tabCheck: any[] = [];
    getBusStops().then((allBusStops) => {
      // onr récupère la liste de tous les arrêts de bus existants
      allBusStops.forEach((el, index) => {
        // On ajoute une clef 'check' pour chaque arret dans notre tabCheck
        tabCheck.push({
          code_stop: el.code_stop,
          name: el.name,
          coordinate: el.coordinate,
          check: false, // tous à false au départ
        });
      });

      getCurrentLine().then((line) => {
        // on récupère la ligne de bus actuelle
        if (line) {
          if (line[0]?.busStops?.length !== 0) {
            // Si il y a deja des arrêts présents sur la ligne, je les check à true dans tabCheck
            line[0]?.busStops?.map((el: any) => {
              for (let y = 0; y < tabCheck.length; y++) {
                if (el.code_stop === tabCheck[y].code_stop) {
                  tabCheck[y].check = true;
                }
              }
            });
            const copyForOrder = [...line[0]?.busStops];
            // Ensuite j'ajoute les arrets checké, dans le tableau des arrêts ordonnés tabOrderStop
            copyForOrder.sort((el) => el.order);
            // Je trie ensuite le tableau pour qu'il respecte l'ordre des arrêts existant
            setTabOrderStop(copyForOrder);
          }
          setTabCheckStop(
            tabCheck.filter((v, i, a) => a.findIndex((t) => t.code_stop === v.code_stop) === i),
          );
          setIsLoading(false);
        }
      });
    });
  }, [reset]);

  const handleCheck = (value: any, codeStop: string) => {
    // Check ou Décheck les arrêts sur la ligne de bus
    const copy = [...tabCheckStop];
    const indexOfStopSelected = copy.findIndex((el) => el.code_stop === codeStop);
    copy[indexOfStopSelected].check = !copy[indexOfStopSelected].check;
    setTabCheckStop(copy);
    const copyForOrder = [...tabOrderStop];
    // Mise à jour dans le tableau d'ordre des arrets
    if (copy[indexOfStopSelected].check) {
      // Si c'est checké, je l'ajoute au tableau d'ordre
      copyForOrder.unshift(copy[indexOfStopSelected]);
    } else {
      // sinon je le supprime du tableau
      const indexOfDelete = copyForOrder.findIndex(
        (el) => el.code_stop === copy[indexOfStopSelected].code_stop,
      );
      copyForOrder.splice(indexOfDelete, 1);
    }
    // eslint-disable-next-line no-return-assign
    copyForOrder.forEach((el: any, index: number) => (el.order = index));
    // je mets à jour l'ordre des arrêts
    setTabOrderStop(copyForOrder);
  };

  const handleDrag = (ev: any) => {
    // Arrets que je déplace
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev: any) => {
    // Dépose de l'arrêt qui enclenche le changement d'ordre dans la liste d'arrêts
    const dragBox = tabOrderStop.find((box: any) => box.code_stop === dragId);
    // Drag = Arrêt que je prends
    const dropBox = tabOrderStop.find((box: any) => box.code_stop === ev.currentTarget.id);
    // Drop = l'Arrêt où je dépose celui que je tiens
    const dragBoxOrder = dragBox.order;
    const dropBoxOrder = dropBox.order;
    const newBoxState = tabOrderStop.map((box: any) => {
      // changement ordre box attrapé + ordre endroit déposée
      if (box.code_stop === dragId) {
        // Si box actuelle == id box attrapée, alors on interverti
        // eslint-disable-next-line no-param-reassign
        box.order = dropBoxOrder;
      }
      if (box.code_stop === ev.currentTarget.id) {
        // eslint-disable-next-line no-param-reassign
        box.order = dragBoxOrder;
      }
      return box;
    });
    setTabOrderStop(newBoxState);
  };

  const navigate = (screen: string) => {
    // navigation vers la page d'édition des horaires
    history.push({
      pathname: AdminScreenPathsList[screen],
      state: { arrets: tabOrderStop, nameOfLine },
    });
  };

  const validate = () => {
    // On mets à jour dans la bdd
    // lineCurrent[0].busStops = tabOrderStop
    // Msg qui affiche si la requete à fonctionnée ou non
  };

  const annulation = () => {
    // On annule les modifs
    // On remets le tableau des check et de l'odre au valeurs de depart
    setReset(!reset);
  };

  return isLoading ? (
    <p>En attente ...</p>
  ) : (
    <div>
      <Button onClick={() => navigate('line-bus-hour-edition')}>Gérer les horaires</Button>
      <Button onClick={() => navigate('line-bus-stop-edition')}>Gérer les arrêts</Button>
      <div className="titleContainer ">
        <p className="h1">Ligne</p>
        <p className="h1">{nameOfLine}</p>
        {/* <p className="h1">-arrêts de la ligne</p> */}
      </div>
      {alert !== 'null' && (
        <Alert
          onClose={() => setAlert('null')}
          severity={alert === 'success' ? 'success' : 'error'}
        >
          {alert === 'success' ? 'Ligne ajoutée !' : 'Erreur'}
        </Alert>
      )}
      <div className="newElementContainer">
        <div className="checkboxStopContainer newElementColumn">
          <p className="textNewElementProperty">Sélection des arrêts de la ligne</p>
          <div
            className="newElementProperty "
            style={{
              border: '1px solid black',
              borderRadius: '5px',
              margin: '15pt',
              padding: '5px',
            }}
          >
            <div className="containerStop scroll">
              {tabCheckStop.map((el: any, index: number) => (
                <label htmlFor={`check${index}`} className="labelStop">
                  <input
                    type="checkbox"
                    id={`check${index}`}
                    checked={el.check}
                    value={el.check}
                    onChange={(e) => handleCheck(e.target.value, el.code_stop)}
                  />
                  {el.name}
                </label>
              ))}
            </div>
          </div>
        </div>
        <div className="checkboxStopContainer newElementColumn">
          <p className="textNewElementProperty">Ordre des arrêts sur la ligne</p>
          <div
            className="newElementProperty "
            style={{
              border: '1px solid black',
              borderRadius: '5px',
              margin: '15pt',
              padding: '5px',
            }}
          >
            <div className="containerStop containerDrag">
              {tabOrderStop
                // .filter((el: any) => el.check === true)
                .sort((a: any, b: any) => a.order - b.order)
                .map((el: any) => (
                  <div
                    draggable
                    id={el.code_stop}
                    onDragStart={handleDrag}
                    onDrop={handleDrop}
                    onDragOver={(ev) => ev.preventDefault()}
                    style={{ display: 'flex', width: '100%' }}
                  >
                    <img src={LogoDragNDrop} alt="" className="iconDragNDrop" />
                    <p className="textItemDrag">{el.name}</p>
                  </div>
                ))}
            </div>
          </div>
          <Button onClick={validate}>Valider</Button>
          <Button onClick={annulation}>Annuler</Button>
        </div>
      </div>
    </div>
  );
};

const AdminEditStopOfBusBusProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Lignes de bus"
    screenName="line-bus"
    menuPath="line-bus"
    adminPage={AdminEditStopOfBus}
  />
);

export default AdminEditStopOfBusBusProtected;
