import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminScreenPathsList from '../../Datas/BeecomingDatas/AdminScreenPathsList';
import OtherScreenPathsList from '../../Datas/BeecomingDatas/OtherScreenPathsList';
import AdminAddBusProtected from './AdminPages/AdminAddBusProtected';
import AdminEditActualityProtected from './AdminPages/AdminEditActualityProtected';
import AdminEditArretBusProtected from './AdminPages/AdminEditArretBusProtected';
import AdminEditExceptionProtected from './AdminPages/AdminEditExceptionProtected';
import AdminEditFeaturesProtected from './AdminPages/AdminEditFeaturesProtected';
import AdminEditHourBusProtected from './AdminPages/AdminEditHourBusProtected';
import AdminEditManagingRightProtected from './AdminPages/AdminEditManagingRightProtected';
import AdminEditNotificationProtected from './AdminPages/AdminEditNotificationProtected';
import AdminEditParkingProtected from './AdminPages/AdminEditParkingProtected';
import AdminEditStopOfBusBusProtected from './AdminPages/AdminEditStopOfBusProtected';
import AdminEditWasteCollectProtected from './AdminPages/AdminEditWasteCollectProtected';
import AdminLogin from './AdminPages/AdminLogin';
import AdminProfilProtected from './AdminPages/AdminProfilProtected';
import AdminPromoteProtected from './AdminPages/AdminPromoteProtected';
import AdminStatisticsProtected from './AdminPages/AdminStatisticsProtected';
import ListActualityProtected from './AdminPages/ListActualityProtected';
import ListAgendaProtected from './AdminPages/ListAgendaProtected';
import ListArretBusProtected from './AdminPages/ListArretBusProtected';
import ListBusProtected from './AdminPages/ListBusProtected';
import ListFeaturesProtected from './AdminPages/ListFeaturesProtected';
import ListGarbageProtected from './AdminPages/ListGarbageProtected';
import ListManagingRightProtected from './AdminPages/ListManagingRightProtected';
import ListNotificationProtected from './AdminPages/ListNotificationProtected';
import ListParkingProtected from './AdminPages/ListParkingProtected';
import ListSignalementProtected from './AdminPages/ListSignalementProtected';
import ListProcedureProtected from './AdminPages/ListProcedureProtected';
import './App.scss';
import HomePage from './HomePage';
import HomeRedirect from './HomeRedirect';
import CreateAccount from './OtherPages/CreateAccount';
import EmailChange from './OtherPages/EmailChange';
import EmailConfirm from './OtherPages/EmailConfirm';
import ForgotPasswod from './OtherPages/ForgotPasswod';
import PasswordChange from './OtherPages/PasswordChange';
import PasswordReset from './OtherPages/PasswordReset';
import { LegalMentions } from './OtherPages/LegalMentions';

function App() {
  return (
    <div className="App">
      {window.location.href.includes('mentions-legales') ? (
        <LegalMentions />
      ) : (
        <Router>
          <div>
            {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
            <Switch>
              <Route path={OtherScreenPathsList['email-confirm']}>
                <EmailConfirm />
              </Route>
              <Route path={OtherScreenPathsList['password-forgot']}>
                <ForgotPasswod />
              </Route>
              <Route path={OtherScreenPathsList['password-success']}>
                <PasswordChange />
              </Route>
              <Route path={OtherScreenPathsList['password-recup']}>
                <PasswordReset />
              </Route>
              <Route path={OtherScreenPathsList['email-change']}>
                <EmailChange />
              </Route>
              <Route path={OtherScreenPathsList['account-new']}>
                <CreateAccount />
              </Route>
              <Route path={AdminScreenPathsList.statistics}>
                <AdminStatisticsProtected />
              </Route>
              <Route path={AdminScreenPathsList.agenda}>
                <ListAgendaProtected />
              </Route>
              <Route path={AdminScreenPathsList['actuality-edition']}>
                <AdminEditActualityProtected />
              </Route>
              <Route path={AdminScreenPathsList['parking-edition']}>
                <AdminEditParkingProtected />
              </Route>
              <Route path={AdminScreenPathsList.parking}>
                <ListParkingProtected />
              </Route>
              <Route path={AdminScreenPathsList.actuality}>
                <ListActualityProtected />
              </Route>
              <Route path={AdminScreenPathsList['line-bus-hour-edition']}>
                <AdminEditHourBusProtected />
              </Route>
              <Route path={AdminScreenPathsList['line-bus-name-and-color-edition']}>
                <AdminAddBusProtected />
              </Route>
              <Route path={AdminScreenPathsList['line-bus-stop-edition']}>
                <AdminEditStopOfBusBusProtected />
              </Route>
              <Route path={AdminScreenPathsList['line-bus-add']}>
                <AdminAddBusProtected />
              </Route>
              <Route path={AdminScreenPathsList['line-bus']}>
                <ListBusProtected />
              </Route>
              <Route path={AdminScreenPathsList['arret-bus-edition']}>
                <AdminEditArretBusProtected />
              </Route>
              <Route path={AdminScreenPathsList['arret-bus']}>
                <ListArretBusProtected />
              </Route>
              <Route path={AdminScreenPathsList['managing-right-add-account']}>
                <AdminEditManagingRightProtected />
              </Route>
              <Route path={AdminScreenPathsList['managing-right']}>
                <ListManagingRightProtected />
              </Route>
              <Route path={AdminScreenPathsList.signalement}>
                <ListSignalementProtected />
              </Route>
              <Route path={AdminScreenPathsList.demarche}>
                <ListProcedureProtected />
              </Route>
              <Route path={AdminScreenPathsList['notification-edition']}>
                <AdminEditNotificationProtected />
              </Route>
              <Route path={AdminScreenPathsList.notification}>
                <ListNotificationProtected />
              </Route>
              <Route path={AdminScreenPathsList['waste-collect-exception']}>
                <AdminEditExceptionProtected />
              </Route>
              <Route path={AdminScreenPathsList['waste-collect-edition']}>
                <AdminEditWasteCollectProtected />
              </Route>
              <Route path={AdminScreenPathsList['waste-collect']}>
                <ListGarbageProtected />
              </Route>
              <Route path={AdminScreenPathsList.account}>
                <AdminProfilProtected />
              </Route>
              <Route path={AdminScreenPathsList['admin-promote']}>
                <AdminPromoteProtected />
              </Route>
              <Route path={OtherScreenPathsList['admin-connection']}>
                <AdminLogin />
              </Route>
              <Route path={AdminScreenPathsList['features-edition']}>
                <AdminEditFeaturesProtected />
              </Route>
              <Route path={AdminScreenPathsList.features}>
                <ListFeaturesProtected />
              </Route>
              <Route path={AdminScreenPathsList.home}>
                <HomeRedirect />
              </Route>
              <Route path={OtherScreenPathsList['legal-mentions']}>
                <LegalMentions />
              </Route>
              <Route>
                <HomePage />
              </Route>
            </Switch>
            <ToastContainer pauseOnHover={false} position="bottom-right" />
          </div>
        </Router>
      )}
    </div>
  );
}

export default App;
