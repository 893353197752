import { createTheme } from '@material-ui/core/styles';
import ProjectColors from '../Datas/ProjectDatas/ProjectColors';

const ProjectTheme = createTheme({
  palette: {
    primary: {
      main: ProjectColors.primary,
      contrastText: 'rgba(255,255,255)',
    },
    secondary: {
      main: ProjectColors.secondary,
      contrastText: 'rgba(255,255,255)',
    },
    success: {
      main: ProjectColors.success,
      contrastText: 'rgba(255,255,255)',
    },
    error: {
      main: ProjectColors.error,
      contrastText: 'rgba(255,255,255)',
    },
    info: {
      main: ProjectColors.info,
      contrastText: 'rgba(255,255,255)',
    },
    warning: {
      main: ProjectColors.warning,
      contrastText: 'rgba(255,255,255)',
    },
  },
});

export default ProjectTheme;
