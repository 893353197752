/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import { Order } from '../../../../Types/BeecomingTypes/BeecomingTypes';
import GetComparatorFromKeyInterface from '../../../../Types/Interface/MethodInterface/GetComparatorFromKeyInterface';
import BusInterface from '../../../../Types/Interface/TableInterfaces/DataInterface/BusInterface';

const GetBusComparatorFromKey: GetComparatorFromKeyInterface<BusInterface> = (
  key: keyof BusInterface,
  typeOrder: Order,
) => {
  const sign: number = typeOrder === 'asc' ? 1 : -1;
  switch (key) {
    case 'title':
      return (a: BusInterface, b: BusInterface) => sign * a.title.localeCompare(b.title);

    default:
      return (a: BusInterface, b: BusInterface) =>
        sign * a[key].toLocaleString().localeCompare(b[key].toLocaleString());
  }
};

export default GetBusComparatorFromKey;
