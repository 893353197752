/* eslint-disable indent */
import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LogoParking from '../../../../assets/icons/parking_blue.svg';
import LogoAdministration from '../../../../assets/icons/administration.svg';
import LogoManagingRight from '../../../../assets/icons/avatar.svg';
import LogoBus from '../../../../assets/icons/transport_blue.svg';
import LogoFonctionnality from '../../../../assets/icons/fonctionality.svg';
import LogoSignalements from '../../../../assets/icons/report_black.svg';
import LogoStatistics from '../../../../assets/icons/statistics.svg';
import LogoAgenda from '../../../../assets/icons/agenda.svg';
import LogoActuality from '../../../../assets/icons/actuality.svg';
import LogoReport from '../../../../assets/icons/ask_blue.svg';
import LogoTrash from '../../../../assets/icons/waste.svg';
import AdminScreenPathsList from '../../../../Datas/BeecomingDatas/AdminScreenPathsList';
import AdminScreen from '../../../../Types/BeecomingTypes/AdminScreen';
import NavigationItem, { NavigationSubItem } from '../../../../Types/BeecomingTypes/NavigationItem';
import NavigationSubItemComponent from './NavigationSubItemComponent';

const NavigationItemComponent: FunctionComponent<NavigationItem> = ({ label, node, imgSrc }) => {
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const handleClick = () => setOpen(!open);
  const path = AdminScreenPathsList[node as AdminScreen];
  const subItems = path === undefined ? (node as NavigationSubItem[]) : undefined;

  const icon = () => {
    if (imgSrc === 'administration') {
      return (
        <img
          // eslint-disable-next-line global-require
          src={LogoAdministration}
          alt=""
          className="sidebarLogo"
        />
      );
    }
    if (imgSrc === 'statistics') {
      return <img src={LogoStatistics} alt="" className="sidebarLogo" />;
    }
    if (imgSrc === 'signalements') {
      return <img src={LogoSignalements} alt="" className="sidebarLogo" />;
    }
    if (imgSrc === 'fonctionalities') {
      return <img src={LogoFonctionnality} alt="" className="sidebarLogo" />;
    }
    if (imgSrc === 'managing-right') {
      return <img src={LogoManagingRight} alt="" className="sidebarLogo" />;
    }
    if (imgSrc === 'bus') {
      return <img src={LogoBus} alt="" className="sidebarLogo" />;
    }
    if (imgSrc === 'parking') {
      return <img src={LogoParking} alt="" className="sidebarLogo" />;
    }
    if (imgSrc === 'agenda') {
      return <img src={LogoAgenda} alt="" className="sidebarLogo" />;
    }
    if (imgSrc === 'actuality') {
      return <img src={LogoActuality} alt="" className="sidebarLogo" />;
    }
    if (imgSrc === 'trash') {
      return <img src={LogoTrash} alt="" className="sidebarLogo" />;
    }
    if (imgSrc === 'ask') {
      return <img src={LogoReport} alt="" className="sidebarLogo" />;
    }
    return <img src={LogoStatistics} alt="" className="sidebarLogo" />;
  };

  useEffect(() => {}, [imgSrc]);

  return (
    <div>
      <ListItem
        button
        component="a"
        onClick={
          path !== undefined
            ? () => {
                if (path !== undefined) {
                  history.push(path as string);
                }
              }
            : handleClick
        }
      >
        {/* <ListItemIcon></ListItemIcon> */}
        {icon()}
        <ListItemText primary={label} />
        {subItems !== undefined ? <>{open ? <ExpandLess /> : <ExpandMore />}</> : null}
      </ListItem>
      {subItems !== undefined ? (
        <Collapse in={open}>
          <List component="ul" disablePadding>
            {subItems.map((item) => (
              <NavigationSubItemComponent key={item.label} label={item.label} to={item.to} />
            ))}
          </List>
        </Collapse>
      ) : null}
    </div>
  );
};

export default NavigationItemComponent;
