import GetAdditionalQueryInterface from '../../../Types/Interface/MethodInterface/GetAdditionalQueryInteface';
import FeaturesAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/FeaturesAdditionalArgs';
import { FeaturesInterface } from '../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import StringContains from '../../StringContains';

const GetFeaturesAdditionalQuery: GetAdditionalQueryInterface<FeaturesAdditionalArgs> = (
  _additionalQuery: FeaturesAdditionalArgs,
) => null;

// some additional args are not well handle by the clloApi, so their treated by the front
const parseFeaturesList = (
  dataBlock: FeaturesInterface[],
  args: FeaturesAdditionalArgs,
): FeaturesInterface[] => dataBlock.filter((row) => StringContains(row.title, args.title));

export default GetFeaturesAdditionalQuery;
export { parseFeaturesList };
