/* eslint-disable operator-linebreak */
import { Button, colors, TextField } from '@material-ui/core';
import 'date-fns';
import React, { Dispatch, FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AdminScreenPathsList from '../../../Datas/BeecomingDatas/AdminScreenPathsList';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import '../Admin.scss';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';

interface Props {}

/**
 * @param onError method: show a snackbar with the chosen message
 * @returns Notification Edit and Creation Page
 */

const AdminEditArretBus: AdminPageComponent = () => {
  const history = useHistory();

  const [id, setId] = useState<string | null>(null);

  const [nomArret, setNomArret] = useState<string | null>('');
  const [codeArret, setCodeArret] = useState<string | null>('');

  const [latArret, setLatArret] = useState<number | null>();
  const [latInput, setLatInput] = useState<string>('');
  const [lngArret, setLngArret] = useState<number | null>();
  const [lngInput, setLngInput] = useState<string>('');

  useEffect(() => {
    setLatArret(parseFloat(latInput));
    setLngArret(parseFloat(lngInput));
  }, [latInput, lngInput]);

  // #region Errors
  const [errorNomArret, setErrorNomArret] = useState<boolean>(false);
  const [errorCodeArret, setErrorCodeArret] = useState<boolean>(false);
  const [errorLatArret, setErrorLatArret] = useState<boolean>(false);
  const [errorLngArret, setErrorLngArret] = useState<boolean>(false);
  // #endregion

  // #region Fetch data
  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const arretBusId = params.get('id');
    setId(arretBusId);

    getArretBusData(history, arretBusId, setNomArret, setCodeArret, setLatInput, setLngInput);
  }, []);
  // #endregion

  // #region handle Validation
  const isValid = (): boolean => {
    let valid: boolean = true;
    if (nomArret === '') {
      valid = false;
      setErrorNomArret(true);
    }
    if (codeArret === '') {
      valid = false;
      setErrorCodeArret(true);
    }
    if (Number.isNaN(parseFloat(latInput))) {
      valid = false;
      setErrorLatArret(true);
    }
    if (Number.isNaN(parseFloat(lngInput))) {
      valid = false;
      setErrorLngArret(true);
    }
    return valid;
  };

  const handleValidation = () => {
    if (isValid()) {
      const dataToSend = {
        name: nomArret,
        code_stop: codeArret,
        lat: latArret,
        lon: lngArret,
      };

      if (!id) {
        ApiFetch('/bus-stop/', 'POST', history, dataToSend).then((response) => {
          if (response.ok) {
            toast.success("L'arrêt de bus à été créer");
            history.push(AdminScreenPathsList['arret-bus']);
          } else {
            toast.error("Echec de la création de l'arrêt de bus");
          }
        });
      } else {
        ApiFetch(`/bus-stop/${id}`, 'PUT', history, dataToSend).then((response) => {
          if (response.ok) {
            toast.success("L'arrêt de bus à bien été modifié");
          } else {
            toast.error("Echec de la création de l'arrêt de bus");
          }
        });
      }
    }
  };
  // #endregion

  const handleDelete = () => {
    if (window.confirm('Etes vous sûr de vouloir supprimer cet arrêt de bus?')) {
      ApiFetch(`/bus-stop/${id}`, 'DELETE', history).then((response) => {
        if (response.ok) {
          toast.success("L'arrêt de bus a bien été supprimé");
          history.push(AdminScreenPathsList['arret-bus']);
        } else {
          toast.error('Echec de la suppression.');
        }
      });
    }
  };

  return (
    <div>
      <h1>{id != null ? "Edition de l'arrêt de bus" : "Ajout d'un arrêt de bus"}</h1>

      <div>
        <div className="inputContainer">
          <TextField
            label={"Nom de l'arrêt"}
            value={nomArret}
            error={errorNomArret}
            required
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setNomArret(e.target.value);
              setErrorNomArret(false);
            }}
          />
        </div>

        {!id && (
          <div className="inputContainer">
            <TextField
              label={"Code de l'arrêt"}
              value={codeArret}
              error={errorCodeArret}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setCodeArret(e.target.value);
                setErrorCodeArret(false);
              }}
            />
          </div>
        )}

        <p className="textNewElementProperty">Coordonnées GPS</p>
        <div
          style={{ display: 'flex', flexDirection: 'row', paddingTop: 0 }}
          className="newElementProperty"
        >
          <TextField
            style={{ width: '100%', marginRight: 5 }}
            label="Latitude"
            value={latInput}
            error={errorLatArret}
            required
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setLatInput(event.target.value);
              setErrorLatArret(false);
            }}
          />

          <TextField
            style={{ width: '100%' }}
            label="Longitude"
            value={lngInput}
            error={errorLngArret}
            required
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setLngInput(event.target.value);
              setErrorLngArret(false);
            }}
          />
        </div>

        <div className="newElementProperty btnContainer">
          <Button
            style={{ marginRight: '5px' }}
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => handleValidation()}
          >
            Valider
          </Button>
          {id && (
            <Button
              fullWidth
              variant="contained"
              style={{ backgroundColor: colors.red[700], color: '#FFFFFF' }}
              onClick={handleDelete}
            >
              Supprimer
            </Button>
          )}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

const AdminEditArretBusProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Retour à la liste"
    screenName="arret-bus"
    menuPath="arret-bus"
    adminPage={AdminEditArretBus}
  />
);

export default AdminEditArretBusProtected;

const getArretBusData = (
  history: any,
  arretBusId: string | null,
  setNomArret: Dispatch<string>,
  setCodeArret: Dispatch<string>,
  setLatArret: Dispatch<string>,
  setLngArret: Dispatch<string>,
) => {
  if (arretBusId) {
    ApiFetch(`/bus-stop/${arretBusId}`, 'GET', history)
      .then((response) => response.json())
      .then((data: any) => {
        setNomArret(data.name);
        setCodeArret(data.code_stop);
        setLatArret(data.coordinate[0]);
        setLngArret(data.coordinate[1]);
      });
  }
};
