/* eslint-disable operator-linebreak */
import {
  Button,
  Checkbox,
  CheckboxProps,
  CircularProgress,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { grey, yellow } from '@material-ui/core/colors';
import { Star, StarBorder } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UseBeecomingStyles from '../../../../../Themes/UseBeecomingStyles';
import FeaturesInterface from '../../../../../Types/Interface/TableInterfaces/DataInterface/FeaturesInterface';
import ListItemProps from '../../../../../Types/Interface/Props/ListItemProps';
import FeaturesSharedData from '../../../../../Types/Interface/TableInterfaces/SharedDataInterface/FeaturesSharedData';
import HandleCheckBoxFeatures from '../../../../../Methods/FeaturesHandler/HandleCheckBoxFeatures';
import HandleCheckBoxMainFeatures from '../../../../../Methods/FeaturesHandler/HandleCheckBoxMainFeatures';
import DisplayLevel from '../../../../../Types/BeecomingTypes/DisplayLevel';

const ListItemFeatures: FunctionComponent<ListItemProps<FeaturesInterface, FeaturesSharedData>> = ({
  row,
  sharedData,
  setSharedData,
  setRow,
  onError,
}) => {
  const history = useHistory();
  const [childRow, setChildRow] = useState<FeaturesInterface>(row);
  const [shown, setShown] = useState<boolean>(row.level >= DisplayLevel.Listed);
  const [inCarousel, setInCarousel] = useState<boolean>(row.level >= DisplayLevel.Carousel);

  // loaders
  const [shownLoader, setShownLoader] = useState<boolean>(false);
  const [carousselLoader, setCarousselLoader] = useState<boolean>(false);
  const [hightlightLoader, setHightlightLoader] = useState<boolean>(false);

  // eslint-disable-next-line prefer-destructuring
  const highlight = sharedData.highlight;
  const highlightSetter = (h: string) => {
    setSharedData({ highlight: h });
  };
  const setDisplayLevel = (l: DisplayLevel): void => {
    const newRow: FeaturesInterface = childRow;
    newRow.level = l;
    // need to update data in parent and child
    setRow(newRow);
    setChildRow(newRow);
    setShown(newRow.level >= DisplayLevel.Listed);
    setInCarousel(newRow.level >= DisplayLevel.Carousel);
  };

  const YellowCheckBox = withStyles({
    root: {
      color: grey[500],
      '&$checked': {
        color: yellow[600],
      },
    },
    checked: {},
    // eslint-disable-next-line react/jsx-props-no-spreading
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);
  const classes = UseBeecomingStyles();

  const handleError = () => {
    onError("Une erreur s'est produite");
  };

  return (
    <TableRow>
      <TableCell>{row.title}</TableCell>
      <TableCell align="center">
        {shownLoader && <CircularProgress className={classes.loader} />}
        <Checkbox
          checked={shown}
          onChange={async (event) => {
            HandleCheckBoxFeatures(
              DisplayLevel.Listed,
              event,
              row.id,
              highlight,
              setDisplayLevel,
              highlightSetter,
              setShownLoader,
              handleError,
              history,
            );
          }}
        />
      </TableCell>
      <TableCell align="center">
        {carousselLoader && <CircularProgress className={classes.loader} />}
        <Checkbox
          checked={inCarousel}
          onChange={async (event) => {
            HandleCheckBoxFeatures(
              DisplayLevel.Carousel,
              event,
              row.id,
              highlight,
              setDisplayLevel,
              highlightSetter,
              setCarousselLoader,
              handleError,
              history,
            );
          }}
        />
      </TableCell>
      <TableCell align="center">
        <Button
          onClick={() => {
            console.log('click sur éditer');
          }}
          color="secondary"
          style={{ fontSize: 12 }}
        >
          Éditer
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default ListItemFeatures;
