import { Button, TableCell, TableRow } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import AdminScreenPathsList from '../../../../../Datas/BeecomingDatas/AdminScreenPathsList';
import useCcloStyles from '../../../../../Themes/UseBeecomingStyles';
import NotificationInterface from '../../../../../Types/Interface/TableInterfaces/DataInterface/NotificationInterface';
import ListItemProps from '../../../../../Types/Interface/Props/ListItemProps';
import SharedDataInterface from '../../../../../Types/Interface/TableInterfaces/SharedDataInterface/SharedDataInterface';

const ListItemNotification: FunctionComponent<
  ListItemProps<NotificationInterface, SharedDataInterface>
> = ({ row }) => {
  const ccloStyles = useCcloStyles();
  const history = useHistory();
  return (
    <TableRow>
      <TableCell>{row.title}</TableCell>
      <TableCell>{row.body}</TableCell>
      <TableCell>{new Date(row.sendingTime).toLocaleDateString()}</TableCell>
      <TableCell>{new Date(row.sendingTime).toLocaleTimeString()}</TableCell>
      <TableCell>
        <Button
          className={ccloStyles.BeecomingButton}
          color="primary"
          onClick={() => {
            history.push(`${AdminScreenPathsList['notification-edition']}?id=${row.id}`);
          }}
        >
          Editer
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default ListItemNotification;
