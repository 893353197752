import './LegalMentions.scss';

export const LegalMentions = () => (
  <div id="legal-mentions">
    <h1>MENTIONS LÉGALES</h1>
    <h2>DROIT D’ACCÈS, DE MODIFICATION ET DE SUPPRESSION DES DONNÉES PERSONNELLES</h2>
    <p>
      Les données vous concernant dans le cadre de l&apos;application sont strictement
      confidentielles et ne peuvent être communiquées à un tiers. Lors de l&apos;utilisation de
      l&apos;application, vous pouvez être amené à saisir des données personnelles tels que :{' '}
      <ul>
        <li>
          <b>Votre adresse mail, lors de la création d&apos;un compte</b> : Utilisé pour la
          connexion, et la réinitialisation du mot de passe
        </li>
        <li>
          <b>Votre adresse physique</b> : Pour obtenir des notifications personnalisés liées aux
          collectes de déchets
        </li>
        <li>
          <b>Votre position</b> : Pour connaître les évènements et les points d&apos;intérêt à
          proximité
        </li>
      </ul>
    </p>
    <p>
      En application de la loi nº 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et
      aux libertés, vous disposez des droits d’opposition (art. 26 de la loi), d’accès (art. 34 à 38
      de la loi) et de rectification (art. 36 de la loi) des données vous concernant.
    </p>
    <p>
      <b>Pour faire valoir ces droits, contactez-nous</b> :
      <ul>
        <li>
          {' '}
          en envoyant un courrier électronique à :{' '}
          <a href="mailto:communication@sablesursarthe.fr">communication@sablesursarthe.fr</a>
        </li>
        <li> en envoyant un courrier à l’adresse suivante : </li>
      </ul>
      Ville de Sablé-sur-Sarthe – Direction de la Communication <br /> Place Raphaël Elizé <br />
      B.P. 129 <br />
      72305 Sablé-sur-Sarthe CEDEX <br />
      Tél : <a href="tel:02.43.62.50.00">02.43.62.50.00</a>
    </p>
    <h2>INFORMATIONS ÉDITEUR</h2> Le site Internet de la Ville et de la Communauté de communes de
    Sablé-sur-Sarthe est réalisé par la Direction de la Communication en collaboration avec les
    différentes directions de la Ville et de la Communauté.
    <ul>
      <li>Directeur de la publication : Nicolas Leudière, Maire de Sablé-sur-Sarthe</li>
      <li>Direction Générale : Mélanie Duchemin</li>
      <li>Chargé de mission Internet : Aminata Coulibaly et Renaud Derrien</li>
      <li>
        Rédaction : Service communication Ville de Sablé-sur-Sarthe et Communauté de communes du
        Pays sabolien
      </li>
      <li> Réalisation et hébergement : société Inovagora</li>
    </ul>
    <h2>MISE À JOUR</h2> La mise à jour du site et de l&apos;application est réalisée régulièrement
    par le Service Communication. Nous rappelons que le contenu du site Internet peut comporter des
    inexactitudes ou omissions, et que ce contenu est susceptible d’être modifié ou mis à jour dans
    de brefs délais. Si vous constatez un lien brisé, une omission ou une erreur, vous pouvez le
    faire savoir auprès de du service communication par courriel, téléphone, ou par voie postale aux
    coordonnées ci-dessus. La Ville et la Communauté de communes de Sablé-sur-Sarthe déclinent toute
    responsabilité quant aux sites Internet qui possèdent un lien avec{' '}
    <a href="www.sablesursarthe.fr">www.sablesursarthe.fr</a>.
  </div>
);
