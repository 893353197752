/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import { Order } from '../../../../Types/BeecomingTypes/BeecomingTypes';
import GetComparatorFromKeyInterface from '../../../../Types/Interface/MethodInterface/GetComparatorFromKeyInterface';
import ArretBusInterface from '../../../../Types/Interface/TableInterfaces/DataInterface/ArretBusInterface';

const GetArretBusComparatorFromKey: GetComparatorFromKeyInterface<ArretBusInterface> = (
  key: keyof ArretBusInterface,
  typeOrder: Order,
) => {
  const sign: number = typeOrder === 'asc' ? 1 : -1;
  switch (key) {
    case 'name':
      return (a: ArretBusInterface, b: ArretBusInterface) => sign * a.name.localeCompare(b.name);
    default:
      return (a: ArretBusInterface, b: ArretBusInterface) =>
        sign * a[key].toLocaleString().localeCompare(b[key].toLocaleString());
  }
};

export default GetArretBusComparatorFromKey;
