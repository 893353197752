/* eslint-disable operator-linebreak */
import { Button, CircularProgress, TableCell, TableRow } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AdminScreenPathsList from '../../../../../Datas/BeecomingDatas/AdminScreenPathsList';
import UseBeecomingStyles from '../../../../../Themes/UseBeecomingStyles';
import ListItemProps from '../../../../../Types/Interface/Props/ListItemProps';
import ArretBusInterface from '../../../../../Types/Interface/TableInterfaces/DataInterface/ArretBusInterface';
import ArretBusSharedData from '../../../../../Types/Interface/TableInterfaces/SharedDataInterface/ArretBusSharedData';

const ListItemArretBus: FunctionComponent<ListItemProps<ArretBusInterface, ArretBusSharedData>> = ({
  row,
  sharedData,
  setSharedData,
  setRow,
  onError,
}) => {
  const history = useHistory();

  // loaders
  const [shownLoader, setShownLoader] = useState<boolean>(false);
  const [carousselLoader, setCarousselLoader] = useState<boolean>(false);
  const [hightlightLoader, setHightlightLoader] = useState<boolean>(false);

  const classes = UseBeecomingStyles();

  return (
    <TableRow style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
      <TableCell style={{ borderBottom: 'none' }}>
        {hightlightLoader && <CircularProgress className={classes.loader} />}
        {row.name}
      </TableCell>
      <TableCell align="center" style={{ borderBottom: 'none' }}>
        {shownLoader && <CircularProgress className={classes.loader} />}
        {`${row.coordinate[0]}, ${row.coordinate[1]}`}
      </TableCell>
      <TableCell align="center" style={{ borderBottom: 'none' }}>
        {carousselLoader && <CircularProgress className={classes.loader} />}
        {row.code_stop}
      </TableCell>
      <TableCell align="center" style={{ borderBottom: 'none' }}>
        <Button
          onClick={() => {
            history.push(`${AdminScreenPathsList['arret-bus-edition']}?id=${row.id}`);
          }}
          color="secondary"
        >
          Éditer
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default ListItemArretBus;
