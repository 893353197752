import { OtherScreenPaths } from '../../Types/BeecomingTypes/OtherScreen';

const OtherScreenPathsList: OtherScreenPaths = {
  home: '',
  'account-new': '/compte/nouveau',
  'email-confirm': '/email/confirmation/succes',
  'email-change': '/email/nouveau/succes',
  'password-forgot': '/mot-de-passe/oublie',
  'password-recup': '/mot-de-passe/nouveau',
  'password-success': '/mot-de-passe/nouveau/succes',
  'admin-connection': '/admin/connexion',
  'legal-mentions': '/mentions-legales',
};

export default OtherScreenPathsList;
