/* eslint-disable implicit-arrow-linebreak */
import SetDataSharedInterface from '../../../Types/Interface/MethodInterface/GetDataSharedInterface';
import AgendaSharedData from '../../../Types/Interface/TableInterfaces/SharedDataInterface/AgendaSharedData';
import ApiFetch from '../../RefreshToken/ApiRequest';

const GetAgendaHighLight: SetDataSharedInterface<AgendaSharedData> = ({ history }) =>
  ApiFetch('/OpenData/nouvelles?rows=1&displayLevel=2&thisDisplayLevelOnly=true', 'GET', history)
    .then((response) => (response.status !== 204 ? response.json() : undefined))
    .then((resp) => {
      if (resp !== undefined) {
        return { highlight: resp.records[0].recordid };
      }
      return { highlight: '' };
    });

export default GetAgendaHighLight;
