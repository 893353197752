import DisplayLevel from '../../../BeecomingTypes/DisplayLevel';
import DataInterface from './DataInterface';

interface ManagingRightFromFetch {
  recordid: string;
  fields: FieldsInterface;
  level: DisplayLevel;
}

interface FieldsInterface {
  title: string;
  published: Date;
}

interface ManagingRightInterface extends DataInterface {
  title: string;
  level: DisplayLevel;
  published: Date;
}

function ManagingRightFormatter(data: ManagingRightFromFetch): ManagingRightInterface {
  return {
    id: data.recordid,
    title: data.fields.title,
    level: data.level ?? -1,
    published: data.fields.published,
  };
}

export default ManagingRightInterface;
export { ManagingRightFormatter };
