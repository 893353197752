/* eslint-disable operator-linebreak */
/* eslint-disable no-restricted-globals */
import moment from 'moment';
import { useState } from 'react';
import UserData from '../../Datas/UserData';
import {
  KeyPickupTour,
  PickupTour,
  Address,
  KeyAdress,
  Exception,
  KeyException,
} from '../../Types/BeecomingTypes/WasteTypes';
import ApiFetch from '../RefreshToken/ApiRequest';

const convertCsvPickup: PickupTour[] = [];
const convertCsvAddress: Address[] = [];
const convertCsvException: Exception[] = [];

export const handleResetDataPickup = () => {
  convertCsvPickup.length = 0;
};

export const handleResetDataAddress = () => {
  convertCsvAddress.length = 0;
};

export const handleResetDataException = () => {
  convertCsvException.length = 0;
};

const uploadDatas = async (
  arrPickup: PickupTour[],
  arrAddress: Address[],
  history: History,
  arrException?: Exception[],
): Promise<boolean> => {
  try {
    const prepaPickup = JSON.stringify({
      pickupTours: arrPickup,
    });
    // console.log(prepaPickup);
    const prepaAdress = JSON.stringify({
      wasteAddresses: arrAddress,
    });
    // console.log(prepaAdress);
    const uploadpickup: Response = await fetch(
      `${process.env.REACT_APP_URL_REQUEST}/waste-pickup`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          authorization: `Bearer ${UserData.getUser().token}`,
          'Content-Type': 'Application/json',
        },
        body: prepaPickup,
      },
    );

    const uploadAdress: Response = await fetch(
      `${process.env.REACT_APP_URL_REQUEST}/waste-address`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          authorization: `Bearer ${UserData.getUser().token}`,
          'Content-Type': 'Application/json',
        },
        body: prepaAdress,
      },
    );

    let uploadException: Response | null = null;
    // console.log(UserData.getUser().token);
    if (convertCsvException.length > 0) {
      const prepaException = JSON.stringify({ exceptions: arrException });

      uploadException = await fetch(
        `${process.env.REACT_APP_URL_REQUEST}/waste-exception/add-multiple`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            authorization: `Bearer ${UserData.getUser().token}`,
            'Content-Type': 'Application/json',
          },
          body: prepaException,
        },
      );
    }

    if (uploadpickup.ok && uploadAdress.ok) {
      handleResetDataAddress();
      handleResetDataPickup();
      if (uploadException && !uploadException.ok) {
        return false;
      }
      handleResetDataException();
      return true;
    }
    throw new Error('upload impossible...');
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const importCSVHandle = (boolean: any, setModal: any) => {
  setModal((oldModal: any) => !oldModal);
  if (boolean) {
    console.log('true');
  } else {
    console.log('false');
  }
};

export const handleOnDropPickup = (data: any): boolean => {
  console.log(data);
  data?.forEach((el: any): void => {
    if (el.data.length !== 5) {
      return;
    }
    const currentRow: PickupTour = {
      code: null,
      name: null,
      day: null,
      type: null,
      recurrence: null,
    };
    el.data.forEach((item: string, index: number) => {
      currentRow[Object.keys(currentRow)[index] as KeyPickupTour] = item;
    });

    convertCsvPickup.push(currentRow);
  });

  if (convertCsvPickup.length) {
    return true;
  }
  return false;
};

export const handleOnDropException = (data: any): boolean => {
  data?.forEach((el: any): void => {
    if (el.data.length !== 3) {
      return;
    }
    const currentRow: Exception = {
      pickupTourCode: null,
      date: null,
      newDate: null,
    };
    el.data.forEach((item: string, index: number) => {
      if (index > 0) {
        currentRow[Object.keys(currentRow)[index] as KeyException] = moment(
          `${item.slice(0, 4)}-${item.slice(4, 6)}-${item.slice(6, 8)}T22:00:00Z`,
        ).toISOString();
      } else {
        currentRow[Object.keys(currentRow)[index] as KeyException] = item;
      }
    });

    convertCsvException.push(currentRow);
  });

  if (convertCsvException.length) {
    return true;
  }
  return false;
};

export const handleOnDropAddress = (data: any): boolean => {
  console.log(data);
  const checkAdress: string[] = [];
  data?.forEach((el: any): void => {
    if (el.data.length < 5 || el.data.length > 7) {
      return;
    }
    const currentRow: Address = {
      number: null,
      street: null,
      postCode: null,
      city: null,
      pickupTourCode: [],
    };

    el.data.forEach((item: string, index: number) => {
      if (
        // eslint-disable-next-line operator-linebreak
        index > 3
      ) {
        (currentRow.pickupTourCode as string[])?.push(item);
      } else {
        currentRow[Object.keys(currentRow)[index] as KeyAdress] = item;
      }
    });

    if (currentRow.number === '') {
      currentRow.number = 0;
    }

    currentRow.number =
      currentRow.number
        ?.toString()
        .split('')
        .filter((e) => !isNaN(parseInt(e, 10)))
        .join('') ?? 0;

    const address =
      currentRow.number.toString() +
      currentRow.street +
      currentRow.postCode?.toString() +
      currentRow.city;

    if (!checkAdress.includes(address)) {
      checkAdress.push(address);
      convertCsvAddress.push(currentRow);
    }
  });

  if (convertCsvAddress.length) {
    return true;
  }
  return false;
};

export const handleOnUpload = async (): Promise<boolean | unknown> => {
  try {
    if (convertCsvAddress.length && convertCsvPickup.length) {
      const upload = await uploadDatas(
        convertCsvPickup,
        convertCsvAddress,
        history,
        convertCsvException,
      );

      if (upload) {
        return true;
      }
    }
  } catch (e) {
    console.error(e);
    return e;
  }
  return false;
};

export const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
  console.log(err);
};

export const handleOnRemoveFile = (data: any) => {
  console.log('---------------------------');
  console.log(data);
  console.log('---------------------------');
};
