/* eslint-disable indent */
import {
  Checkbox,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { FiberManualRecord, Visibility } from '@material-ui/icons';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UseBeecomingStyles from '../../../../../Themes/UseBeecomingStyles';
import ListItemProps from '../../../../../Types/Interface/Props/ListItemProps';
import { WasteManagementInterface } from '../../../../../Types/Interface/TableInterfaces/DataInterface/WasteManagementInterface';
import SharedDataInterface from '../../../../../Types/Interface/TableInterfaces/SharedDataInterface/SharedDataInterface';
import AdminScreenPathsList from '../../../../../Datas/BeecomingDatas/AdminScreenPathsList';
import AllDays from '../../../../../Datas/BeecomingDatas/AllDays';

const ListItemWasteCollect: FunctionComponent<
  ListItemProps<WasteManagementInterface, SharedDataInterface>
> = ({ row, selectedItemsManager }) => {
  const [selected, setSelected] = useState<boolean>(selectedItemsManager.isSelected(row));
  const ccloClasses = UseBeecomingStyles();
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const change = event.target.checked;
    setSelected(change);
    if (change) {
      selectedItemsManager.addItem(row);
    } else {
      selectedItemsManager.subItem(row);
    }
  };

  return (
    <TableRow className={selected ? 'selectedRow' : 'normalRow'}>
      <TableCell>
        <Checkbox color="primary" checked={selected} onChange={handleChange} size="small" />
        {row.name}
      </TableCell>
      <TableCell className="textWithIcon">
        <ListItem>
          <ListItemIcon>
            <FiberManualRecord
              className={
                row.type.toLowerCase() === 'om'
                  ? ccloClasses.waste_style
                  : ccloClasses.packaging_style
              }
              fontSize="small"
            />
          </ListItemIcon>
          <ListItemText>{row.type}</ListItemText>
        </ListItem>
      </TableCell>
      <TableCell>
        {`${AllDays[row.day - 1].name} (${
          // eslint-disable-next-line no-nested-ternary
          row.recurrence === 0
            ? 'toutes les semaines'
            : row.recurrence === 1
            ? 'semaines impaires'
            : 'semaines paires'
        })`}
      </TableCell>
      <TableCell>
        <IconButton
          color="primary"
          onClick={() => {
            history.push(`${AdminScreenPathsList['waste-collect-edition']}?id=${row.code}`);
          }}
        >
          <Visibility />
        </IconButton>
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

export default ListItemWasteCollect;
