import GetAdditionalQueryInterface from '../../../Types/Interface/MethodInterface/GetAdditionalQueryInteface';
import ManagingRightAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/ManagingRightAdditionalArgs';
import { ManagingRightInterface } from '../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import StringContains from '../../StringContains';

const GetManagingRightAdditionalQuery: GetAdditionalQueryInterface<ManagingRightAdditionalArgs> = (
  _additionalQuery: ManagingRightAdditionalArgs,
) => null;

// some additional args are not well handle by the clloApi, so their treated by the front
const parseManagingRightList = (
  dataBlock: ManagingRightInterface[],
  args: ManagingRightAdditionalArgs,
): ManagingRightInterface[] => dataBlock.filter((row) => StringContains(row.title, args.title));

export default GetManagingRightAdditionalQuery;
export { parseManagingRightList };
