import { ListItem, ListItemText } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import AdminScreenPathsList from '../../../../Datas/BeecomingDatas/AdminScreenPathsList';
import UseBeecomingStyles from '../../../../Themes/UseBeecomingStyles';
import { NavigationSubItem } from '../../../../Types/BeecomingTypes/NavigationItem';

const NavigationSubItemComponent: FunctionComponent<NavigationSubItem> = ({ label, to }) => {
  const history = useHistory();

  const path = AdminScreenPathsList[to];

  const beecomingClasses = UseBeecomingStyles();

  return (
    <ListItem
      button
      component="a"
      onClick={() => {
        history.push(path);
      }}
      className={beecomingClasses.navigationSubItem}
    >
      <ListItemText primary={label} />
    </ListItem>
  );
};

export default NavigationSubItemComponent;
