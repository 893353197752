import UserData from '../../Datas/UserData';

const urlRequest = process.env.REACT_APP_URL_REQUEST;

function ApiRequest(path: string, method: string, token: string, body?: any): Promise<Response> {
  if (body) {
    return fetch(`${urlRequest}${path}`, {
      method,
      credentials: 'include',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json-patch+json',
      },
      body: JSON.stringify(body),
    });
  }
  return fetch(`${urlRequest}${path}`, {
    method,
    credentials: 'include',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}

/**
 * Make a request to default Api
 * @param path Request path
 * @param method Http Method (either GET, POST, PUT, DELETE, ...)
 * @param history history (react-router-dom)
 * @param body Request Body (JSON.stringify will be apply on this row object)
 * @returns Promise of the Api Response
 */
function ApiFetch(path: string, method: string, history: any, body?: any): Promise<Response> {
  const token: string | null = UserData.getUserProperty('token');
  if (token === null) {
    const resp: Response = new Response(null, { status: 401 });
    // history.push(OtherScreenPathsList['admin-connection']);
    return new Promise<Response>(() => resp);
  }
  return ApiRequest(path, method, token, body);
}

function ApiFetchUnLog(path: string, method: string, body?: any): Promise<Response> {
  if (body) {
    return fetch(`${urlRequest}${path}`, {
      method,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
      body: JSON.stringify(body),
    });
  }
  return fetch(`${urlRequest}${path}`, {
    method,
    credentials: 'include',
  });
}

export default ApiFetch;
export { ApiFetchUnLog };
