/* eslint-disable operator-linebreak */
import 'date-fns';
import { Button, TextField } from '@material-ui/core';

import React, { FunctionComponent, useEffect, useState } from 'react';

import '../Admin.scss';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert/Alert';
import NotificationInterface, {
  NotificationFormatter,
} from '../../../Types/Interface/TableInterfaces/DataInterface/NotificationInterface';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';

interface Props {}

/**
 * @param onError method: show a snackbar with the chosen message
 * @returns Notification Edit and Creation Page
 */

const AdminEditManagingRight: AdminPageComponent = ({ onError }) => {
  const history = useHistory();

  const [pageTitle, setPageTitle] = useState<string>("Ajout d'un compte");

  const [errorTitle, setErrorTitle] = useState<boolean>(false);
  const [errorName, setErrorName] = useState<boolean>(false);
  const [errorLastname, setErrorLastname] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [errorBody, setErrorBody] = useState<boolean>(false);
  const [errorAddress, setErrorAddress] = useState<boolean>(false);
  const [errorImg, setErrorImg] = useState<boolean>(false);

  const [errorAdditionalInfo, setErrorAdditionalInfo] = useState<boolean>(false);
  const [errorPrice, setErrorPrice] = useState<boolean>(false);
  const [errorAge, setErrorAge] = useState<boolean>(false);
  const [alert, setAlert] = useState<any>({
    type: 'null',
    status: 'null',
    msg: 'null',
  });
  const [alertRightManagement, setAlertRightManagement] = useState<string>('null');

  const [select, setSelect] = useState<any>({
    statistic: 'read',
    notification: 'read',
    actuality: 'read',
    event: 'read',
    waste: 'read',
    budget: 'read',
    report: 'read',
    feature: 'read',
  });
  const selectTab: any = [
    'statistic',
    'notification',
    'actuality',
    'event',
    'waste',
    'budget',
    'report',
    'feature',
  ];
  const selectTitle: any = [
    'Statistique',
    'Notification',
    'Actualité',
    'Agenda',
    'Gestion des déchets',
    'Budget participatif',
    'Signalement',
    'Fonctionnalité',
  ];

  const [id, setId] = useState<string | null>();
  const [title, setTitle] = useState<string | null>('');
  const [name, setName] = useState<string | null>('');
  const [lastname, setLastname] = useState<string | null>('');
  const [email, setEmail] = useState<string | null>('');
  const [password, setPassword] = useState<string | null>('');
  const [body, setBody] = useState<string | null>('');
  const [price, setPrice] = useState<string | null>('');
  const [age, setAge] = useState<string | null>('');
  const [additionalInfo, setAdditionalInfo] = useState<string | null>('');
  const [address, setAddress] = useState<string | null>('');
  const [imgUrl, setImgUrl] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedDateEnd, setSelectedDateEnd] = useState<Date | null>(new Date());
  const [interval, setInterval] = useState<string>('00:00:00');

  const handleDateChange = (date: Date | null, type: string) => {
    date?.setSeconds(0);
    date?.setMilliseconds(0);
    if (type === 'start') {
      setSelectedDate(date);
    } else {
      setSelectedDateEnd(date);
    }
  };

  // #region handle Validation
  /**
   * @returns either the fields are valids
   */
  const isValid = (): boolean => {
    let valid: boolean = true;
    if (title === '') {
      valid = false;
      setErrorTitle(true);
    }
    if (name === '') {
      valid = false;
      setErrorName(true);
    }
    if (lastname === '') {
      valid = false;
      setErrorLastname(true);
    }
    if (email === '') {
      valid = false;
      setErrorEmail(true);
    }
    if (password === '') {
      valid = false;
      setErrorPassword(true);
    }
    if (body === '') {
      valid = false;
      setErrorBody(true);
    }
    if (address === '') {
      valid = false;
      setErrorAddress(true);
    }
    if (imgUrl === '') {
      valid = false;
      setErrorImg(true);
    }
    if (additionalInfo === '') {
      valid = false;
      setErrorAdditionalInfo(true);
    }
    if (age === '') {
      valid = false;
      setErrorAge(true);
    }
    if (price === '') {
      valid = false;
      setErrorPrice(true);
    }
    if (selectedDate === null) {
      valid = false;
    }
    if (selectedDateEnd === null) {
      valid = false;
    }
    return valid;
  };

  const alertNull = () => {
    const newAlert = { ...alert };
    newAlert.type = 'null';
    newAlert.status = 'null';
    newAlert.msg = 'null';
    setAlert(newAlert);
  };

  const changeAlert = (type: any, status: any, msg: any) => {
    const newAlert = { ...alert };
    newAlert.type = type;
    newAlert.status = status;
    newAlert.msg = msg;
    setAlert(newAlert);
  };

  const handleValidationInfoAccount = () => {
    if (isValid()) {
      changeAlert('account', 'success', 'Compte ajouté !');
    } else {
      changeAlert('account', 'error', 'Erreur');
    }
  };

  const handleValidationManagementRights = () => {
    changeAlert('manageRight', 'success', 'La gestion des droits a été mis à jour !');
  };
  // #endregion

  /**
   * Ask API to delete a notification
   */
  const handleDelete = () => {};

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const notifId = params.get('id');
    setId(notifId);
    // if notifId is null then the user is creating a new notification
    if (notifId === null) {
      // look for url parameter to set notification parameter
      // (used for creating new notification)
      setTitle(params.get('titre') ?? '');
      setBody(params.get('corps') ?? '');
      setImgUrl(params.get('imgUrl') ?? '');
      setSelectedDate(new Date(params.get('date') ?? new Date()));
      setInterval(params.get('interval') ?? '00:00:00');
    } else {
      // ask api to set notification parameter
      // (used for editing existing notification)
      setPageTitle('Edition de notification');
      ApiFetch(`/Notification/notification?notificationId=${notifId}`, 'GET', history)
        .then((response) => response.json())
        .then((r) => NotificationFormatter(r))
        .then((resp: NotificationInterface) => {
          setTitle(resp.title);
          setBody(resp.body);
          setSelectedDate(resp.sendingTime);
          setInterval(resp.interval);
        });
    }
  }, []);

  return (
    <div>
      <h1>{pageTitle}</h1>
      {alert.msg !== 'null' && (
        <Alert onClose={alertNull} severity={alert.status}>
          {alert.msg}
        </Alert>
      )}
      <div className="newElementContainer">
        <div className="newElementColumn">
          <p className="textNewElementProperty">Informations du compte</p>
          <div className="newElementProperty">
            <TextField
              label="Nom"
              value={name}
              error={errorName}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
                setErrorName(false);
                setAlert('null');
              }}
            />
          </div>
          <div className="newElementProperty">
            <TextField
              label="Prénom"
              value={lastname}
              error={errorLastname}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setLastname(event.target.value);
                setErrorLastname(false);
                setAlert('null');
              }}
            />
          </div>
          <div className="newElementProperty">
            <TextField
              label="Email"
              value={email}
              error={errorEmail}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
                setErrorEmail(false);
                setAlert('null');
              }}
            />
          </div>
          <div className="newElementProperty">
            <TextField
              label="Mot de passe"
              value={password}
              error={errorPassword}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
                setErrorPassword(false);
                setAlert('null');
              }}
            />
          </div>

          <div className="newElementProperty">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleValidationInfoAccount}
            >
              Valider
            </Button>
          </div>
        </div>
        <div className="newElementColumn">
          <p className="textNewElementProperty">Gestion des droits</p>
          {selectTab.map((selectName: any, index: number) => (
            <div className="newElementProperty space-between">
              <label className="d-flex space-between" htmlFor={selectName}>
                {selectTitle[index]}
                <select
                  className="select"
                  name={selectName}
                  value={select.selectName}
                  onChange={(e) => {
                    const newSelect = { ...select };
                    newSelect[selectName] = e.target.value;
                    setSelect(newSelect);
                  }}
                >
                  <option value="no-access">Non accessible</option>
                  <option value="read-edit">Lecture + Édition</option>
                  <option selected value="read">
                    Lecture
                  </option>
                  <option value="edit">Édition</option>
                </select>
              </label>
            </div>
          ))}
          <div className="newElementProperty">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleValidationManagementRights}
            >
              Enregistrer
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdminEditManagingRightProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Gestion des droits"
    screenName="managing-right"
    menuPath="managing-right-add-account"
    adminPage={AdminEditManagingRight}
  />
);

export default AdminEditManagingRightProtected;
