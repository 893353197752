import { Button, TextField } from '@material-ui/core';
import 'date-fns';
import ColorPicker from 'material-ui-color-picker';
import React, { Dispatch, FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AdminScreenPathsList from '../../../Datas/BeecomingDatas/AdminScreenPathsList';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import '../Admin.scss';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';

interface Props {}

/**
 * @param onError method: show a snackbar with the chosen message
 * @returns Notification Edit and Creation Page
 */

const AdminEditBus: AdminPageComponent = () => {
  const history = useHistory();
  const [errorTitle, setErrorTitle] = useState<boolean>(false);
  const [errorColor, setErrorColor] = useState<boolean>(false);

  const defaultColor = '#000';
  const [title, setTitle] = useState<string | null>('');
  const [color, setColor] = useState<string | null>(defaultColor);
  const [id, setId] = useState<string | null>();

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const lineId = params.get('id');
    setId(lineId);
    // if parkingId is null then the user is creating a new parking

    getBusData(history, lineId, setTitle, setColor);
  }, []);

  // #region handle Validation
  /**
   * @returns either the fields are valids
   */
  const isValid = (): boolean => {
    let valid: boolean = true;
    if (title === '') {
      valid = false;
      setErrorTitle(true);
    }
    if (color === null) {
      valid = false;
      setErrorColor(true);
    }
    return valid;
  };

  const validate = async () => {
    if (!id) {
      //  Ajout d'une ligne de bus
      ApiFetch('/bus-line', 'POST', history, {
        name: title,
        color,
      }).then((result) => {
        if (result.ok) {
          toast.success('La nouvelle ligne à été ajoutée');
          history.push(AdminScreenPathsList['line-bus']);
        } else {
          toast.error('Echec de la création de la ligne');
        }
      });
    }
  };

  const handleValidation = (boolean: boolean) => {
    if (boolean) {
      // Validation
      if (isValid()) {
        validate();
      }
    } else {
      // Annulation
      clearInput();
      history.push(AdminScreenPathsList['line-bus']);
    }
  };
  // #endregion
  const clearInput = () => {
    setTitle('');
    setColor(defaultColor);
    setErrorTitle(false);
    setErrorColor(false);
  };

  return (
    <div>
      {/* <Button onClick={addColumn}>Ajouter une colonne</Button> */}
      <h1>Ajout d&apos;une ligne de bus</h1>

      <div className="newElementContainer">
        <div className="newElementColumn" style={{ maxWidth: '100%' }}>
          <div className="newElementProperty ">
            <TextField
              label="Nom de la ligne"
              value={title}
              error={errorTitle}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTitle(event.target.value);
                setErrorTitle(false);
              }}
            />
          </div>
          <div className="newElementProperty colorContainer">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p
                style={{
                  marginRight: '10px',
                  textAlign: 'left',
                  color: 'rgba(0, 0, 0, 0.54)',
                }}
              >
                Couleur de la ligne :
              </p>
              <ColorPicker
                name="color"
                defaultValue={defaultColor}
                value={color ?? defaultColor}
                onChange={(colorValue) => setColor(colorValue)}
                style={{
                  backgroundColor: color ?? defaultColor,
                  width: '15px',
                  height: '15px',
                  borderRadius: '2px',
                  content: '',
                  padding: 0,
                  margin: 0,
                  flexDirection: 'row',
                }}
              />
            </div>
          </div>

          <div className="newElementProperty btnContainer">
            <Button
              style={{ marginRight: '5px' }}
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleValidation(true)}
            >
              Valider
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => handleValidation(false)}
            >
              Annuler
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdminAddBusProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Retour à la liste"
    screenName="line-bus"
    menuPath="line-bus"
    adminPage={AdminEditBus}
  />
);

export default AdminAddBusProtected;
const getBusData = (
  history: any,
  lineId: string | null,
  setTitle: Dispatch<string>,
  setColor: Dispatch<string>,
) => {
  if (lineId) {
    ApiFetch(`/bus-line/${lineId}`, 'GET', history)
      .then((response) => response.json())
      .then((data: any) => {
        if (data) {
          setTitle(data.name);
          setColor(data.color);
        }
      });
  }
};
