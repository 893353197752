import DisplayLevel from '../../../BeecomingTypes/DisplayLevel';
import DataInterface from './DataInterface';

interface AgendaFromFetch {
  id: string;
  label: string;
  startDateTime: Date;
  pictureUrl: string;
  endDateTime: Date;
  level: DisplayLevel;
}

interface FieldsInterface {
  label: string;
  published: Date;
}

interface AgendaInterface extends DataInterface {
  label: string;
  level: DisplayLevel;
  pictureUrl: string;
  startDateTime: Date;
  endDateTime: Date;
}

function AgendaFormatter(data: AgendaFromFetch): AgendaInterface {
  return {
    id: data.id,
    label: data.label,
    level: data.level ?? -1,
    pictureUrl: data.pictureUrl,
    startDateTime: data.startDateTime,
    endDateTime: data.endDateTime,
  };
}

export default AgendaInterface;
export { AgendaFormatter };
