import React, { FunctionComponent } from 'react';
import ActualityAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/ActualityAdditionalArgs';
import { ActualityInterface } from '../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import ActualitySharedData from '../../../Types/Interface/TableInterfaces/SharedDataInterface/ActualitySharedData';
import GetActualityHighLight from '../../../Methods/TableQuery/GetSharedData/GetActualityHighLight';
import GetActualityComparatorFromKey from '../../../Methods/TableQuery/HandleFilter/HandleSort/GetActualityComparatorFromKey';
import AdminListContainer from '../BeecomingCompenents/AdminContainers/AdminListContainer';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import ListHeaderActuality from '../BeecomingCompenents/ListElements/ListHeaders/ListHeaderActuality';
import ListItemActuality from '../BeecomingCompenents/ListElements/ListItems/ListItemActuality';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import GetActualityAdditionalQuery, {
  parseActualityList,
} from '../../../Methods/TableQuery/GetAdditionalQueries/GetActualityAdditionalQuery';
import { ActualityFormatter } from '../../../Types/Interface/TableInterfaces/DataInterface/ActualityInterface';

interface Props {}

const getActualityList = (additionalParameters: ActualityAdditionalArgs, history: any) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  ApiFetch('/actualities', 'GET', history);

/* const query = GetActualityAdditionalQuery(additionalParameters);
  return ApiFetch(`/OpenData/record/actualites/admin?rows=-1${query ?? ''}`, 'GET', history); */

const ListActuality: AdminPageComponent = ({ onError }) => (
  <AdminListContainer<ActualityInterface, ActualityAdditionalArgs, ActualitySharedData>
    // addNew="Nouvelle actualité" //bouton d'ajout supprimé (19/11/21)
    defaultSharedData={{ highlight: '' }}
    defaultAdditionalArgs={{ title: '', body: '', start: null, end: null }}
    defaultSort="title"
    headComponent={ListHeaderActuality}
    listComponent={ListItemActuality}
    getList={{
      request: getActualityList,
      formatter: ActualityFormatter,
      filter: parseActualityList,
      errorMessage: "La liste des actualités n'a pas pu être chargée correctement",
    }}
    getDefaultSharedData={GetActualityHighLight}
    getComparatorFromKey={GetActualityComparatorFromKey}
    onError={onError}
  />
);

const ListActualityProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Actualités"
    menuPath="actuality-edition"
    screenName="actuality"
    adminPage={ListActuality}
  />
);

export default ListActualityProtected;
