/* eslint-disable operator-linebreak */
import {
  Button,
  Checkbox,
  CheckboxProps,
  CircularProgress,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { grey, yellow } from '@material-ui/core/colors';
import { Star, StarBorder } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UseBeecomingStyles from '../../../../../Themes/UseBeecomingStyles';
import AgendaInterface from '../../../../../Types/Interface/TableInterfaces/DataInterface/AgendaInterface';
import ListItemProps from '../../../../../Types/Interface/Props/ListItemProps';
import AgendaSharedData from '../../../../../Types/Interface/TableInterfaces/SharedDataInterface/AgendaSharedData';
import HandleCheckBoxAgenda from '../../../../../Methods/AgendaHandler/HandleCheckBoxAgenda';
import HandleCheckBoxMainAgenda from '../../../../../Methods/AgendaHandler/HandleCheckBoxMainAgenda';
import DisplayLevel from '../../../../../Types/BeecomingTypes/DisplayLevel';
import ApiFetch from '../../../../../Methods/RefreshToken/ApiRequest';

const ListItemAgenda: FunctionComponent<ListItemProps<AgendaInterface, AgendaSharedData>> = ({
  row,
  sharedData,
  setSharedData,
  setRow,
  onError,
}) => {
  const history = useHistory();
  const [childRow, setChildRow] = useState<AgendaInterface>(row);
  const [shown, setShown] = useState<boolean>(row.level >= DisplayLevel.Listed);
  const [inCarousel, setInCarousel] = useState<boolean>(row.level >= DisplayLevel.Carousel);

  // loaders
  const [shownLoader, setShownLoader] = useState<boolean>(false);
  const [carousselLoader, setCarousselLoader] = useState<boolean>(false);
  const [hightlightLoader, setHightlightLoader] = useState<boolean>(false);

  // eslint-disable-next-line prefer-destructuring
  const highlight = sharedData.highlight;
  const highlightSetter = (h: string) => {
    setSharedData({ highlight: h });
  };
  const setDisplayLevel = (l: DisplayLevel): void => {
    const newRow: AgendaInterface = childRow;
    newRow.level = l;
    // need to update data in parent and child
    setRow(newRow);
    setChildRow(newRow);
    setShown(newRow.level >= DisplayLevel.Listed);
    setInCarousel(newRow.level >= DisplayLevel.Carousel);
  };

  const handleNotify = () => {
    const now = new Date(Date.now());
    now.setSeconds(now.getSeconds() + 10);
    const startDate = new Date(row.startDateTime);
    const maxForNotif = new Date(Date.now());
    maxForNotif.setDate(maxForNotif.getDate() + 30);
    console.log(startDate);
    console.log(maxForNotif);
    if (startDate > maxForNotif) {
      onError("L'évènement aura lieu dans plus de 30 jours, annulation de l'envoi...");
      return null;
    }
    const requestData = {
      notification: {
        title: row.label as string,
        body: "Un nouvel évenement est disponible dans l'onglet activités et spectacles",
        PictureUrl: row.pictureUrl as string,
        Topics: { Category: 'agenda' },
        Datas: {
          DatasetId: 'agenda',
          RecordId: row.id as string,
        },
      },
      sendingTime: now as Date,
    };
    ApiFetch('/notifications', 'POST', history, requestData).then((response) => {
      if (response.ok) {
        onError('La demande de notification a bien été prise en compte, envoi dans 10 secondes');
      } else {
        onError("La notification n'a pas été envoyée");
      }
    });
    return null;
  };
  const YellowCheckBox = withStyles({
    root: {
      color: grey[500],
      '&$checked': {
        color: yellow[600],
      },
    },
    checked: {},
    // eslint-disable-next-line react/jsx-props-no-spreading
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);
  const classes = UseBeecomingStyles();

  const handleError = () => {
    onError("Une erreur s'est produite");
  };

  return (
    <TableRow>
      <TableCell>
        {hightlightLoader && <CircularProgress className={classes.loader} />}
        {/* <YellowCheckBox
          color="default"
          checked={sharedData.highlight === row.id}
          icon={<StarBorder />}
          checkedIcon={<Star />}
          onChange={async (event) => {
            HandleCheckBoxMainAgenda(
              event,
              row.id,
              setDisplayLevel,
              highlightSetter,
              setHightlightLoader,
              handleError,
              history,
            );
          }}
        /> */}
        {row.label}
      </TableCell>
      {/* <TableCell align="center">
        {shownLoader && <CircularProgress className={classes.loader} />}
        <Checkbox
          checked={shown}
          onChange={async (event) => {
            HandleCheckBoxAgenda(
              DisplayLevel.Listed,
              event,
              row.id,
              highlight,
              setDisplayLevel,
              highlightSetter,
              setShownLoader,
              handleError,
              history,
            );
          }}
        />
        </TableCell> */}
      <TableCell align="center">
        {carousselLoader && <CircularProgress className={classes.loader} />}
        {new Date(row.startDateTime).toLocaleDateString()}
      </TableCell>
      <TableCell align="center">
        {carousselLoader && <CircularProgress className={classes.loader} />}
        <Button variant="outlined" onClick={handleNotify}>
          Envoyer une notification
        </Button>
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

export default ListItemAgenda;
