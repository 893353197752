import NavigationItem from '../../Types/BeecomingTypes/NavigationItem';

// todo: Décommenter les section voulues quand les pages seront prêtes à être faites
const NavigationList: NavigationItem[] = [
  // {
  //   label: 'Statistiques',
  //   imgSrc: 'statistics',
  //   node: 'statistics',
  // },

  {
    label: 'Administration',
    imgSrc: 'administration',
    node: [
      {
        label: 'Notifications',
        to: 'notification',
      },
      {
        label: 'Promouvoir un utilisateur',
        to: 'admin-promote',
      },
    ],
  },
  {
    label: 'Actualités',
    imgSrc: 'actuality',
    node: 'actuality',
  },
  {
    label: 'Évènements',
    imgSrc: 'agenda',
    node: 'agenda',
  },
  {
    label: 'Gestion des Bus',
    imgSrc: 'bus',
    node: [
      {
        label: 'Arrêts de bus',
        to: 'arret-bus',
      },
      {
        label: 'Lignes de bus',
        to: 'line-bus',
      },
    ],
  },
  {
    label: 'Gestion des déchets',
    imgSrc: 'trash',
    node: 'waste-collect',
  },
  {
    label: 'Démarches en ligne',
    imgSrc: 'ask',
    node: 'demarche',
  },
  {
    label: 'Parkings',
    node: 'parking',
    imgSrc: 'parking',
  },
  {
    label: 'Signalements',
    imgSrc: 'signalements',
    node: 'signalement',
  },
  // {
  //   label: 'Fonctionnalités',
  //   imgSrc: 'fonctionalities',
  //   node: 'features',
  // },
  // {
  //   label: 'Gestion des droits',
  //   imgSrc: 'managing-right',
  //   node: 'managing-right',
  // },
];

export default NavigationList;
