import { TableCell, TableHead, TableRow, Button } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import AgendaAdditionalArgs from '../../../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/AgendaAdditionalArgs';
import { AgendaInterface } from '../../../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import ListHeaderProps from '../../../../../Types/Interface/Props/ListHeaderProps';
import GetHandleFilter from '../../../../../Methods/TableQuery/HandleFilter/GetHandleFilter';
import SearchBox from '../../SearchBox';
import ListHeaderOrder from './ListHeaderOrder';
import { HeadCell } from '../../../../../Types/BeecomingTypes/BeecomingTypes';
import ApiFetch from '../../../../../Methods/RefreshToken/ApiRequest';

// eslint-disable-next-line operator-linebreak
const ListHeaderAgenda: FunctionComponent<ListHeaderProps<AgendaInterface, AgendaAdditionalArgs>> =
  ({
    additionalArgs,
    onChangeAdditionalArgs,
    orderType,
    onChangeOrderType,
    orderBy,
    onChangeOrderBy,
  }) => {
    const [timer, setTimer] = useState(setTimeout(() => {}, 0));

    const headCells: HeadCell<AgendaInterface>[] = [
      { id: 0, keyValue: 'label', name: 'Évènement', align: 'left' },
      { id: 1, keyValue: 'startDateTime', name: 'Date de début', align: 'center' },
      { id: 2, keyValue: 'level', name: 'Notifier', align: 'center' },
    ];

    const handleRefresh = (history: any) => {
      ApiFetch('/wp/events/save/100', 'GET', history);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <SearchBox
              label="Trouver un événement"
              handleSearch={GetHandleFilter<AgendaAdditionalArgs>(
                'title',
                additionalArgs,
                onChangeAdditionalArgs,
                timer,
                setTimer,
                500,
              )}
            />
          </TableCell>
          <TableCell />
          <TableCell>
            <Button variant="outlined" onClick={handleRefresh}>
              Actualiser
            </Button>
          </TableCell>
          <TableCell />
        </TableRow>
        <ListHeaderOrder
          headCells={headCells}
          orderType={orderType}
          onChangeOrderType={onChangeOrderType}
          orderBy={orderBy}
          onChangeOrderBy={onChangeOrderBy}
        />
      </TableHead>
    );
  };

export default ListHeaderAgenda;
