/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  ThemeProvider,
} from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import TableItemTheme from '../../../../Themes/TableItemTheme';
import DataInterface from '../../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import AdditionalArgsInterface from '../../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/AdditionalArgsInterface';
import SharedDataInterface from '../../../../Types/Interface/TableInterfaces/SharedDataInterface/SharedDataInterface';
import AdminListContainerProps from '../../../../Types/Interface/Props/AdminContainerProps';
import SelectionManagerInterface from '../../../../Types/Interface/TableInterfaces/SelectionManagerInterface';
import GetComparatorFromKeyInterface from '../../../../Types/Interface/MethodInterface/GetComparatorFromKeyInterface';
import { Order } from '../../../../Types/BeecomingTypes/BeecomingTypes';
import BeecomingDatas from '../../../../Datas/BeecomingDatas/BeecomingDatas';
import { importCSVHandle } from '../../../../Methods/WasteHandler/importCSV';
import ModalImportCSV from '../../OtherPages/ModalImportCSV';

const AdminListContainer = <
  RowType extends DataInterface,
  AdditionalArgs extends AdditionalArgsInterface,
  SharedData extends SharedDataInterface,
>({
  add,
  addNew,
  addNew2,
  defaultAdditionalArgs,
  defaultSharedData,
  defaultSort,
  headComponent,
  listComponent,
  linkOnSelected,
  importCSV,
  getList,
  getDefaultSharedData = () => new Promise(() => null),
  getComparatorFromKey,
  onError,
}: AdminListContainerProps<RowType, AdditionalArgs, SharedData>): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const [dataBlock, setDataBlock] = useState<RowType[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  // #region Table Header
  // #region states
  const [additionalArgs, setAdditionalArgs] = useState<AdditionalArgs>(defaultAdditionalArgs);
  const [orderType, setOrderType] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof RowType>(defaultSort);
  // #endregion
  // #region Get final form response from Api response
  const setData = (
    args: AdditionalArgs,
    checkSubscription: () => boolean = () => true,
  ): Promise<boolean> =>
    getList.request(args, history).then((response) => {
      if (checkSubscription()) {
        if (response.ok) {
          return response
            .json()
            .then((rowResponse) => {
              if (getList.fromRecords) {
                return rowResponse.records;
              }
              return rowResponse;
            })
            .then((result) => result?.map((item: any) => getList.formatter(item)))
            .then((data) => {
              if (getList?.filter !== undefined) {
                const demo = getList?.filter(data, args);
                return demo;
              }
              return data;
            })
            .then((rows: RowType[]) => {
              if (checkSubscription()) {
                setDataBlock(rows);
                setLoader(false);
                return rows?.length > 0;
              }
              return false;
            });
        }
        onError(getList.errorMessage);
        return false;
      }
      return false;
    });
  // #endregion

  // #region Table Header functions
  const onChangeAdditionalArgs = (args: AdditionalArgs): Promise<boolean> => {
    setAdditionalArgs(args);
    return setData(args);
  };

  const onChangeOrderType = (ord: Order) => {
    setOrderType(ord);
  };

  const onChangeOrderBy = (k: keyof RowType) => {
    setOrderBy(k);
  };
  // #endregion
  // #endregion

  // #region Table Footstep
  const [page, setPage] = useState<number>(0);
  // const [totalRows, setTotalRows] = useState<number>(dataBlock.length);
  const [rowsPerPage, setRowsPerPage] = useState<number>(BeecomingDatas.globalDefaultRowsPerPage);
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  // #endregion

  // #region Handle Selection
  const [numberSelected, setNumberSelected] = useState<number>(0);
  const [selectedList, setSelectedList] = useState<RowType[]>([]);

  const addSelectedItem = (row: RowType) => {
    setNumberSelected(numberSelected + 1);
    setSelectedList(selectedList.concat(row));
  };

  const subSelectedItem = (row: RowType) => {
    setNumberSelected(numberSelected - 1);
    const index = selectedList.indexOf(row);
    const newArray = selectedList;
    newArray.splice(index, 1);
    setSelectedList(newArray);
  };

  const isSelectedItem = (row: RowType): boolean => {
    const ro = selectedList.find((r: RowType) => r.id === row.id);
    return ro !== undefined;
  };

  const selectedItemsManager: SelectionManagerInterface<RowType> = {
    addItem: addSelectedItem,
    subItem: subSelectedItem,
    isSelected: isSelectedItem,
  };
  // #endregion

  // #region Table Data
  const [sharedData, setSharedData] = useState<SharedData>(defaultSharedData);

  useEffect(() => {
    let isSubscribed = true;
    getDefaultSharedData(history).then((value) => {
      if (isSubscribed) {
        setSharedData(value);
      }
    });
    return () => {
      isSubscribed = false;
    };
  }, [getDefaultSharedData]);

  const setRow = (row: RowType) => {
    const newDataBlock = dataBlock;
    const index = newDataBlock.findIndex((r) => r.id === row.id);
    if (index !== -1) {
      newDataBlock.splice(index, 1, row);
    }
    setDataBlock(newDataBlock);
  };

  const getGetTableWithFilter =
    (comparator: GetComparatorFromKeyInterface<RowType>) => (): RowType[] => {
      if (comparator !== undefined && orderBy !== undefined) {
        return dataBlock?.sort(comparator(orderBy, orderType));
      }
      return dataBlock;
    };

  const getTableData = (comparator: GetComparatorFromKeyInterface<RowType>): RowType[] =>
    getGetTableWithFilter(comparator)()?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );

  useEffect(() => {
    setDataBlock([]);
    let isSubscribed = true;
    setLoader(true);
    const checkSubscription = () => isSubscribed;

    // setCount(setTotalRows, additionalArgs);
    setData(additionalArgs, checkSubscription);
    return () => {
      isSubscribed = false;
    };
  }, [additionalArgs]);

  // #endregion

  return (
    <div>
      {modalIsOpen && <ModalImportCSV setModalIsOpen={setModalIsOpen} />}
      <div className="adminSecondToolBar" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {linkOnSelected !== undefined ? (
          <Button
            disabled={numberSelected <= 0}
            variant="contained"
            onClick={() => history.push(linkOnSelected.getPath(selectedList))}
            color="primary"
            style={{ marginRight: '20pt', marginLeft: '20pt' }}
          >
            {linkOnSelected.title}
          </Button>
        ) : null}

        {importCSV !== undefined ? (
          <Button
            variant="contained"
            onClick={() => importCSVHandle(modalIsOpen, setModalIsOpen)}
            color="primary"
            style={{ marginRight: '20pt', marginLeft: '20pt' }}
          >
            {importCSV}
          </Button>
        ) : null}

        {add !== undefined ? (
          <Button
            onClick={() => {
              history.push(`${location.pathname}/ajout`);
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            {add}
          </Button>
        ) : null}
        {addNew !== undefined ? (
          <Button
            onClick={() => {
              history.push(`${location.pathname}/edition`);
            }}
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: '20pt' }}
          >
            {addNew}
          </Button>
        ) : null}
        {addNew2 !== undefined ? (
          <Button
            onClick={() => {
              history.push(`${location.pathname}/proposition`);
            }}
            variant="contained"
            color="primary"
            size="small"
          >
            {addNew2}
          </Button>
        ) : null}
      </div>
      <ThemeProvider theme={TableItemTheme}>
        <TableContainer>
          <Table>
            {headComponent({
              additionalArgs,
              onChangeAdditionalArgs,
              orderType,
              onChangeOrderType,
              orderBy,
              onChangeOrderBy,
            })}
            <TableBody>
              {getTableData(getComparatorFromKey)?.map((row: RowType) => {
                const MyItem = (): ReactElement | null =>
                  listComponent({
                    row,
                    sharedData,
                    setSharedData,
                    selectedItemsManager,
                    setRow,
                    onError,
                  });
                return <MyItem key={row.id} />;
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={getGetTableWithFilter(getComparatorFromKey)()?.length}
                  onPageChange={handleChangePage}
                  page={page}
                  labelRowsPerPage="Lignes par page"
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={BeecomingDatas.globalrowsPerPageOptions}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        {loader && <CircularProgress style={{ position: 'absolute' }} />}
      </ThemeProvider>
    </div>
  );
};

export default AdminListContainer;
