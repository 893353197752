/* eslint-disable implicit-arrow-linebreak */
import { WeekItem } from '../../Types/BeecomingTypes/BeecomingTypes';

const AllWeeks: WeekItem[] = [
  { id: 0, description: 'Toutes les semaines' },
  { id: 1, description: 'Semaines impaires' },
  { id: 2, description: 'Semaines paires' },
];

const getWeeksFromNames = (names: string[]): number[] =>
  names.map((name) => AllWeeks.findIndex((x) => x.description === name));
const getWeeksFromIds = (ids: number[]): string[] => ids.map((day) => AllWeeks[day].description);

export default AllWeeks;
export { getWeeksFromIds, getWeeksFromNames };
