import React from 'react';
import DisplayLevel from '../../Types/BeecomingTypes/DisplayLevel';
import ApiFetch from '../RefreshToken/ApiRequest';

async function HandleCheckBoxFeatures(
  checkBoxDisplayLevel: DisplayLevel,
  event: React.ChangeEvent<HTMLInputElement>,
  recordid: string,
  highlight: string,
  setDisplay: (_l: DisplayLevel) => void,
  setHighlight: (_h: string) => void,
  setLoader: React.Dispatch<React.SetStateAction<boolean>>,
  handleError: () => void,
  history: any,
) {
  const checking: boolean = event.target.checked;
  const newDisplayLevel = checking ? checkBoxDisplayLevel : checkBoxDisplayLevel - 1;
  const requestData = {
    recordid,
    dataset: 'actualites_cclo',
    displayLevel: newDisplayLevel,
  };
  setLoader(true);
  ApiFetch('/OpenData/record', 'PUT', history, requestData).then((response) => {
    if (response.ok) {
      setDisplay(newDisplayLevel);
      if (!checking && highlight === recordid) {
        response.text().then((text) => setHighlight(text));
      }
    } else {
      handleError();
    }
    setLoader(false);
  });
}

export default HandleCheckBoxFeatures;
