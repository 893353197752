import React, { FunctionComponent } from 'react';

interface Props {}

const EmailConfirm: FunctionComponent<Props> = () => (
  <div>
    <h1>Votre adresse Email a été confirmé.</h1>
    <p>Vous pouvez fermer cette page.</p>
  </div>
);

export default EmailConfirm;
