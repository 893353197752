import { Button, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import ActualityAdditionalArgs from '../../../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/ActualityAdditionalArgs';
import { ActualityInterface } from '../../../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import ListHeaderProps from '../../../../../Types/Interface/Props/ListHeaderProps';
import GetHandleFilter from '../../../../../Methods/TableQuery/HandleFilter/GetHandleFilter';
import SearchBox from '../../SearchBox';
import ListHeaderOrder from './ListHeaderOrder';
import { HeadCell } from '../../../../../Types/BeecomingTypes/BeecomingTypes';
import ApiFetch from '../../../../../Methods/RefreshToken/ApiRequest';

const ListHeaderActuality: FunctionComponent<
  ListHeaderProps<ActualityInterface, ActualityAdditionalArgs>
> = ({
  additionalArgs,
  onChangeAdditionalArgs,
  orderType,
  onChangeOrderType,
  orderBy,
  onChangeOrderBy,
}) => {
  const [timer, setTimer] = useState(setTimeout(() => {}, 0));

  const headCells: HeadCell<ActualityInterface>[] = [
    { id: 0, keyValue: 'title', name: 'Actualités', align: 'left' },
    { id: 1, keyValue: 'published', name: 'Date de publication', align: 'center' },
    // { id: 2, keyValue: 'level', name: "Afficher sur l'application", align: 'center' },
    { id: 3, keyValue: 'level', name: 'Notifier', align: 'center' },
  ];

  const handleRefresh = (history: any) => {
    ApiFetch('/wp/actualities/save/100', 'GET', history);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <SearchBox
            label="Trouver une actualité"
            handleSearch={GetHandleFilter<ActualityAdditionalArgs>(
              'title',
              additionalArgs,
              onChangeAdditionalArgs,
              timer,
              setTimer,
              500,
            )}
          />
        </TableCell>
        <TableCell />
        <TableCell>
          <Button variant="outlined" onClick={handleRefresh}>
            Actualiser
          </Button>
        </TableCell>
        <TableCell />
      </TableRow>
      <ListHeaderOrder
        headCells={headCells}
        orderType={orderType}
        onChangeOrderType={onChangeOrderType}
        orderBy={orderBy}
        onChangeOrderBy={onChangeOrderBy}
      />
    </TableHead>
  );
};

export default ListHeaderActuality;
