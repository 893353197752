/* eslint-disable operator-linebreak */
import {
  Button,
  Checkbox,
  CheckboxProps,
  CircularProgress,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { grey, yellow } from '@material-ui/core/colors';
import { Star, StarBorder } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UseBeecomingStyles from '../../../../../Themes/UseBeecomingStyles';
import ActualityInterface from '../../../../../Types/Interface/TableInterfaces/DataInterface/ActualityInterface';
import ListItemProps from '../../../../../Types/Interface/Props/ListItemProps';
import ActualitySharedData from '../../../../../Types/Interface/TableInterfaces/SharedDataInterface/ActualitySharedData';
import HandleCheckBoxActuality from '../../../../../Methods/ActualityHandler/HandleCheckBoxActuality';
import HandleCheckBoxMainActuality from '../../../../../Methods/ActualityHandler/HandleCheckBoxMainActuality';
import DisplayLevel from '../../../../../Types/BeecomingTypes/DisplayLevel';
import ApiFetch from '../../../../../Methods/RefreshToken/ApiRequest';

const ListItemActuality: FunctionComponent<ListItemProps<ActualityInterface, ActualitySharedData>> =
  ({ row, sharedData, setSharedData, setRow, onError }) => {
    const history = useHistory();
    const [childRow, setChildRow] = useState<ActualityInterface>(row);
    const [shown, setShown] = useState<boolean>(row.level >= DisplayLevel.Listed);
    const [inCarousel, setInCarousel] = useState<boolean>(row.level >= DisplayLevel.Carousel);

    // eslint-disable-next-line prefer-destructuring
    const highlight = sharedData.highlight;
    const highlightSetter = (h: string) => {
      setSharedData({ highlight: h });
    };

    // loaders
    const [shownLoader, setShownLoader] = useState<boolean>(false);
    const [carousselLoader, setCarousselLoader] = useState<boolean>(false);
    const [hightlightLoader, setHightlightLoader] = useState<boolean>(false);

    const setDisplayLevel = (l: DisplayLevel): void => {
      const newRow: ActualityInterface = childRow;
      newRow.level = l;
      // need to update data in parent and child
      setRow(newRow);
      setChildRow(newRow);
      setShown(newRow.level >= DisplayLevel.Listed);
      setInCarousel(newRow.level >= DisplayLevel.Carousel);
    };

    const handleNotify = () => {
      const now = new Date(Date.now());
      now.setSeconds(now.getSeconds() + 10);
      const requestData = {
        notification: {
          title: row.title as string,
          body: 'Une nouvelle actualité est disponible',
          PictureUrl: row.img_url as string,
          Topics: { Category: 'actuality' },
          Datas: {
            DatasetId: 'actuality',
            RecordId: row.id as string,
          },
        },
        sendingTime: now as Date,
      };
      ApiFetch('/notifications', 'POST', history, requestData).then((response) => {
        if (response.ok) {
          onError('La demande de notification a bien été prise en compte, envoi dans 10 secondes');
        } else {
          onError("La notification n'a pas été envoyée");
        }
      });
    };

    const classes = UseBeecomingStyles();

    /* const YellowCheckBox = withStyles({
      root: {
        color: grey[500],
        '&$checked': {
          color: yellow[600],
        },
      },
      checked: {},
      // eslint-disable-next-line react/jsx-props-no-spreading
    })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

    const handleError = () => {
      onError("Une erreur s'est produite");
    }; */

    return (
      <TableRow>
        <TableCell>
          {/* {hightlightLoader && <CircularProgress className={classes.loader} />}
          <YellowCheckBox
            color="default"
            checked={sharedData.highlight === row.id}
            icon={<StarBorder />}
            checkedIcon={<Star />}
            onChange={async (event) => {
              HandleCheckBoxMainActuality(
                event,
                row.id,
                setDisplayLevel,
                highlightSetter,
                setHightlightLoader,
                handleError,
                history,
              );
            }}
          /> */}
          {row.title}
        </TableCell>
        {/* <TableCell align="center">{new Date(row.published).toLocaleDateString()}</TableCell> */}
        <TableCell align="center">
          {/* {shownLoader && <CircularProgress className={classes.loader} />}
          <Checkbox
            checked={shown}
            onChange={async (event) => {
              HandleCheckBoxActuality(
                DisplayLevel.Listed,
                event,
                row.id,
                highlight,
                setDisplayLevel,
                highlightSetter,
                setShownLoader,
                handleError,
                history,
              );
            }}
          /> */}
          {new Date(row.published).toLocaleDateString()}
        </TableCell>
        <TableCell align="center">
          {carousselLoader && <CircularProgress className={classes.loader} />}
          <Button variant="outlined" onClick={handleNotify}>
            Envoyer une notification
          </Button>
        </TableCell>
        <TableCell />
      </TableRow>
    );
  };

export default ListItemActuality;
