/* eslint-disable operator-linebreak */
import { Button, TableCell, TableRow } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import ManagingRightInterface from '../../../../../Types/Interface/TableInterfaces/DataInterface/ManagingRightInterface';
import ListItemProps from '../../../../../Types/Interface/Props/ListItemProps';
import ManagingRightSharedData from '../../../../../Types/Interface/TableInterfaces/SharedDataInterface/ManagingRightSharedData';

const ListItemManagingRight: FunctionComponent<
  ListItemProps<ManagingRightInterface, ManagingRightSharedData>
> = ({ row }) => (
  <TableRow>
    <TableCell>{row.title}</TableCell>
    {/* <TableCell align="center">{new Date(row.published).toLocaleDateString()}</TableCell> */}
    <TableCell align="center">{row.title}</TableCell>
    <TableCell align="center">{row.title}</TableCell>
    <TableCell align="center">
      <Button
        onClick={() => {
          console.log('click sur répondre');
        }}
        color="secondary"
        style={{ fontSize: 12 }}
      >
        Éditer
      </Button>
    </TableCell>
  </TableRow>
);

export default ListItemManagingRight;
