/* eslint-disable operator-linebreak */
import { Button, TableCell, TableRow } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import AdminScreenPathsList from '../../../../../Datas/BeecomingDatas/AdminScreenPathsList';
import ListItemProps from '../../../../../Types/Interface/Props/ListItemProps';
import ParkingInterface from '../../../../../Types/Interface/TableInterfaces/DataInterface/ParkingInterface';
import ParkingSharedData from '../../../../../Types/Interface/TableInterfaces/SharedDataInterface/ParkingSharedData';

const ListItemParking: FunctionComponent<ListItemProps<ParkingInterface, ParkingSharedData>> = ({
  row,
}) => {
  const history = useHistory();

  return (
    <TableRow>
      <TableCell>{row.name}</TableCell>
      <TableCell align="center">{`${row.coordinate[0]}, ${row.coordinate[1]}`}</TableCell>
      <TableCell align="center">{row.maxSpaces}</TableCell>
      <TableCell align="center">
        <Button
          onClick={() => {
            history.push(`${AdminScreenPathsList['parking-edition']}?id=${row.id}`);
          }}
          color="secondary"
        >
          Éditer
        </Button>
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

export default ListItemParking;
