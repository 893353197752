import { Button, TextField, ThemeProvider } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ProjectTheme from '../../../Themes/ProjectTheme';
import { ApiFetchUnLog } from '../../../Methods/RefreshToken/ApiRequest';

interface Props {}

const localPadding = '8pt';

const PasswordReset: FunctionComponent<Props> = () => {
  const history = useHistory();
  const [userId, setUserId] = useState<string | null>(null);
  const [resetToken, setResetToken] = useState<string | null>(null);
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');

  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    setUserId(params.get('userId'));
    setResetToken(params.get('resetToken'));
  }, []);

  const handleValidate = () => {
    if (password === '' || password !== passwordConfirm) {
      setError(true);
    } else {
      const confirmationToken = resetToken || '';
      const requestBody = {
        userId,
        confirmationToken: encodeURIComponent(confirmationToken),
        password,
        passwordConfirmation: passwordConfirm,
      };
      ApiFetchUnLog('/compte/mot-de-passe/nouveau', 'PUT', requestBody).then((response) => {
        if (response.ok) {
          response.text().then((text) => history.push(text));
        } else {
          setError(true);
        }
      });
    }
  };

  return (
    <ThemeProvider theme={ProjectTheme}>
      <div>
        <h1>Veuillez selectionner un nouveau mot de passe.</h1>
        <div style={{ padding: localPadding }}>
          <TextField
            value={password}
            label="Nouveau mot de passe"
            variant="outlined"
            size="small"
            type="password"
            error={error}
            onChange={(event) => {
              setPassword(event.target.value);
              setError(false);
            }}
          />
        </div>
        <div style={{ padding: localPadding }}>
          <TextField
            value={passwordConfirm}
            label="Confirmation"
            variant="outlined"
            size="small"
            type="password"
            error={error}
            onChange={(event) => {
              setPasswordConfirm(event.target.value);
              setError(false);
            }}
          />
        </div>
        <div style={{ padding: localPadding }}>
          <Button onClick={handleValidate} variant="contained" color="primary" size="small">
            Confirmer
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default PasswordReset;
