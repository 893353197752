import React, { FunctionComponent } from 'react';

interface Props {}

const PasswordChange: FunctionComponent<Props> = () => (
  <div>
    <h1>Votre mot de passe a bien été modifié</h1>
    <p>Vous pouvez fermer cette page</p>
  </div>
);

export default PasswordChange;
