import LinkOnSelectedInterface from '../../Types/Interface/MethodInterface/LinkOnSelectedInterface';
import { WasteManagementInterface } from '../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import AdminScreenPathsList from '../BeecomingDatas/AdminScreenPathsList';

/**
 * @param data Garbage collet array
 * @returns a link usable to create exception for each Garbage collect selected
 */
function getPath(data: WasteManagementInterface[]): string {
  let link: string = AdminScreenPathsList['waste-collect-exception'];
  const l = data.length;
  if (l > 0) {
    link += `?collecteId=${data[0].code}`;
  }
  for (let i = 1; i < l; i += 1) {
    link += `&collecteId=${data[i].code}`;
  }
  return link;
}

const GarbageCollectExcetionLink: LinkOnSelectedInterface<WasteManagementInterface> = {
  title: 'Créer une exception',
  getPath,
};

export default GarbageCollectExcetionLink;
