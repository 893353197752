import GetAdditionalQueryInterface from '../../../Types/Interface/MethodInterface/GetAdditionalQueryInteface';
import ActualityAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/ActualityAdditionalArgs';
import { ActualityInterface } from '../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import StringContains from '../../StringContains';

const GetActualityAdditionalQuery: GetAdditionalQueryInterface<ActualityAdditionalArgs> = (
  _additionalQuery: ActualityAdditionalArgs,
) => null;

// some additional args are not well handle by the clloApi, so their treated by the front
const parseActualityList = (
  dataBlock: ActualityInterface[],
  args: ActualityAdditionalArgs,
): ActualityInterface[] => dataBlock.filter((row) => StringContains(row.title, args.title));

export default GetActualityAdditionalQuery;
export { parseActualityList };
