import { createTheme } from '@material-ui/core';
import theme from './ProjectTheme';

const TableItemTheme = createTheme({
  typography: {
    fontSize: 14,
  },
  palette: theme.palette,
  overrides: {
    MuiTableCell: {
      head: {
        fontSize: 18,
      },
      body: {
        paddingTop: '1pt',
        paddingBottom: '1pt',
      },
    },
  },
});

export default TableItemTheme;
