import React, { FunctionComponent } from 'react';
import ManagingRightAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/ManagingRightAdditionalArgs';
import { ManagingRightInterface } from '../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import ManagingRightSharedData from '../../../Types/Interface/TableInterfaces/SharedDataInterface/ManagingRightSharedData';
import GetManagingRightComparatorFromKey from '../../../Methods/TableQuery/HandleFilter/HandleSort/GetManagingRightComparatorFromKey';
import AdminListContainer from '../BeecomingCompenents/AdminContainers/AdminListContainer';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import ListHeaderManagingRight from '../BeecomingCompenents/ListElements/ListHeaders/ListHeaderManagingRight';
import ListItemManagingRight from '../BeecomingCompenents/ListElements/ListItems/ListItemManagingRight';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import GetManagingRightAdditionalQuery, {
  parseManagingRightList,
} from '../../../Methods/TableQuery/GetAdditionalQueries/GetManagingRightAdditionalQuery';
import { ManagingRightFormatter } from '../../../Types/Interface/TableInterfaces/DataInterface/ManagingRightInterface';

interface Props {}

const getActualityList = (additionalParameters: ManagingRightAdditionalArgs, history: any) => {
  const query = GetManagingRightAdditionalQuery(additionalParameters);
  return ApiFetch(`/OpenData/record/actualites/admin?rows=-1${query ?? ''}`, 'GET', history);
};

const ListManagingRight: AdminPageComponent = ({ onError }) => (
  <AdminListContainer<ManagingRightInterface, ManagingRightAdditionalArgs, ManagingRightSharedData>
    addNew="Ajouter un compte"
    defaultSharedData={{ highlight: '' }}
    defaultAdditionalArgs={{ title: '', body: '', start: null, end: null }}
    defaultSort="title"
    headComponent={ListHeaderManagingRight}
    listComponent={ListItemManagingRight}
    getList={{
      request: getActualityList,
      formatter: ManagingRightFormatter,
      filter: parseManagingRightList,
      errorMessage: "La liste des comptes n'a pas pu être chargée correctement",
    }}
    getComparatorFromKey={GetManagingRightComparatorFromKey}
    onError={onError}
  />
);

const ListManagingRightProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Gestion des droits"
    menuPath="managing-right-add-account"
    screenName="managing-right"
    adminPage={ListManagingRight}
  />
);

export default ListManagingRightProtected;
