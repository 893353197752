/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */

import { Order } from '../../../../Types/BeecomingTypes/BeecomingTypes';
import GetComparatorFromKeyInterface from '../../../../Types/Interface/MethodInterface/GetComparatorFromKeyInterface';
import { NotificationInterface } from '../../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';

const GetNotificationComparatorFromKey: GetComparatorFromKeyInterface<NotificationInterface> = (
  key: keyof NotificationInterface,
  typeOrder: Order,
) => {
  const sign: number = typeOrder === 'asc' ? 1 : -1;
  switch (key) {
    case 'title':
      return (a: NotificationInterface, b: NotificationInterface) =>
        sign * a.title.localeCompare(b.title);
    case 'body':
      return (a: NotificationInterface, b: NotificationInterface) =>
        sign * a.body.localeCompare(b.body);
    case 'sendingTime':
      return (a: NotificationInterface, b: NotificationInterface) =>
        sign * (new Date(a.sendingTime).getTime() - new Date(b.sendingTime).getTime());
    case 'PictureUrl':
      return (_a: NotificationInterface, _b: NotificationInterface) => 0;
    default:
      return (a: NotificationInterface, b: NotificationInterface) =>
        sign * a[key].toLocaleString().localeCompare(b[key].toLocaleString());
  }
};

export default GetNotificationComparatorFromKey;
