/* eslint-disable operator-linebreak */
import 'date-fns';
import { Button, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import React, { FunctionComponent, useEffect, useState } from 'react';

import '../Admin.scss';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert/Alert';
import NotificationInterface, {
  NotificationFormatter,
} from '../../../Types/Interface/TableInterfaces/DataInterface/NotificationInterface';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';

interface Props {}

/**
 * @param onError method: show a snackbar with the chosen message
 * @returns Notification Edit and Creation Page
 */

const AdminEditFeatures: AdminPageComponent = ({ onError }) => {
  const history = useHistory();

  const [pageTitle, setPageTitle] = useState<string>("Ajout d'une fonctionnalité");

  const [errorTitle, setErrorTitle] = useState<boolean>(false);
  const [errorBody, setErrorBody] = useState<boolean>(false);
  const [errorAddress, setErrorAddress] = useState<boolean>(false);
  const [errorAdditionalInfo, setErrorAdditionalInfo] = useState<boolean>(false);
  const [errorPrice, setErrorPrice] = useState<boolean>(false);
  const [errorAge, setErrorAge] = useState<boolean>(false);
  const [errorImg, setErrorImg] = useState<boolean>(false);

  const [alert, setAlert] = useState<string>('null');

  const [id, setId] = useState<string | null>();
  const [title, setTitle] = useState<string | null>('');
  const [body, setBody] = useState<string | null>('');
  const [price, setPrice] = useState<string | null>('');
  const [age, setAge] = useState<string | null>('');
  const [additionalInfo, setAdditionalInfo] = useState<string | null>('');
  const [address, setAddress] = useState<string | null>('');
  const [imgUrl, setImgUrl] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedDateEnd, setSelectedDateEnd] = useState<Date | null>(new Date());
  const [interval, setInterval] = useState<string>('00:00:00');

  const handleDateChange = (date: Date | null, type: string) => {
    date?.setSeconds(0);
    date?.setMilliseconds(0);
    if (type === 'start') {
      setSelectedDate(date);
    } else {
      setSelectedDateEnd(date);
    }
  };

  // #region handle Validation
  /**
   * @returns either the fields are valids
   */
  const isValid = (): boolean => {
    let valid: boolean = true;
    if (title === '') {
      valid = false;
      setErrorTitle(true);
    }
    if (body === '') {
      valid = false;
      setErrorBody(true);
    }
    if (address === '') {
      valid = false;
      setErrorAddress(true);
    }
    if (additionalInfo === '') {
      valid = false;
      setErrorAdditionalInfo(true);
    }
    if (imgUrl === '') {
      valid = false;
      setErrorImg(true);
    }
    if (age === '') {
      valid = false;
      setErrorAge(true);
    }
    if (price === '') {
      valid = false;
      setErrorPrice(true);
    }
    if (selectedDate === null) {
      valid = false;
    }
    if (selectedDateEnd === null) {
      valid = false;
    }
    return valid;
  };

  /**
   * Ask Api to edit or create a notfication
   */
  const handleValidation = async () => {
    if (isValid()) {
      setAlert('success');
    } else {
      setAlert('error');
    }
  };
  // #endregion

  /**
   * Ask API to delete a notification
   */
  const handleDelete = () => {};

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const notifId = params.get('id');
    setId(notifId);
    // if notifId is null then the user is creating a new notification
    if (notifId === null) {
      // look for url parameter to set notification parameter
      // (used for creating new notification)
      setTitle(params.get('titre') ?? '');
      setBody(params.get('corps') ?? '');
      setImgUrl(params.get('imgUrl') ?? '');
      setSelectedDate(new Date(params.get('date') ?? new Date()));
      setInterval(params.get('interval') ?? '00:00:00');
    } else {
      // ask api to set notification parameter
      // (used for editing existing notification)
      setPageTitle('Edition de notification');
      ApiFetch(`/Notification/notification?notificationId=${notifId}`, 'GET', history)
        .then((response) => response.json())
        .then((r) => NotificationFormatter(r))
        .then((resp: NotificationInterface) => {
          setTitle(resp.title);
          setBody(resp.body);
          setSelectedDate(resp.sendingTime);
          setInterval(resp.interval);
        });
    }
  }, []);

  return (
    <div>
      <h1>{pageTitle}</h1>
      {alert !== 'null' && (
        <Alert
          onClose={() => setAlert('null')}
          severity={alert === 'success' ? 'success' : 'error'}
        >
          {alert === 'success' ? 'Événement ajouté !' : 'Erreur'}
        </Alert>
      )}
      <div className="newElementContainer">
        <div className="newElementColumn">
          <div className="newElementProperty">
            <TextField
              label="Nom de la fonctionnalité"
              value={title}
              error={errorTitle}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTitle(event.target.value);
                setErrorTitle(false);
                setAlert('null');
              }}
            />
          </div>
          <p className="textNewElementProperty">Date de l&apos;événement</p>
          <div style={{ display: 'flex', flexDirection: 'row' }} className="newElementProperty">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
              <KeyboardDatePicker
                required
                style={{ marginRight: '10pt' }}
                value={selectedDate}
                onChange={(date) => handleDateChange(date, 'start')}
                format="dd/MM/yyyy"
                okLabel="OK"
                cancelLabel="Annuler"
                invalidDateMessage="Date invalide"
              />
              <KeyboardDatePicker
                required
                style={{ marginLeft: '10pt' }}
                value={selectedDateEnd}
                onChange={(date) => handleDateChange(date, 'end')}
                format="dd/MM/yyyy"
                okLabel="OK"
                cancelLabel="Annuler"
                invalidDateMessage="Date invalide"
              />
              {/* <KeyboardTimePicker
                required
                style={{ marginLeft: '10pt' }}
                value={selectedDate}
                ampm={false}
                onChange={handleDateChange}
                okLabel="OK"
                cancelLabel="Annuler"
                invalidDateMessage="Horaire invalide"
              /> */}
            </MuiPickersUtilsProvider>
          </div>

          <div className="newElementProperty">
            <TextField
              label="Adresse de l'événement"
              value={address}
              error={errorAddress}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              rows={4}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddress(event.target.value);
                setErrorAddress(false);
                setAlert('null');
              }}
            />
          </div>

          <div className="newElementProperty">
            <TextField
              value={imgUrl}
              error={errorImg}
              InputLabelProps={{ shrink: true }}
              fullWidth
              label="URL de l'image"
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setImgUrl(event.target.value);
                setAlert('null');
              }}
            />
          </div>
          <div className="newElementProperty">
            <Button fullWidth variant="contained" color="primary" onClick={handleValidation}>
              Valider
            </Button>
            {id !== null ? (
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleDelete}
                style={{ marginTop: 20 }}
              >
                Supprimer
              </Button>
            ) : null}
          </div>
        </div>

        <div className="newElementColumn">
          <div className="newElementProperty">
            <TextField
              label="Description de l'événement"
              value={body}
              error={errorBody}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              rows={4}
              multiline
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setBody(event.target.value);
                setErrorBody(false);
                setAlert('null');
              }}
            />
          </div>
          <div className="newElementProperty">
            <TextField
              label="Informations complémentaires"
              value={additionalInfo}
              error={errorAdditionalInfo}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              rows={3}
              multiline
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAdditionalInfo(event.target.value);
                setErrorAdditionalInfo(false);
                setAlert('null');
              }}
            />
          </div>
          <p className="textNewElementProperty">Tarif</p>
          <div
            className="newElementProperty"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <TextField
              label="Prix"
              style={{ marginRight: '10px' }}
              value={price}
              error={errorPrice}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              rows={1}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPrice(event.target.value);
                setErrorPrice(false);
                setAlert('null');
              }}
            />
            <TextField
              label="Âge"
              value={age}
              error={errorAge}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              rows={1}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAge(event.target.value);
                setErrorAge(false);
                setAlert('null');
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const AdminEditFeaturesProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Fonctionnalités"
    screenName="features-edition"
    menuPath="features"
    adminPage={AdminEditFeatures}
  />
);

export default AdminEditFeaturesProtected;
