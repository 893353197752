import DisplayLevel from '../../../BeecomingTypes/DisplayLevel';
import DataInterface from './DataInterface';

interface FeaturesFromFetch {
  recordid: string;
  fields: FieldsInterface;
  level: DisplayLevel;
}

interface FieldsInterface {
  title: string;
  published: Date;
}

interface FeaturesInterface extends DataInterface {
  title: string;
  level: DisplayLevel;
  published: Date;
}

function FeaturesFormatter(data: FeaturesFromFetch): FeaturesInterface {
  return {
    id: data.recordid,
    title: data.fields.title,
    level: data.level ?? -1,
    published: data.fields.published,
  };
}

export default FeaturesInterface;
export { FeaturesFormatter };
