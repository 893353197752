/* eslint-disable no-param-reassign */
/* eslint-disable operator-linebreak */
import { Button, TableCell, TableRow } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import AdminScreenPathsList from '../../../../../Datas/BeecomingDatas/AdminScreenPathsList';
import ListItemProps from '../../../../../Types/Interface/Props/ListItemProps';
import BusInterface from '../../../../../Types/Interface/TableInterfaces/DataInterface/BusInterface';
import BusSharedData from '../../../../../Types/Interface/TableInterfaces/SharedDataInterface/BusSharedData';

const ListItemBus: FunctionComponent<ListItemProps<BusInterface, BusSharedData>> = ({ row }) => {
  const history = useHistory();

  const handleEditHourAndStop = () => {
    history.push(`${AdminScreenPathsList['line-bus-hour-edition']}?id=${row.title}`);
  };

  return (
    <TableRow style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
      <TableCell style={{ borderBottom: 'none' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              backgroundColor: row.color,
            }}
            className="circleColorBusLine"
          />
          {row.title}
        </div>
      </TableCell>
      <TableCell align="center" style={{ borderBottom: 'none' }}>
        {row?.busStops?.length ?? 0}
      </TableCell>

      <TableCell align="center">
        <Button onClick={handleEditHourAndStop} color="secondary">
          Éditer
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default ListItemBus;
