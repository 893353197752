import React, { FunctionComponent } from 'react';
import FeaturesAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/FeaturesAdditionalArgs';
import { FeaturesInterface } from '../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import FeaturesSharedData from '../../../Types/Interface/TableInterfaces/SharedDataInterface/FeaturesSharedData';
import GetFeaturesComparatorFromKey from '../../../Methods/TableQuery/HandleFilter/HandleSort/GetFeaturesComparatorFromKey';
import AdminListContainer from '../BeecomingCompenents/AdminContainers/AdminListContainer';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import ListHeaderFeatures from '../BeecomingCompenents/ListElements/ListHeaders/ListHeaderFeatures';
import ListItemFeatures from '../BeecomingCompenents/ListElements/ListItems/ListItemFeatures';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import GetFeaturesAdditionalQuery, {
  parseFeaturesList,
} from '../../../Methods/TableQuery/GetAdditionalQueries/GetFeaturesAdditionalQuery';
import { FeaturesFormatter } from '../../../Types/Interface/TableInterfaces/DataInterface/FeaturesInterface';

interface Props {}

const getFeaturesList = (additionalParameters: FeaturesAdditionalArgs, history: any) => {
  const query = GetFeaturesAdditionalQuery(additionalParameters);
  return ApiFetch(`/OpenData/record/actualites/admin?rows=-1${query ?? ''}`, 'GET', history);
};

const ListFeatures: AdminPageComponent = ({ onError }) => (
  <AdminListContainer<FeaturesInterface, FeaturesAdditionalArgs, FeaturesSharedData>
    defaultSharedData={{ highlight: '' }}
    // addNew="Nouvelle fonctionnalité" //on affiche plus cette fonctionnalité (19/11/21)
    defaultAdditionalArgs={{ title: '', body: '', start: null, end: null }}
    defaultSort="title"
    headComponent={ListHeaderFeatures}
    listComponent={ListItemFeatures}
    getList={{
      request: getFeaturesList,
      formatter: FeaturesFormatter,
      filter: parseFeaturesList,
      errorMessage: "La liste des fonctionnalités n'a pas pu être chargée correctement",
    }}
    getComparatorFromKey={GetFeaturesComparatorFromKey}
    onError={onError}
  />
);

const ListFeaturesProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Fonctionnalités"
    menuPath="features"
    screenName="features"
    adminPage={ListFeatures}
  />
);

export default ListFeaturesProtected;
