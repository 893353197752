/* eslint-disable operator-linebreak */
/* eslint-disable wrap-iife */
/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
import assert from 'assert';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import {
  FormatterWaste,
  WasteManagementInterface,
} from '../../../Types/Interface/TableInterfaces/DataInterface/WasteManagementInterface';

import '../Admin.scss';
import '../BeecomingCompenents/AdminDataDisplayer.scss';
import AdminDisplayExceptions from '../BeecomingCompenents/AdminDisplayExceptions';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import DisplayProperty from '../BeecomingCompenents/DisplayProperty';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import AllDays from '../../../Datas/BeecomingDatas/AllDays';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';

interface Props {}

/**
 * @param onError method: show a snackbar with the chosen message
 * @returns Garbage Collect Edition and Creation Page
 */

const AdminEditWasteCollect: AdminPageComponent = ({ onError }) => {
  const history = useHistory();
  const [collectId, setCollectId] = useState<string | null>(null);
  const [collectZone, setCollectZone] = useState<string>('');
  const [collectType, setCollectType] = useState<string>('');
  const [day, setDay] = useState<string>('');
  const [collectWeekType, setCollectWeekType] = useState<string>('');

  const handleNotify = () => {
    const now = new Date(Date.now());
    now.setSeconds(now.getSeconds() + 10);
    const requestData = {
      notification: {
        title: 'test' as string,
        body: `Ceci est un test de notfication pour la collecte ${collectId}`,
        PictureUrl: 'https://www.sablesursarthe.fr/wp-content/uploads/2017/08/logo.png',
        Topics: {
          Category: 'Gestion des déchets',
          subCategories: [collectType],
          zones: [collectId],
        },
        Datas: {
          DatasetId: 'sss',
          RecordId: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, '')
            .substr(0, 5),
        },
      },
      sendingTime: now as Date,
    };
    ApiFetch('/notifications', 'POST', history, requestData).then((response) => {
      if (response.ok) {
        onError('La demande de notification a bien été prise en compte, envoi dans 10 secondes');
      } else {
        onError("La notification n'a pas été envoyée");
      }
    });
    console.log(requestData);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const id = params.get('id');
    if (id !== null) {
      setCollectId(id);
      fetch(`${process.env.REACT_APP_URL_REQUEST}/waste-pickup/${id}`, { method: 'GET' })
        .then((response) => response.json())
        .then((r) => FormatterWaste(r))
        .then((resp: WasteManagementInterface) => {
          assert(id === resp.code);
          setCollectZone(resp.name);
          setCollectType(resp.type);
          setDay(AllDays[resp.day - 1].name);
          setCollectWeekType(
            resp.recurrence === 0 ? 'toutes' : resp.recurrence === 1 ? 'impaires' : 'paires',
          );
        });
    } else {
      onError("Une erreur s'est produite, impossible de charger la collecte.");
    }
  }, []);

  return (
    <div>
      <h1>Détails de la collecte</h1>
      <div className="newElementContainer">
        <div className="newElementColumn">
          <DisplayProperty label="Identifiant de la collecte :" value={collectId ?? ''} />
          <DisplayProperty label="Type de la collecte :" value={collectType} />
          <DisplayProperty label="Jour de la collecte :" value={day} />
          <DisplayProperty label="Semaine :" value={collectWeekType} />
        </div>
        <div className="newElementColumn">
          <DisplayProperty label="Zone de la collecte :" value={collectZone} />
        </div>
        <div className="newElementColumn">
          {(function () {
            if (
              process.env.REACT_APP_URL_REQUEST === 'https://api.sss-dev.beecoming.fr' ||
              process.env.REACT_APP_URL_REQUEST === 'https://localhost:5001'
            ) {
              return (
                <Button variant="outlined" onClick={handleNotify}>
                  Tester une notification (dev only)
                </Button>
              );
            }
            return null;
          })()}
        </div>
      </div>
      <div>
        <p>Exception (jour de passage)</p>
        <AdminDisplayExceptions collectId={collectId} onError={onError} />
      </div>
    </div>
  );
};

const AdminEditWasteCollectProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Gestion des déchets"
    screenName="waste-collect-edition"
    menuPath="waste-collect"
    adminPage={AdminEditWasteCollect}
  />
);

export default AdminEditWasteCollectProtected;
