import GetAdditionalQueryInterface from '../../../Types/Interface/MethodInterface/GetAdditionalQueryInteface';
import ArretBusAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/ArretBusAdditionalArgs';
import { ArretBusInterface } from '../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import StringContains from '../../StringContains';

const GetArretBusAdditionalQuery: GetAdditionalQueryInterface<ArretBusAdditionalArgs> = (
  _additionalQuery: ArretBusAdditionalArgs,
) => null;

// some additional args are not well handle by the clloApi, so their treated by the front
const parseArretBusList = (
  dataBlock: ArretBusInterface[],
  args: ArretBusAdditionalArgs,
): ArretBusInterface[] =>
  // eslint-disable-next-line implicit-arrow-linebreak
  dataBlock.filter((row) => StringContains(row.name, args.title));

export default GetArretBusAdditionalQuery;
export { parseArretBusList };
