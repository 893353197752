import DataInterface from './DataInterface';

interface WasteManagementInterface {
  id: string;
  code: string;
  name: string;
  day: number;
  type: string;
  recurrence: number;
}

interface FieldProps {
  collecteid: string;
  typeid: string;
  typeidlabel: string;
  jour: string;
  zoneid: string;
  zoneidlabel: string;
  semaineid: string;
  semaineidlabel: string;
}

interface BasicGarbageCollector {
  collecteid: string;
  typeid: number;
  zoneid: number;
  zoneidlabel: string;
  semaineid: number;
}

interface GarbageCollectorInterface extends DataInterface, BasicGarbageCollector {
  typeidlabel: string;
  jour: string;
  semaineidlabel: string;
  selected: boolean;
  importCSV: string;
}

function FormatterBasicWaste(data: any): BasicGarbageCollector {
  return {
    collecteid: data.fields.collecteid,
    typeid: Number(data.fields.typeid),
    zoneid: Number(data.fields.zoneid),
    zoneidlabel: data.fields.zoneidlabel,
    semaineid: Number(data.fields.semaineid),
  };
}

function FormatterWaste(data: WasteManagementInterface): WasteManagementInterface {
  return {
    id: data.code,
    code: data.code,
    name: data.name,
    recurrence: data.recurrence,
    day: data.day,
    type: data.type,
  };
}

export default GarbageCollectorInterface;
export type { BasicGarbageCollector, WasteManagementInterface };
export { FormatterWaste, FormatterBasicWaste };
