import DataInterface from './DataInterface';

interface ParkingFromFetch {
  id: string;
  name: string;
  coordinate: Array<number>;
  maxSpaces: number;
}

interface ParkingInterface extends DataInterface {
  title: string;
  name: string;
  coordinate: Array<number>;
  maxSpaces: number;
  edit: string;
}

function ParkingFormatter(data: ParkingFromFetch): ParkingInterface {
  return {
    id: data.id,
    title: data.name,
    name: data.name,
    coordinate: data.coordinate,
    maxSpaces: data.maxSpaces,
    edit: 'Editer',
  };
}

export default ParkingInterface;
export { ParkingFormatter };
