import { AdminScreenPaths } from '../../Types/BeecomingTypes/AdminScreen';

const AdminScreenPathsList: AdminScreenPaths = {
  home: '/',
  account: '/mon-compte',
  'market-place': '/admin',
  budget: '/admin',

  statistics: '/statistiques',

  // ADMINISTRATION
  notification: '/administration/notification',
  'notification-edition': '/administration/notification/edition',
  actuality: '/administration/actualites',
  'actuality-edition': '/administration/actualites/edition',
  parking: '/administration/parking',
  'parking-edition': '/administration/parking/edition',
  agenda: '/administration/agenda',
  'agenda-edition': '/administration/agenda/edition',
  'agenda-proposition': '/administration/agenda/proposition',
  'waste-collect': '/administration/gestion-dechets',
  'waste-collect-edition': '/administration/gestion-dechets/edition',
  'waste-collect-exception': '/administration/gestion-dechets/exception',
  'admin-promote': '/administration/promouvoir',

  // GESTION DES BUS
  'line-bus': '/gestion-bus/ligne',
  'line-bus-add': '/gestion-bus/ligne/ajout',
  'line-bus-hour-edition': '/gestion-bus/ligne/horaires/edition',
  'line-bus-stop-edition': '/gestion-bus/ligne/arrêts/edition',
  'line-bus-name-and-color-edition': '/gestion-bus/ligne/nom-et-couleur/edition',
  'arret-bus': '/gestion-bus/arret-bus',
  'arret-bus-edition': '/gestion-bus/arret-bus/edition',

  signalement: '/signalement',
  demarche: '/demarche',

  features: '/fonctionnalites',
  'features-edition': '/fonctionnalites/edition',

  'managing-right': '/gestion-des-droits',
  'managing-right-add-account': '/gestion-des-droits/edition',
};

export default AdminScreenPathsList;
