import { makeStyles } from '@material-ui/core';
import { lightGreen, amber } from '@material-ui/core/colors';
import ProjectTheme from './ProjectTheme';

const UseBeecomingStyles = makeStyles(() => ({
  BeecomingButton: {
    textTransform: 'none',
  },
  waste_style: {
    color: `${lightGreen[300]}!important`,
  },
  packaging_style: {
    color: `${amber[300]}!important`,
  },
  loader: {
    position: 'absolute',
  },
  navigationSubItem: {
    paddingLeft: ProjectTheme.spacing(4),
  },
}));

export default UseBeecomingStyles;
