import React, { ChangeEventHandler, FunctionComponent } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

interface Props {
  label: string;
  handleSearch: ChangeEventHandler<HTMLTextAreaElement>;
}

const SearchBox: FunctionComponent<Props> = ({ label, handleSearch }) => (
  <TextField
    label={label}
    variant="outlined"
    color="secondary"
    onChange={handleSearch}
    size="small"
    InputLabelProps={{ shrink: true }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />
);

export default SearchBox;
