/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-len */
import DateFnsUtils from '@date-io/date-fns';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faEnvelope,
  faKey,
  faMinus,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, colors, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import 'date-fns';
import { keyBy, keys, mapValues, omit } from 'lodash';
import ColorPicker from 'material-ui-color-picker';
// import { ColorButton } from 'material-ui-color';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import LogoDragNDrop from '../../../assets/icons/move_btn.svg';
import AdminScreenPathsList from '../../../Datas/BeecomingDatas/AdminScreenPathsList';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import '../Admin.scss';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';

library.add(faEnvelope, faKey, faMinus, faPlus, faTrash, faCheck);

interface Props {}
const defaultHour = null;

const reorder = <T extends unknown>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const AdminEditHourBus: AdminPageComponent = () => {
  const urlRequest = process.env.REACT_APP_URL_REQUEST;

  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentLine, setCurrentLine] = useState<any>([]); // state de la ligne de bus actuelle
  const [nameOfLine, setNameOfLine] = useState<string | null>();
  const [id, setId] = useState<string | null>();
  const [color, setColor] = useState<string | null>();
  const defaultColor = '#000';
  const [allStops, setAllStops] = useState<any>({}); // tous les arrets dispo
  const [lineStops, setLineStops] = useState<string[]>([]); // tous les codeStop desservis par la ligne
  const [initialLineBus, setInitialLineBus] = useState<any>(null);
  const [displayStopBus, setDisplayStopBus] = useState<boolean>(true);
  const [displayNameAndColorOfLine, setDisplayNameAndColorOfLine] = useState<boolean>(true);
  const [displayHourBus, setDisplayHourBus] = useState<boolean>(true);
  const [errorNameOfLine, setErrorNameOfLine] = useState<boolean>(false);

  const init = (lineBus: any) => {
    const copyLineBus = { ...lineBus };
    // ajout d'un objet qui contient les arrets désservis ainsi que par la ligne de bus
    // formattage pour accéder aux arrets desservis + horaires plus facilement
    copyLineBus.busRoutes = copyLineBus.busRoutes.map((route: any) => ({
      ...route,
      schedule: mapValues(
        keyBy(route.busRoute_x_BusStop_array, 'busStopId'),
        (stopTime) => stopTime.hour,
      ),
    }));
    setCurrentLine(copyLineBus);
    // Récupération des arrets desservis
    setLineStops((lineBus.busStops || []).map((stop: any) => stop.code_stop));
  };

  const keysWeeks = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
    'isSchoolPeriod',
    'isHolidayPeriod',
  ];

  const tabWeeks = [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
    'Périodes scolaires',
    'Vacances scolaires',
  ];

  type lineCurrentType = {
    name: string;
    color: string | null;
    busStops: [];
    busRoutes: Array<any>;
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    setLineStops((oldLineStops) =>
      reorder(oldLineStops, result.source.index, result.destination.index),
    );

    return null;
  };

  const getBusStops = async () => {
    // Récupération de la liste de tous les arrêts existants
    let allBusStops: any[] | undefined | null = [];
    // eslint-disable-next-line implicit-arrow-linebreak
    await ApiFetch('/bus-stop', 'GET', history)
      .then((response) => {
        if (!response.ok) {
          toast.error('Impossible de récupérer les arrêt de bus');
          return null;
        }
        return response.json();
      })
      .then((data) => {
        allBusStops = data;
      });
    return allBusStops;
  };

  const getLineCurrent = async (idOnURl: string | null) => {
    // Récupération de la ligne de bus actuelle
    let lineCurrent: lineCurrentType = {
      name: '',
      color: defaultColor,
      busStops: [],
      busRoutes: [],
    };
    // eslint-disable-next-line no-template-curly-in-string
    await ApiFetch(`/bus-line/${idOnURl}`, 'GET', history)
      .then((response) => {
        if (response.status !== 200) {
          toast.error('Echec de la récupération des infos.');
          return lineCurrent;
        }
        return response.json();
      })
      .then((data) => {
        lineCurrent = data;
      });
    return lineCurrent;
  };

  const sendApi = async (lineForApi: any) => {
    // Enregistrement de la ligne de bus en BDD
    await ApiFetch('/bus-line', 'PUT', history, lineForApi).then((result) => {
      if (result.ok) {
        toast.success('Modifications enregistrées');
      } else {
        toast.error('Echec de la modification des données');
      }
    });
  };

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const idOnURl = params.get('id');
    setId(idOnURl);
    if (idOnURl !== null) {
      getBusStops().then((allBusStops) => {
        // 1. on récupère la liste de tous les arrêts de bus existants
        setAllStops(keyBy(allBusStops, 'code_stop'));
        // eslint-disable-next-line no-return-assign
        getLineCurrent(idOnURl).then((lineBusFromAPI) => {
          // on récupère la ligne de bus actuelle
          setInitialLineBus(lineBusFromAPI);
          setColor(lineBusFromAPI.color);
          setNameOfLine(lineBusFromAPI.name);
          init(lineBusFromAPI);
          setIsLoading(false);
        });
      });
    } else {
      history.push(AdminScreenPathsList['line-bus']);
    }
  }, []);

  const handleCheck = (checked: any, codeStop: string) => {
    // Check ou Décheck les arrêts sur la ligne de bus
    if (checked) {
      // J'ajoute l'arret dans mon tableau d'ordre des arrets
      setLineStops((oldLineStops) => oldLineStops.concat(codeStop));
      // Pour chaque route, je vais rajouter le schedule correspondant au nouvel array
      setCurrentLine((oldCurrentLine: any) => ({
        ...oldCurrentLine,
        busRoutes: oldCurrentLine.busRoutes.map((route: any) => ({
          ...route,
          schedule: {
            ...route.schedule,
            [codeStop]: null,
          },
        })),
      }));
    } else {
      setLineStops(
        (oldLineStops) => oldLineStops.filter((lineCodeStop: any) => lineCodeStop !== codeStop),
        // eslint-disable-next-line function-paren-newline
      );
      setCurrentLine((oldCurrentLine: any) => ({
        ...oldCurrentLine,
        busRoutes: oldCurrentLine.busRoutes.map((route: any) => ({
          ...route,
          schedule: omit(route.schedule, [codeStop]),
        })),
      }));
    }
  };

  const handleValidation = () => {
    // On mets à jour dans la bdd
    const lineForAPI = {
      name: id,
      color,
      busStopsIds: lineStops,
      busRoutes: currentLine.busRoutes.map((route: any) => ({
        ...route,
        schedule: undefined,
        busRoute_x_BusStop_array: lineStops.map((codeStop: any) => ({
          busStopId: codeStop,
          hour: route.schedule[codeStop],
        })),
      })),
    };
    sendApi(lineForAPI);
  };

  const handleDelete = () => {
    if (window.confirm('Etes vous sûr de vouloir supprimer cette ligne de bus?')) {
      ApiFetch(`/bus-line/${currentLine.name}`, 'DELETE', history).then((response) => {
        if (response.ok) {
          toast.success("L'arrêt de bus a bien été supprimé");
          history.push(AdminScreenPathsList['line-bus']);
        } else {
          toast.error('Echec de la suppression.');
        }
      });
    }
  };

  const annulation = () => {
    // On annule les modifs
    init(initialLineBus);
  };

  const deleteColumn = (indexRoute: number) => {
    setCurrentLine((oldCurrentLine: any) => ({
      ...oldCurrentLine,
      busRoutes: oldCurrentLine.busRoutes.filter((r: any, index: number) => index !== indexRoute),
    }));
  };

  const addColumn = () => {
    // Ici je souhaite ajouter une nouvelle colonne
    const newBusRoute = {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      isSchoolPeriod: false,
      isHolidayPeriod: false,
      busRoute_x_BusStop_array: [],
      schedule: mapValues(keyBy(lineStops), () => defaultHour),
    };
    setCurrentLine((oldCurrentLine: any) => ({
      ...oldCurrentLine,
      busRoutes: (oldCurrentLine.busRoutes || []).concat(newBusRoute),
    }));
  };

  const onChangeHours = (
    value: string | number | Date | null,
    codeStop: string,
    routeIndex: number,
  ) => {
    const newValue = moment(value).toISOString();
    // Change la valeur des input d'heures
    setCurrentLine((oldCurrentLine: any) => ({
      ...oldCurrentLine,
      busRoutes: oldCurrentLine.busRoutes.map((route: any, index: number) => {
        if (index !== routeIndex) {
          return route;
        }
        return {
          ...route,
          schedule: {
            ...route.schedule,
            [codeStop]: JSON.parse(JSON.stringify(newValue)),
          },
        };
      }),
    }));
  };

  const onChangeCheck = (checked: any, routeIndex: number, week: string) => {
    // Change check des jours + vacances
    setCurrentLine((oldCurrentLine: any) => ({
      ...oldCurrentLine,
      busRoutes: oldCurrentLine.busRoutes.map((route: any, index: number) => {
        if (index !== routeIndex) {
          return route;
        }
        return {
          ...route,
          [week]: checked,
        };
      }),
    }));
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 1,
    margin: '0 0 2px 0',
    width: '100%',
    display: 'flex',
    justifContent: 'left',
    // change background colour if dragging
    background: isDragging ? 'lightgrey' : 'transparent',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const handleDisplayStopBus = () => {
    setDisplayStopBus((oldDisplayStopBus) => !oldDisplayStopBus);
  };
  const handleDisplayHourBus = () => {
    setDisplayHourBus((oldDisplayHourBus) => !oldDisplayHourBus);
  };
  const handleDisplayNameAndColorOfLine = () => {
    setDisplayNameAndColorOfLine((oldDisplayNameAndColorOfLine) => !oldDisplayNameAndColorOfLine);
  };

  const validateChangeName = async () => {
    ApiFetch(`/update-name?previousName=${id}&newName=${nameOfLine}`, 'PUT', history)
      .then((response) => {
        if (response.ok) {
          toast.success('Le nom à été changé');
          return response.json();
        }

        toast.error('Echec de la modification du nom');
      })
      .then((data) => {
        if (data) {
          setId(data.name);
        }
      });
  };

  const isValid = (): boolean => {
    let valid: boolean = true;
    if (nameOfLine === '') {
      valid = false;
      setErrorNameOfLine(true);
    }
    return valid;
  };

  const handleValidationChangeName = (boolean: boolean) => {
    if (boolean) {
      // Validation
      if (isValid()) {
        validateChangeName();
      }
    }
  };

  const borderTable = '5px';

  return isLoading ? (
    <p>En attente ...</p>
  ) : (
    <div>
      {/* Nom de la ligne */}
      <div className="container-btn-display">
        {/* <p className="textNewElementProperty">Couleur de la ligne</p> */}
        <p className="textNewElementProperty">Nom et couleur de la ligne</p>
        <button className="btn-display" onClick={handleDisplayNameAndColorOfLine}>
          {displayNameAndColorOfLine ? (
            <FontAwesomeIcon icon="minus" />
          ) : (
            <FontAwesomeIcon icon="plus" />
          )}
        </button>
      </div>
      {displayNameAndColorOfLine && (
        <>
          <div className="newElementProperty container-btn-change-name">
            <TextField
              label="Nom de la ligne"
              value={nameOfLine}
              error={errorNameOfLine}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNameOfLine(event.target.value);
                setErrorNameOfLine(false);
              }}
            />
            <Button
              style={{ marginLeft: '20px', width: '50px' }}
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleValidationChangeName(true)}
            >
              <FontAwesomeIcon icon="check" color="white" />
            </Button>
          </div>
          <div className=" colorContainer" style={{ padding: '0 15pt 0 15pt' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p
                style={{
                  marginRight: '10px',
                  textAlign: 'left',
                  color: 'rgba(0, 0, 0, 0.54)',
                  // fontSize: '12px',
                }}
              >
                Couleur de la ligne :
              </p>
              <ColorPicker
                name="color"
                defaultValue={defaultColor}
                value={color ?? defaultColor}
                onChange={(colorValue) => setColor(colorValue)}
                style={{
                  backgroundColor: color ?? defaultColor,
                  width: '15px',
                  height: '15px',
                  borderRadius: '2px',
                  content: '',
                  padding: 0,
                  margin: 0,
                  flexDirection: 'row',
                }}
              />
            </div>
          </div>
        </>
      )}

      {/* LES ARRETS DE LA LIGNE */}
      <div className="container-btn-display">
        <p className="textNewElementProperty">Arrêts de la ligne</p>
        <button className="btn-display" onClick={handleDisplayStopBus}>
          {displayStopBus ? <FontAwesomeIcon icon="minus" /> : <FontAwesomeIcon icon="plus" />}
        </button>
      </div>
      {displayStopBus && (
        <div className="newElementContainer">
          <div className="checkboxStopContainer newElementColumn">
            <p className="textNewElementProperty subtitle">Sélection des arrêts de la ligne</p>

            <div
              className="newElementProperty "
              style={{
                border: '1px solid lightgrey',
                borderRadius: '5px',
                margin: '0 15pt 15pt 15pt',
                padding: '5px',
              }}
            >
              <div className="containerStop scroll">
                {/* liste de tous les arrets */}
                {keys(allStops).map((codeStop: string, index: number) => (
                  <label htmlFor={`check${index}`} className="labelStop">
                    <input
                      type="checkbox"
                      id={`check${index}`}
                      checked={lineStops.some((lineCodeStop) => lineCodeStop === codeStop)}
                      onChange={(e) => handleCheck(e.target.checked, codeStop)}
                    />
                    {allStops[codeStop].name}
                  </label>
                ))}
              </div>
            </div>
          </div>
          <div className="checkboxStopContainer newElementColumn">
            <p className="textNewElementProperty subtitle">Ordre des arrêts sur la ligne</p>
            <div
              className="newElementProperty containerTabStopBus"
              style={{
                border: '1px solid lightgrey',
                borderRadius: '5px',
                margin: '0 15pt 15pt 15pt',
                padding: '5px',
              }}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(dropProvided: any, dropSnapshot: any) => (
                    <div
                      className="containerStop containerDrag"
                      {...dropProvided.droppableProps}
                      ref={dropProvided.innerRef}
                    >
                      {/* Drag'n Drop des arrets de bus */}
                      {lineStops.map((codeStop: any, index: number) => (
                        <Draggable key={codeStop} draggableId={codeStop} index={index}>
                          {(draProvided: any, dragSnapshot: any) => (
                            <div
                              ref={draProvided.innerRef}
                              {...draProvided.draggableProps}
                              {...draProvided.dragHandleProps}
                              style={getItemStyle(
                                dragSnapshot.isDragging,
                                draProvided.draggableProps.style,
                              )}
                            >
                              <img src={LogoDragNDrop} alt="" className="iconDragNDrop" />
                              <p className="textItemDrag">{allStops[codeStop].name}</p>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {dropProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
      )}
      <div className="separator" />
      {/* LES HORAIRES DE LA LIGNE */}
      <div className="container-btn-display">
        <p className="textNewElementProperty ">{id} - Horaires</p>
        <button className="btn-display" onClick={handleDisplayHourBus}>
          {displayHourBus ? <FontAwesomeIcon icon="minus" /> : <FontAwesomeIcon icon="plus" />}
        </button>
      </div>
      {displayHourBus && (
        <div className="tabContainerScroll">
          <table className="table table-hover tableCustom" cellSpacing="0" cellPadding="0">
            <tbody>
              <tr>
                <tr>
                  <td />
                  <td />
                  {currentLine.busRoutes.map((route: any, indexRoute: any) => (
                    // Suppression d'une colonne
                    <td
                      className="tdBorder"
                      style={{
                        borderTopLeftRadius: indexRoute === 0 ? borderTable : 0,
                      }}
                    >
                      <Button onClick={() => deleteColumn(indexRoute)}>
                        <FontAwesomeIcon icon="trash" color="grey" />
                      </Button>
                    </td>
                  ))}
                </tr>
                {tabWeeks.map((week: any, index: number) => (
                  <tr>
                    <td
                      // 1ère colonne - code arrêts
                      className={index === tabWeeks.length - 1 ? 'tdBlue' : 'none'}
                      style={{
                        borderTopLeftRadius: index === tabWeeks.length - 1 ? borderTable : '0px',
                      }}
                    >
                      {index === tabWeeks.length - 1 && 'Code arrêt'}
                    </td>
                    <td
                      // 2ème colonne - Jours de la semaine
                      style={{
                        borderTopLeftRadius: index === 0 ? borderTable : '0px',
                      }}
                      className={
                        week === 'Périodes scolaires'
                          ? 'tdBlue tdBorder'
                          : week === 'Vacances scolaires'
                          ? 'tdBlue tdBorder'
                          : 'tdGreen tdBorder'
                      }
                    >
                      {week}
                    </td>
                    {currentLine.busRoutes.map((route: any, indexRoute: any) => (
                      <td className="tdBorder">
                        <input
                          type="checkbox"
                          checked={route[keysWeeks[index]]}
                          onChange={(e) =>
                            onChangeCheck(e.target.checked, indexRoute, keysWeeks[index])
                          }
                        />
                      </td>
                    ))}
                  </tr>
                ))}
                {lineStops.map((codeStop: any) => (
                  // Chaque lignes
                  <React.Fragment key={codeStop}>
                    <tr>
                      <td className="tdBorder">{codeStop}</td>
                      <td className="tdBorder">{allStops[codeStop].name}</td>
                      {currentLine.busRoutes.map((route: any, routeIndex: number) => (
                        // Les input d'heures
                        <td className="tdBorder">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                              className="HorairePicker"
                              emptyLabel="     |"
                              style={{
                                width: 50,
                                textAlign: 'center',
                              }}
                              clearable
                              ampm={false}
                              invalidDateMessage=""
                              value={route.schedule[codeStop]}
                              onChange={(newValue) => {
                                if (newValue == null) {
                                  onChangeHours(newValue, codeStop, routeIndex);
                                }

                                const stringValue = newValue?.toString();
                                if (stringValue) {
                                  onChangeHours(new Date(stringValue), codeStop, routeIndex);
                                }
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </td>
                      ))}
                    </tr>
                  </React.Fragment>
                ))}

                <td onClick={addColumn} className="btn-add-column">
                  <div className="btn-add-colum-text-container">
                    <p className="btn-add-column-text">+</p>
                    <p className="btn-add-column-text">Ajouter une colonne</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div className="newElementProperty">
        <div className="parking_buttons_row_container">
          <Button fullWidth variant="contained" color="primary" onClick={handleValidation}>
            Valider
          </Button>
          <Button
            fullWidth
            variant="contained"
            style={{ backgroundColor: colors.red[700], color: '#FFFFFF' }}
            onClick={handleDelete}
          >
            Supprimer
          </Button>
        </div>
      </div>
    </div>
  );
};

const AdminEditHourBusProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Retour à la liste"
    screenName="line-bus"
    menuPath="line-bus"
    adminPage={AdminEditHourBus}
  />
);

export default AdminEditHourBusProtected;
