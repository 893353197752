import React, { FunctionComponent } from 'react';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import GetParkingAdditionalQuery, {
  parseParkingList,
} from '../../../Methods/TableQuery/GetAdditionalQueries/GetParkingAdditionalQuery';
import GetParkingComparatorFromKey from '../../../Methods/TableQuery/HandleFilter/HandleSort/GetParkingComparatorFromKey';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import ParkingAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/ParkingAdditionalArgs';
import ParkingInterface, {
  ParkingFormatter,
} from '../../../Types/Interface/TableInterfaces/DataInterface/ParkingInterface';
import ParkingSharedData from '../../../Types/Interface/TableInterfaces/SharedDataInterface/ParkingSharedData';
import AdminListContainer from '../BeecomingCompenents/AdminContainers/AdminListContainer';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import ListHeaderParking from '../BeecomingCompenents/ListElements/ListHeaders/ListHeaderParking';
import ListItemParking from '../BeecomingCompenents/ListElements/ListItems/ListItemParking';

interface Props {}

const getParkingList = (additionalParameters: ParkingAdditionalArgs, history: any) => {
  const query = GetParkingAdditionalQuery(additionalParameters);
  return ApiFetch('/parkings', 'GET', history);
};

const ListParking: AdminPageComponent = ({ onError }) => (
  <AdminListContainer<ParkingInterface, ParkingAdditionalArgs, ParkingSharedData>
    addNew="Ajouter un parking"
    defaultSharedData={{ highlight: '' }}
    defaultAdditionalArgs={{ title: '', body: '', start: null, end: null }}
    defaultSort="title"
    headComponent={ListHeaderParking}
    listComponent={ListItemParking}
    getList={{
      request: getParkingList,
      formatter: ParkingFormatter,
      filter: parseParkingList,
      errorMessage: "La liste des parkings n'a pas pu être chargée correctement",
    }}
    // getDefaultSharedData={GetParkingHighLight}
    getComparatorFromKey={GetParkingComparatorFromKey}
    onError={onError}
  />
);

const ListParkingProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Parkings"
    menuPath="parking"
    screenName="parking"
    adminPage={ListParking}
  />
);

export default ListParkingProtected;
