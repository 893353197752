/**
 * tells if a contains b (ignores case and accent)
 * @param a the string which may contains b
 * @param b the string which may be in a
 * @returns true if a contains b (ignores case and accent)
 */

function StringContains(a: string, b: string): boolean {
  const aNorm = a
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  const bNorm = b
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  return aNorm.includes(bNorm);
}
export default StringContains;
