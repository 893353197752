import DataInterface from './DataInterface';

interface NotificationMessage {
  title: string;
  body: string;
  Topics: NotificationTopic;
  PictureUrl: string | null;
  Datas: {
    DatasetId: string;
    RecordId: string;
  };
}

type TopicCategory = 'Evénements' | 'Gestion des déchets' | 'all';

interface NotificationTopic {
  Category: string;
  /* subCategories: string[];
  zones: string[]; */
}

interface NotificationFromFetch {
  notificationId?: string;
  notification: NotificationMessage;
  sendingTime: Date;
  interval: string;
  endInterval: Date;
}

interface NotificationInterface extends DataInterface {
  title: string;
  body: string;
  Topics: NotificationTopic;
  PictureUrl: string | null;
  sendingTime: Date;
  interval: string;
}

function NotificationFormatter(data: NotificationFromFetch): NotificationInterface {
  return {
    id: data.notificationId as string,
    title: data.notification.title,
    body: data.notification.body,
    Topics: data.notification.Topics,
    PictureUrl: data.notification.PictureUrl,
    sendingTime: data.sendingTime,
    interval: data.interval,
  };
}

export default NotificationInterface;
export { NotificationFormatter };
export type { NotificationFromFetch /* , NotificationTopic, TopicCategory */ };
