/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
// eslint-disable-next-line no-unused-vars
enum DisplayLevel {
  Unlisted = -1,
  Listed,
  Carousel,
  Highlight,
}

export default DisplayLevel;
