/* eslint-disable camelcase */
import accessibilite from '../../../assets/icons/icons-demo/accessibilite.svg';
import arbre_qui_tombe from '../../../assets/icons/icons-demo/arbre_qui_tombe.svg';
import autre from '../../../assets/icons/icons-demo/autre.svg';
import camion from '../../../assets/icons/icons-demo/camion.svg';
import chantier from '../../../assets/icons/icons-demo/chantier.svg';
import dechets from '../../../assets/icons/icons-demo/dechets.svg';
import eau_assainnissement from '../../../assets/icons/icons-demo/eau_assainnissement.svg';
import eclairage_public from '../../../assets/icons/icons-demo/eclairage_public.svg';
import encombrant from '../../../assets/icons/icons-demo/encombrant.svg';
import espace_vert from '../../../assets/icons/icons-demo/espace_vert.svg';
import gaz from '../../../assets/icons/icons-demo/gaz.svg';
import grafiti_tag from '../../../assets/icons/icons-demo/grafiti_tag.svg';
import handicap from '../../../assets/icons/icons-demo/handicap.svg';
import incendie from '../../../assets/icons/icons-demo/incendie.svg';
import nuisances_animals from '../../../assets/icons/icons-demo/nuisances_animals.svg';
import nuisances_sonores from '../../../assets/icons/icons-demo/nuisances_sonores.svg';
import poubelle from '../../../assets/icons/icons-demo/poubelle.svg';
import probleme_technique from '../../../assets/icons/icons-demo/probleme_technique.svg';
import proprete from '../../../assets/icons/icons-demo/proprete.svg';
import signalisation from '../../../assets/icons/icons-demo/signalisation.svg';
import stationnement from '../../../assets/icons/icons-demo/stationnement.svg';
import telecom from '../../../assets/icons/icons-demo/telecom.svg';
import tonte from '../../../assets/icons/icons-demo/tonte.svg';
import vehicule from '../../../assets/icons/icons-demo/vehicule.svg';
import voirie from '../../../assets/icons/icons-demo/voirie.svg';
import bench from '../../../assets/icons/icons-demo/bench.svg';
import billboard from '../../../assets/icons/icons-demo/billboard.svg';
import cctv from '../../../assets/icons/icons-demo/cctv.svg';
import concrete from '../../../assets/icons/icons-demo/concrete.svg';
import faucet from '../../../assets/icons/icons-demo/faucet.svg';
import football_field from '../../../assets/icons/icons-demo/football_field.svg';
import fountain from '../../../assets/icons/icons-demo/fountain.svg';
import hydrant from '../../../assets/icons/icons-demo/hydrant.svg';
import parking_meter from '../../../assets/icons/icons-demo/parking_meter.svg';
import rj45 from '../../../assets/icons/icons-demo/rj45.svg';
import street_lamp from '../../../assets/icons/icons-demo/street_lamp.svg';
import traffic_light_2 from '../../../assets/icons/icons-demo/traffic_light_2.svg';
import traffic_light from '../../../assets/icons/icons-demo/traffic_light.svg';
import wires from '../../../assets/icons/icons-demo/wires.svg';

const allIcons = {
  accessibilite: () => <img src={accessibilite} alt="accessibilité" width={30} height={30} />,
  arbre_qui_tombe: () => <img src={arbre_qui_tombe} alt="arbre qui tombe" width={30} height={30} />,
  autre: () => <img src={autre} alt="autre" width={30} height={30} />,
  camion: () => <img src={camion} alt="camion" width={30} height={30} />,
  chantier: () => <img src={chantier} alt="chantier" width={30} height={30} />,
  dechets: () => <img src={dechets} alt="dechets" width={30} height={30} />,
  eau_assainnissement: () => (
    <img src={eau_assainnissement} alt="eau_assainnissement" width={30} height={30} />
  ),
  eclairage_public: () => (
    <img src={eclairage_public} alt="eclairage_public" width={30} height={30} />
  ),
  encombrant: () => <img src={encombrant} alt="encombrant" width={30} height={30} />,
  espace_vert: () => <img src={espace_vert} alt="espace_vert" width={30} height={30} />,
  gaz: () => <img src={gaz} alt="gaz" width={30} height={30} />,
  grafiti_tag: () => <img src={grafiti_tag} alt="grafiti_tag" width={30} height={30} />,
  handicap: () => <img src={handicap} alt="handicap" width={30} height={30} />,
  incendie: () => <img src={incendie} alt="incendie" width={30} height={30} />,
  nuisances_animals: () => (
    <img src={nuisances_animals} alt="nuisances_animals" width={30} height={30} />
  ),
  nuisances_sonores: () => (
    <img src={nuisances_sonores} alt="nuisances_sonores" width={30} height={30} />
  ),
  poubelle: () => <img src={poubelle} alt="poubelle" width={30} height={30} />,
  probleme_technique: () => (
    <img src={probleme_technique} alt="probleme_technique" width={30} height={30} />
  ),
  proprete: () => <img src={proprete} alt="proprete" width={30} height={30} />,
  signalisation: () => <img src={signalisation} alt="signalisation" width={30} height={30} />,
  stationnement: () => <img src={stationnement} alt="stationnement" width={30} height={30} />,
  telecom: () => <img src={telecom} alt="telecom" width={30} height={30} />,
  tonte: () => <img src={tonte} alt="tonte" width={30} height={30} />,
  vehicule: () => <img src={vehicule} alt="vehicule" width={30} height={30} />,
  voirie: () => <img src={voirie} alt="voirie" width={30} height={30} />,
  bench: () => <img src={bench} alt="bench" width={30} height={30} />,
  billboard: () => <img src={billboard} alt="billboard" width={30} height={30} />,
  cctv: () => <img src={cctv} alt="cctv" width={30} height={30} />,
  concrete: () => <img src={concrete} alt="concrete" width={30} height={30} />,
  faucet: () => <img src={faucet} alt="faucet" width={30} height={30} />,
  football_field: () => <img src={football_field} alt="football_field" width={30} height={30} />,
  fountain: () => <img src={fountain} alt="fountain" width={30} height={30} />,
  hydrant: () => <img src={hydrant} alt="hydrant" width={30} height={30} />,
  parking_meter: () => <img src={parking_meter} alt="parking_meter" width={30} height={30} />,
  rj45: () => <img src={rj45} alt="rj45" width={30} height={30} />,
  street_lamp: () => <img src={street_lamp} alt="street_lamp" width={30} height={30} />,
  traffic_light_2: () => <img src={traffic_light_2} alt="traffic_light_2" width={30} height={30} />,
  traffic_light: () => <img src={traffic_light} alt="traffic_light" width={30} height={30} />,
  wires: () => <img src={wires} alt="wires" width={30} height={30} />,
};

export default { ...allIcons };
