/* eslint-disable function-paren-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { result } from 'lodash';
import AllDays from '../../../Datas/BeecomingDatas/AllDays';
import GetAdditionalQueryInterface from '../../../Types/Interface/MethodInterface/GetAdditionalQueryInteface';
import GarbageCollectAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/GarbageCollectAdditionalArgs';
import { WasteManagementInterface } from '../../../Types/Interface/TableInterfaces/DataInterface/WasteManagementInterface';
import StringContains from '../../StringContains';

const GetWasteAdditionalQuery: GetAdditionalQueryInterface<GarbageCollectAdditionalArgs> = (
  additionalQuery,
) => {
  const { days, weeks } = additionalQuery;
  const otherParameters: string[] = [];

  if (days.length > 0) {
    let dayQuery = `( jour=${days[0]}`;
    for (let i = 1, l = days.length; i < l; i += 1) {
      dayQuery += ` or jour=${days[i]}`;
    }
    dayQuery += ' )';
    otherParameters.push(dayQuery);
  }
  if (weeks.length > 0) {
    let weekQuery = `( semaineid=${weeks[0]}`;
    for (let i = 1, l = weeks.length; i < l; i += 1) {
      weekQuery += ` or semaineid=${weeks[i]}`;
    }
    weekQuery += ' )';
    otherParameters.push(weekQuery);
  }
  if (otherParameters.length === 0) {
    return null;
  }
  let res: string = otherParameters[0];
  for (let i = 1, l = otherParameters.length; i < l; i += 1) {
    res += ` and ${otherParameters[i]}`;
  }
  return res;
};

const parseWasteList = (
  dataBlock: WasteManagementInterface[],
  args: GarbageCollectAdditionalArgs,
): WasteManagementInterface[] => {
  let resultsType: WasteManagementInterface[] = [];

  const days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

  // on filtre les bacs...
  switch (args.type) {
    case 0:
      resultsType.push(...dataBlock.filter((el) => el.type.toLowerCase() === 'om'));
      break;
    case 1:
      resultsType.push(...dataBlock.filter((el) => el.type.toLowerCase() === 'sj'));
      break;
    default:
      resultsType.push(...dataBlock);
  }

  // on filtre la periode...
  if (args.weeks.length > 0) {
    resultsType = resultsType.filter((el) => args.weeks.includes(el.recurrence));
  }

  // on filtre par journée...
  if (args.days.length > 0) {
    resultsType = resultsType.filter((el) => args.days.includes(days[el.day - 1]));
  }

  // on fini par la recherche par nom...
  return resultsType?.filter((row) => StringContains(row.name, args.name));
};

export default GetWasteAdditionalQuery;
export { parseWasteList };
