import React, { FunctionComponent } from 'react';

interface Props {}

const EmailChange: FunctionComponent<Props> = () => (
  <div>
    <h1>Votre adresse a bien été modifiée</h1>
    <p>Vous pouvez fermer cette page</p>
  </div>
);

export default EmailChange;
