import React, { FunctionComponent } from 'react';
import BusAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/BusAdditionalArgs';
import { BusInterface } from '../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import BusSharedData from '../../../Types/Interface/TableInterfaces/SharedDataInterface/BusSharedData';
import GetBusComparatorFromKey from '../../../Methods/TableQuery/HandleFilter/HandleSort/GetBusComparatorFromKey';
import AdminListContainer from '../BeecomingCompenents/AdminContainers/AdminListContainer';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import ListHeaderBus from '../BeecomingCompenents/ListElements/ListHeaders/ListHeaderBus';
import ListItemBus from '../BeecomingCompenents/ListElements/ListItems/ListItemBus';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import GetBusAdditionalQuery, {
  parseBusList,
} from '../../../Methods/TableQuery/GetAdditionalQueries/GetBusAdditionalQuery';
import { BusFormatter } from '../../../Types/Interface/TableInterfaces/DataInterface/BusInterface';

interface Props {}

const getBusList = (additionalParameters: BusAdditionalArgs, history: any) => {
  const query = GetBusAdditionalQuery(additionalParameters);
  return ApiFetch('/bus-line', 'GET', history);
};
const ListBus: AdminPageComponent = ({ onError }) => (
  <AdminListContainer<BusInterface, BusAdditionalArgs, BusSharedData>
    add="Ajouter une ligne"
    defaultSharedData={{ highlight: '' }}
    defaultAdditionalArgs={{ title: '', body: '', start: null, end: null }}
    defaultSort="title"
    headComponent={ListHeaderBus}
    listComponent={ListItemBus}
    getList={{
      request: getBusList,
      formatter: BusFormatter,
      filter: parseBusList,
      errorMessage: "La liste des lignes de bus n'a pas pu être chargée correctement",
    }}
    getComparatorFromKey={GetBusComparatorFromKey}
    onError={onError}
  />
);

const ListBusProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Lignes de bus"
    menuPath="line-bus"
    screenName="line-bus"
    adminPage={ListBus}
  />
);

export default ListBusProtected;
