/* eslint-disable no-console */
/* eslint-disable react/button-has-type */
import { Button, ThemeProvider } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import AdminScreenPathsList from '../../Datas/BeecomingDatas/AdminScreenPathsList';
import theme from '../../Themes/ProjectTheme';

interface Props {}

const HomePage: FunctionComponent<Props> = () => {
  const history = useHistory();
  return (
    <ThemeProvider theme={theme}>
      <div>
        <h1>Accueil du site Sablé &amp; Moi</h1>
        <div>
          <Button
            onClick={() => {
              history.push(AdminScreenPathsList.home);
            }}
            variant="contained"
            color="primary"
          >
            Admin
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default HomePage;
