import React, { FunctionComponent } from 'react';
import AgendaAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/AgendaAdditionalArgs';
import { AgendaInterface } from '../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import AgendaSharedData from '../../../Types/Interface/TableInterfaces/SharedDataInterface/AgendaSharedData';
import GetAgendaHighLight from '../../../Methods/TableQuery/GetSharedData/GetAgendaHighLight';
import GetAgendaComparatorFromKey from '../../../Methods/TableQuery/HandleFilter/HandleSort/GetAgendaComparatorFromKey';
import AdminListContainer from '../BeecomingCompenents/AdminContainers/AdminListContainer';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import ListHeaderAgenda from '../BeecomingCompenents/ListElements/ListHeaders/ListHeaderAgenda';
import ListItemAgenda from '../BeecomingCompenents/ListElements/ListItems/ListItemAgenda';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import GetAgendaAdditionalQuery, {
  parseAgendaList,
} from '../../../Methods/TableQuery/GetAdditionalQueries/GetAgendaAdditionalQuery';
import { AgendaFormatter } from '../../../Types/Interface/TableInterfaces/DataInterface/AgendaInterface';

interface Props {}

const getAgendaList = (additionalParameters: AgendaAdditionalArgs, history: any) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  ApiFetch('/events', 'GET', history);
/* const query = GetAgendaAdditionalQuery(additionalParameters);
  return ApiFetch(`/OpenData/record/actualites/admin?rows=-1${query ?? ''}`, 'GET', history); */

const ListAgenda: AdminPageComponent = ({ onError }) => (
  <AdminListContainer<AgendaInterface, AgendaAdditionalArgs, AgendaSharedData>
    // addNew="Nouvel événement"//bouton d'ajout caché (19/11/21)
    defaultSharedData={{ highlight: '' }}
    defaultAdditionalArgs={{ title: '', body: '', start: null, end: null }}
    defaultSort="label"
    headComponent={ListHeaderAgenda}
    listComponent={ListItemAgenda}
    getList={{
      request: getAgendaList,
      formatter: AgendaFormatter,
      filter: parseAgendaList,
      errorMessage: "La liste des événements n'a pas pu être chargée correctement",
    }}
    getDefaultSharedData={GetAgendaHighLight}
    getComparatorFromKey={GetAgendaComparatorFromKey}
    onError={onError}
  />
);

const ListAgendaProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Évènements"
    menuPath="agenda"
    screenName="agenda"
    adminPage={ListAgenda}
  />
);

export default ListAgendaProtected;
