import GetAdditionalQueryInterface from '../../../Types/Interface/MethodInterface/GetAdditionalQueryInteface';
import BusAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/BusAdditionalArgs';
import { BusInterface } from '../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import StringContains from '../../StringContains';

const GetBusAdditionalQuery: GetAdditionalQueryInterface<BusAdditionalArgs> = (
  _additionalQuery: BusAdditionalArgs,
) => null;

// some additional args are not well handle by the clloApi, so their treated by the front
const parseBusList = (dataBlock: BusInterface[], args: BusAdditionalArgs): BusInterface[] =>
  // eslint-disable-next-line implicit-arrow-linebreak
  dataBlock.filter((row) => StringContains(row.title, args.title));

export default GetBusAdditionalQuery;
export { parseBusList };
