/* eslint-disable react/jsx-one-expression-per-line */
import { CircularProgress, MenuItem, Select } from '@material-ui/core';
import React, { ReactElement, useReducer } from 'react';
import { BeecomingMenuItem } from '../../../Types/BeecomingTypes/BeecomingTypes';

interface LoaderAction {
  index: number;
  loader: boolean;
}

interface Props<I> {
  keyText: keyof I;
  menu: I[];
  values: number[];
  onEditValue: (_v: number[]) => Promise<boolean>;
}

const LoaderReducer = (state: boolean[], action: LoaderAction) => {
  const newState = [...state];
  newState[action.index] = action.loader;
  return newState;
};

const CcloMenu = <I extends BeecomingMenuItem>({
  keyText,
  menu,
  values,
  onEditValue,
}: Props<I>): ReactElement => {
  const [loaders, dispatchLoaders] = useReducer(
    LoaderReducer,
    menu.map(() => false),
  );
  const maxLength = menu.length;

  const getTextFromId = (ids: number[]) => ids.map((id) => menu[id][keyText]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>, child: any) => {
    let newValues: number[] = event.target.value as number[];
    if (newValues.length === maxLength) {
      newValues = [];
    }
    const k: number = Number((child.key as string).substring(4));
    dispatchLoaders({
      index: k,
      loader: true,
    });
    onEditValue(newValues).then(() => {
      dispatchLoaders({ index: k, loader: false });
    });
  };

  return (
    <Select
      multiple
      value={values}
      onChange={handleChange}
      renderValue={(selected) => getTextFromId(selected as number[]).join(', ')}
    >
      {menu.map((item: I, index) => (
        <MenuItem key={item.id} value={item.id}>
          {item[keyText]} {loaders[index] && <CircularProgress size={15} />}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CcloMenu;
export type { BeecomingMenuItem as CcloMenuItem };
