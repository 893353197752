import React from 'react';
import { Redirect } from 'react-router-dom';
import GetPathFromAdminScreen from '../../Methods/GetPathFromAdminScreen';

const HomeRedirect = () => {
  const lastScreen = localStorage.getItem('last-screen');
  const redirectPath: string = GetPathFromAdminScreen(lastScreen);
  return <Redirect to={redirectPath} />;
};

export default HomeRedirect;
