import React, { FunctionComponent } from 'react';
import GarbageCollecAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/GarbageCollectAdditionalArgs';
import SharedDataInterface from '../../../Types/Interface/TableInterfaces/SharedDataInterface/SharedDataInterface';
import GarbageCollectExcetionLink from '../../../Datas/ExceptionLinks/GarbageCollectExcetionLink';
import GetWasteComparatorFromKey from '../../../Methods/TableQuery/HandleFilter/HandleSort/GetWasteComparatorFromKey';
import AdminListContainer from '../BeecomingCompenents/AdminContainers/AdminListContainer';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import ListHeaderGarbageCollect from '../BeecomingCompenents/ListElements/ListHeaders/ListHeaderGarbageCollect';
import ListItemWasteCollect from '../BeecomingCompenents/ListElements/ListItems/ListItemWasteCollect';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import GetWasteAdditionalQuery, {
  parseWasteList,
} from '../../../Methods/TableQuery/GetAdditionalQueries/GetWasteAdditionalQuery';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import {
  FormatterWaste,
  WasteManagementInterface,
} from '../../../Types/Interface/TableInterfaces/DataInterface/WasteManagementInterface';

interface Props {}

const getGarbageCollectList = async () => {
  const path = '/waste-pickup';
  return ApiFetch(path, 'GET', History);
};

const ListGarbage: AdminPageComponent = ({ onError }) => (
  <AdminListContainer<WasteManagementInterface, GarbageCollecAdditionalArgs, SharedDataInterface>
    defaultAdditionalArgs={{
      name: '',
      title: '',
      type: -1,
      days: [],
      weeks: [],
      importCSV: 'importer un CSV',
    }}
    defaultSharedData={{}}
    defaultSort="code"
    headComponent={ListHeaderGarbageCollect}
    listComponent={ListItemWasteCollect}
    linkOnSelected={GarbageCollectExcetionLink}
    importCSV="Mettre à jour la base de données"
    getList={{
      request: getGarbageCollectList,
      fromRecords: false,
      formatter: FormatterWaste,
      filter: parseWasteList,
      errorMessage: "La liste des collecte des déchets ne s'est pas chargée correctement.",
    }}
    getComparatorFromKey={GetWasteComparatorFromKey}
    onError={onError}
  />
);

const ListGarbageProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Gestion des déchets"
    screenName="waste-collect"
    menuPath="waste-collect"
    adminPage={ListGarbage}
  />
);

export default ListGarbageProtected;
