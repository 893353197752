import { Button, Checkbox, colors, Switch, TextField } from '@material-ui/core';
import 'date-fns';
import moment from 'moment';
import React, { Dispatch, FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import '../Admin.scss';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import CreneauComponent from '../../Global/CreneauComponent';

interface IParkingException {
  id: number;
  date: string;
  isOpen: boolean;
}

interface ITimeTable {
  monday: Array<IOpeningTime>;
  tuesday: Array<IOpeningTime>;
  wednesday: Array<IOpeningTime>;
  thursday: Array<IOpeningTime>;
  friday: Array<IOpeningTime>;
  saturday: Array<IOpeningTime>;
  sunday: Array<IOpeningTime>;
}

interface IOpeningTime {
  startTime: string;
  endTime: string;
}

interface Props {}

/**
 * @param onError method: show a snackbar with the chosen message
 * @returns Notification Edit and Creation Page
 */

const AdminEditParking: AdminPageComponent = ({ onError }) => {
  const history = useHistory();

  const [id, setId] = useState<string | null>();
  const [name, setName] = useState<string | null>();
  const [hasChargingStation, setHasChargingStation] = useState<boolean>(false);
  const [hasBlueZone, setHasBlueZone] = useState<boolean>(false);
  const [hasWhiteZone, setHasWhiteZone] = useState<boolean>(true);

  const [latitude, setLatitude] = useState<number>();
  const [latInput, setLatInput] = useState<string>('');
  const [longitude, setLongitude] = useState<number>();
  const [lngInput, setLngInput] = useState<string>('');
  const [maxSpaces, setMaxSpaces] = useState<number>();
  const [limitedMobilitySpaces, setLimitedMobilitySpaces] = useState<number>();
  const [exceptions, setExceptions] = useState<Array<IParkingException>>([]);
  const [schedule, setSchedule] = useState<any>({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  });

  const [errorName, setErrorName] = useState<boolean>(false);
  const [errorLatitude, setErrorLatitude] = useState<boolean>(false);
  const [errorLongitude, setErrorLongitude] = useState<boolean>(false);
  const [errorMaxSpaces, setErrorMaxSpaces] = useState<boolean>(false);
  const [errorLimitedMobilitySpaces, setErrorLimitedMobilitySpaces] = useState<boolean>(false);

  const [exceptionDate, setExceptionDate] = useState<Date>(new Date());
  const [exceptionIsOpen, setExceptionIsOpen] = useState<boolean>(false);
  const [exceptionNumber, setExceptionNumber] = useState<number>(0);

  const [select, setSelect] = useState<any>({
    monday: '0',
    tuesday: '0',
    wednesday: '0',
    thursday: '0',
    friday: '0',
    saturday: '0',
    sunday: '0',
  });
  const selectDay: Array<string> = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];
  const selectTitle: Array<string> = [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
  ];

  const isValid = (): boolean => {
    let valid: boolean = true;

    if (name == null) {
      valid = false;
      setErrorName(true);
    }

    if (Number.isNaN(parseFloat(latInput))) {
      valid = false;
      setErrorLatitude(true);
    }
    if (Number.isNaN(parseFloat(lngInput))) {
      valid = false;
      setErrorLongitude(true);
    }
    if (maxSpaces == null) {
      valid = false;
      setErrorMaxSpaces(true);
    }
    if (limitedMobilitySpaces == null) {
      valid = false;
      setErrorLimitedMobilitySpaces(true);
    }

    return valid;
  };

  // #region Component 1st load
  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const parkingId = params.get('id');
    setId(parkingId);
    // if parkingId is null then the user is creating a new parking

    getParkingData(
      history,
      parkingId,
      setName,
      setHasChargingStation,
      setHasWhiteZone,
      setHasBlueZone,
      setLatInput,
      setLngInput,
      setMaxSpaces,
      setLimitedMobilitySpaces,
      setSchedule,
      setExceptions,
      setExceptionNumber,
      setSelect,
    );
  }, []);

  useEffect(() => {
    setLatitude(parseFloat(latInput));
    setLongitude(parseFloat(lngInput));
  }, [latInput, lngInput]);

  // #endregion

  const handleValidation = () => {
    if (isValid()) {
      // Formates l'array de créneau pour l'envoie à l'API
      const formatedSchedule: ITimeTable = {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      };
      if (schedule) {
        Object.entries(schedule).forEach(([key, value]) => {
          // @ts-ignore
          formatedSchedule[key] = [];
          for (let i = 0; i < select[key]; i += 1) {
            // @ts-ignore
            formatedSchedule[key].push(value[i]);
          }
        });
      }

      const dataToSend = {
        id,
        name,
        coordinate: [latitude, longitude],
        maxSpaces,
        hasChargingStation,
        limitedMobilitySpaces,
        hasBlueZone,
        hasWhiteZone,
        openingTime: formatedSchedule,
        exceptions,
      };

      if (id != null) {
        ApiFetch(`/parkings/${id}`, 'PUT', history, dataToSend).then((response) => {
          if (response.ok) {
            toast.success('Les données ont bien été enregistrées.', { autoClose: 3000 });
          } else {
            toast.error("Echec de l'enregistrement des données.");
          }
        });
      } else {
        ApiFetch('/parkings', 'POST', history, dataToSend).then((response) => {
          if (response.ok) {
            toast.success('Le parking à été créé.');
            history.push('/parkings');
          } else {
            toast.error('Echec de la création.');
          }
        });
      }
    } else {
      toast.error('Veuillez renseigner tout les champs.');
    }
  };

  const handleDelete = () => {
    if (window.confirm('Etes vous sur de vouloir supprimer le parking?')) {
      ApiFetch(`/parkings/${id}`, 'DELETE', history).then((response) => {
        if (response.ok) {
          toast.success('La suppression à bien été effectuée.');
          history.push('/parkings');
        } else {
          toast.error('Echec de la suppression.');
        }
      });
    }
  };

  // Update pour les horaire des créneaux
  const updateValueForInput = (
    day: string,
    creneauIndex: number,
    key: string,
    newDateTime: Date,
  ) => {
    if (schedule) {
      // @ts-ignore
      schedule[day][creneauIndex][key] = moment(newDateTime).toISOString();
      const newSchedule = schedule;

      setSchedule(JSON.parse(JSON.stringify(newSchedule)));
    }
  };

  return (
    <div>
      <h1>{id !== null ? 'Edition du parking' : "Ajout d'un parking"}</h1>

      <div className="newElementContainer">
        {/* left */}
        <div className="newElementColumn">
          <div className="newElementProperty">
            <TextField
              label="Nom du parking"
              value={name}
              error={errorName}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              size="small"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
                setErrorName(false);
              }}
            />
          </div>

          <p className="textNewElementProperty">Coordonnées GPS</p>
          <div
            style={{ display: 'flex', flexDirection: 'row', paddingTop: 0 }}
            className="newElementProperty"
          >
            <TextField
              style={{ width: '100%', marginRight: 5 }}
              label="Latitude"
              value={latInput}
              error={errorLatitude}
              required
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setLatInput(event.target.value);
                setErrorLatitude(false);
              }}
            />
            <TextField
              style={{ width: '100%' }}
              label="Longitude"
              value={lngInput}
              error={errorLongitude}
              required
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setLngInput(event.target.value);
                setErrorLongitude(false);
              }}
            />
          </div>

          <div className="newElementProperty">
            <TextField
              label="Nombre de places (total)"
              value={maxSpaces}
              error={errorMaxSpaces}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              variant="outlined"
              size="small"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setMaxSpaces(parseInt(event.target.value, 10));
                setErrorMaxSpaces(false);
              }}
            />
          </div>
        </div>

        {/* right */}
        <div className="newElementColumn">
          <p className="textNewElementProperty">Borne de recharge</p>
          <div>
            Non
            <Switch
              checked={hasChargingStation}
              onChange={() => {
                setHasChargingStation(!hasChargingStation);
              }}
            />
            Oui
          </div>

          <div className="newElementProperty">
            <TextField
              label="Nombre de places (PMR)"
              value={limitedMobilitySpaces}
              error={errorLimitedMobilitySpaces}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              size="small"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setLimitedMobilitySpaces(parseInt(event.target.value, 10));
                setErrorLimitedMobilitySpaces(false);
              }}
            />
          </div>

          <p className="textNewElementProperty">Type de places</p>
          <div className="parking_places_type">
            <div style={{ marginRight: 'auto' }}>
              <Checkbox
                checked={hasWhiteZone}
                onChange={() => {
                  setHasWhiteZone(!hasWhiteZone);
                }}
              />
              Blanches
            </div>
            <div style={{ marginRight: 'auto' }}>
              <Checkbox
                checked={hasBlueZone}
                onChange={() => {
                  setHasBlueZone(!hasBlueZone);
                }}
              />
              Bleues
            </div>
          </div>
        </div>
      </div>

      {/* Horaires */}
      <div style={{ margin: 10 }}>
        <p className="textNewElementProperty">Horaires</p>
        <div className="parking_column">
          {selectDay.map((currentDay: string, index: number) => (
            <div className="parking_row">
              <label className="parking_dropdown" htmlFor={currentDay}>
                {selectTitle[index]}
                <select
                  className="select"
                  name={currentDay}
                  value={select[currentDay]}
                  onChange={(e) => {
                    const newSelect = { ...select };
                    newSelect[currentDay] = e.target.value;
                    setSelect(newSelect);
                  }}
                >
                  <option value="0">Fermé</option>
                  <option value="1">Ouvert (1 créneau)</option>
                  <option value="2">Ouvert (2 créneaux)</option>
                </select>
              </label>

              {[...Array(parseInt(select[currentDay], 10))].map((e, i) => {
                // Créer un créneau si il est inexistant
                if (schedule && schedule[currentDay] && schedule[currentDay][i] === undefined) {
                  schedule[currentDay][i] = {};
                  schedule[currentDay][i].startTime = '2021-11-25T00:00:00';
                  schedule[currentDay][i].endTime = '2021-11-25T00:00:00';
                  setSchedule(JSON.parse(JSON.stringify(schedule)));
                }

                // Affichage du composant du créneau
                return (
                  <>
                    {schedule && (
                      <CreneauComponent
                        day={currentDay}
                        index={i}
                        defaultValueOpening={schedule[currentDay][i].startTime}
                        defaultValueClosing={schedule[currentDay][i].endTime}
                        updateValueForInput={updateValueForInput}
                      />
                    )}
                  </>
                );
              })}
            </div>
          ))}
        </div>
      </div>

      {/* Exceptions */}
      <div style={{ margin: 10, width: 400 }}>
        <p className="textNewElementProperty" style={{ paddingBottom: 0 }}>
          Exception
        </p>

        <div className="parking_row_wide" style={{ marginLeft: 20 }}>
          <div>
            <Button
              type="button"
              onClick={() => {
                const newException: IParkingException = {
                  id: exceptionNumber,
                  date: exceptionDate.toJSON(),
                  isOpen: exceptionIsOpen,
                };
                setExceptionNumber(exceptionNumber + 1);
                setExceptions([...exceptions, newException]);
              }}
            >
              <AddIcon color="action" />
            </Button>
          </div>
          <div>Date : </div>
          <div>
            <TextField
              value={moment(exceptionDate).format('YYYY-MM-DD')}
              style={{ margin: 0 }}
              id="input_date_exception"
              type="date"
              onChange={(e) => {
                setExceptionDate(new Date(e.target.value));
              }}
            />
          </div>
          <div>
            <select
              name="status"
              id="input_status_exception"
              className="select"
              onChange={(e) => setExceptionIsOpen(e.target.value === 'open')}
              defaultValue={exceptionIsOpen ? 'open' : 'closed'}
            >
              <option value="closed">Fermé</option>
              <option value="open">Ouvert</option>
            </select>
          </div>
        </div>

        {exceptions.length !== 0 && (
          <div
            style={{
              marginLeft: 20,
              border: 'solid lightgrey 1px',
              borderRadius: 5,
            }}
          >
            {exceptions.map((exception: IParkingException) => (
              <div className="parking_row_wide" style={{ justifyContent: 'space-between' }}>
                <div>Date : {new Date(exception.date).toLocaleDateString()}</div>
                <div>{exception.isOpen ? 'Ouvert' : 'Fermé'}</div>
                <div>
                  {/* Button delete */}
                  <Button
                    type="button"
                    onClick={() => {
                      setExceptions(
                        exceptions.filter((e) => {
                          if (e.id !== exception.id) {
                            return e;
                          }
                          return null;
                        }),
                      );
                    }}
                  >
                    <DeleteIcon color="error" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="newElementProperty">
        <div className="parking_buttons_row_container">
          <Button fullWidth variant="contained" color="primary" onClick={handleValidation}>
            Valider
          </Button>
          {id !== null ? (
            <Button
              fullWidth
              variant="contained"
              style={{ backgroundColor: colors.red[700], color: '#FFFFFF' }}
              onClick={handleDelete}
            >
              Supprimer
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const AdminEditParkingProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Retour à la liste"
    screenName="parking"
    menuPath="parking"
    adminPage={AdminEditParking}
  />
);

export default AdminEditParkingProtected;

const getParkingData = (
  history: any,
  parkingId: string | null,
  setName: Dispatch<string>,
  setHasChargingStation: Dispatch<boolean>,
  setHasWhiteZone: Dispatch<boolean>,
  setHasBlueZone: Dispatch<boolean>,
  setLatitude: Dispatch<string>,
  setLongitude: Dispatch<string>,
  setMaxSpaces: Dispatch<number>,
  setLimitedMobilitySpaces: Dispatch<number>,
  setSchedule: Dispatch<any>,
  setExceptions: Dispatch<Array<IParkingException>>,
  setExceptionNumber: Dispatch<number>,
  setSelect: any,
) => {
  if (parkingId) {
    ApiFetch(`/parkings/${parkingId}`, 'GET', history)
      .then((response) => response.json())
      .then((data: any) => {
        if (data) {
          const { openingTime } = data;

          setName(data.name);
          setHasChargingStation(data.hasChargingStation);
          setHasWhiteZone(data.hasWhiteZone);
          setHasBlueZone(data.hasBlueZone);
          setLatitude(data.coordinate[0]);
          setLongitude(data.coordinate[1]);
          setMaxSpaces(data.maxSpaces);
          setLimitedMobilitySpaces(data.limitedMobilitySpaces);
          setSchedule(openingTime);

          const exceptions = data.exceptions.map((e: { date: any; isOpen: any }, index: any) => ({
            id: index,
            date: e.date,
            isOpen: e.isOpen,
          }));
          setExceptions(exceptions);

          setExceptionNumber(exceptions.length);
          const array: any = [];

          Object.entries(openingTime).forEach(([key, val]) => {
            // @ts-ignore
            array[key] = `${val.length}`;
          });

          setSelect(array);
        }
      });
  }
};
