/* eslint-disable no-unused-vars */
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { FunctionComponent } from 'react';

interface CreneauComponentProps {
  day: string;
  index: number;
  defaultValueOpening: string;
  defaultValueClosing: string;
  updateValueForInput: (day: string, creaneauIndex: number, key: string, date: Date) => void;
}

const CreneauComponent: FunctionComponent<CreneauComponentProps> = ({
  day,
  index,
  defaultValueOpening,
  defaultValueClosing,
  updateValueForInput,
}) => (
  <>
    <div className="parking_date">
      <div className="parking_creneau">Créneau {index + 1} :</div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TimePicker
          style={{ width: 50, marginRight: 5, textAlign: 'center' }}
          clearable
          ampm={false}
          minutesStep={5}
          invalidDateMessage=""
          value={moment(defaultValueOpening)}
          onChange={(newValue) => {
            const stringValue = newValue?.toString();
            if (stringValue) {
              updateValueForInput(day, index, 'startTime', new Date(stringValue));
            }
          }}
        />
        <TimePicker
          style={{ width: 50, marginRight: 5, textAlign: 'center' }}
          clearable
          ampm={false}
          minutesStep={5}
          invalidDateMessage=""
          value={moment(defaultValueClosing)}
          onChange={(newValue) => {
            const stringValue = newValue?.toString();
            if (stringValue) {
              updateValueForInput(day, index, 'endTime', new Date(stringValue));
            }
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  </>
);

CreneauComponent.propTypes = {};

export default CreneauComponent;
